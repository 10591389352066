import { enUS } from 'date-fns/locale/en-US';
import { formatInTimeZone } from 'date-fns-tz';
import React, { FC, useMemo } from 'react';

import { CancelTransactionButton } from 'components/dedicated/CancelTransactionButton/CancelTransactionButton';
import { PRINT_ROUTES } from 'router/constants';
import { TimeZone } from 'constants/constants';
import { TransactionStatus, TransactionType } from 'store/api/api.types';
import { apiUrl } from 'env';
import { getParsedTransactionAttributes } from 'helpers/transaction.helpers';
import { getUriChain } from 'utils/format';
import { shouldShowCancelTransationButton } from 'components/dedicated/CancelTransactionButton/CancelTransactionButton.utils';
import { transactionPurposeLabels } from 'components/dedicated/organization/send-money/QuoteForm/QuoteForm.constants';
import Box from 'components/core/Box/Box';
import ExternalLink from 'components/core/ExternalLink/ExternalLink';
import FileDownloadLink from 'components/core/FileDownloadLink/FileDownloadLink';
import Text from 'components/core/Text/Text';
import TextWithTooltip from 'components/core/TextWithTooltip/TextWithTooltip';
import removeApiIdPrefixes from 'utils/removeApiIdPrefixes';

import { DetailsTable, TransactionDetailsProps } from './TransactionDetails.types';
import styles from './TransactionDetails.module.scss';

const TransactionDetails: FC<TransactionDetailsProps> = ({
  organizationId,
  transactionSummary,
  ...props
}) => {
  const { attributes, id: transactionId } = transactionSummary;
  const { blockchainTxHash, createdAt, reference, purpose, documents, source, destination, quote } =
    attributes;
  const {
    senderLabel,
    senderBankLabel,
    senderSublabel,
    sourceAmount,
    sourceAsset,
    recipientLabel,
    recipientSublabel,
    recipientBankLabel,
    targetAsset,
    targetAmount,
    status,
    transactionType,
  } = getParsedTransactionAttributes(attributes);

  const transactionReceiptLink = useMemo(() => {
    if (
      ['offramp', 'fx'].includes(transactionType) &&
      ![TransactionStatus.ComplianceRejected, TransactionStatus.Cancelled].includes(status)
    ) {
      if (status === TransactionStatus.Completed) {
        return (
          <ExternalLink
            link={`${PRINT_ROUTES.PAYMENT_RECEIPTS.absolute}?organizationId=${organizationId}&transactionId=${transactionId}`}
            variant='bodyLinkSmall'
          >
            View Payment Receipt
          </ExternalLink>
        );
      }
      return (
        <TextWithTooltip
          maxTooltipWidth={52}
          position='top-end'
          text='This is not available yet.'
          tooltipText='A transaction receipt will be provided upon successful completion.'
        />
      );
    }
    return null;
  }, [organizationId, status, transactionId, transactionType]);

  const transactionDetails: DetailsTable = [
    {
      label: 'Transaction ID',
      value: removeApiIdPrefixes(transactionSummary.id),
    },
    {
      label: 'Date Created',
      value: formatInTimeZone(createdAt, TimeZone.ET, 'MMMM d, yyyy h:mm:ss a zzz', {
        locale: enUS,
      }),
    },
    {
      label: 'From',
      value: (
        <div>
          <Text marginBottom={1} variant='labelSmall'>
            {sourceAmount} {sourceAsset}
          </Text>
          <Text variant='subCopySmall'>
            {senderLabel}
            {senderBankLabel}
            {senderSublabel && (
              <>
                <br />
                {senderSublabel}
              </>
            )}
          </Text>
        </div>
      ),
    },
    {
      label: 'To',
      value: (
        <div>
          <Text marginBottom={1} variant='labelSmall'>
            {targetAmount} {targetAsset}
          </Text>
          <Text variant='subCopySmall'>
            {recipientLabel}
            {recipientBankLabel}
            {recipientSublabel && (
              <>
                <br />
                {recipientSublabel}
              </>
            )}
          </Text>
        </div>
      ),
    },
    {
      label: 'Exchange Rate',
      value: quote?.rate,
    },
    {
      label: 'Reference',
      value: reference,
    },
  ];

  const purposeAndDocumentation: DetailsTable = [
    {
      label: 'Purpose',
      value: purpose ? transactionPurposeLabels[purpose] : null,
    },
    {
      label: 'Supporting Document',
      value: documents?.invoice?.fileName ? (
        <FileDownloadLink
          fileName={documents.invoice.fileName}
          url={`${apiUrl}/organizations/${organizationId}/platform/transactions/${transactionId}/documents/${documents.invoice.id}`}
          variant='bodyLinkSmall'
        />
      ) : null,
    },
  ];

  const trackingAndReceipts: DetailsTable = [
    {
      label: 'Payment Receipt',
      value: transactionReceiptLink,
    },
  ];

  if ([TransactionType.Deposit, TransactionType.Withdrawal].includes(transactionType)) {
    let transactionHashValue;
    if (!blockchainTxHash) {
      transactionHashValue = (
        <TextWithTooltip
          position='top-end'
          text='This is not available yet.'
          tooltipText="A transaction hash will be provided upon successful completion. If the transaction is cancelled, a hash won't be generated."
        />
      );
    } else {
      const chain = getUriChain(source.assetType || destination.assetType);
      if (chain) {
        transactionHashValue = (
          <Text
            onClick={() =>
              window.open(
                `${chain.explorerUrl}${blockchainTxHash}`,
                '_blank',
                'noopener,noreferrer',
              )
            }
            variant='bodyLinkSmall'
          >
            {blockchainTxHash}
          </Text>
        );
      }
    }

    if (transactionHashValue) {
      trackingAndReceipts.unshift({
        label: 'Transaction Hash',
        value: transactionHashValue,
      });
    }
  }

  const tables = [
    {
      data: transactionDetails,
      title: 'Transaction Details',
    },
    {
      data: purposeAndDocumentation,
      title: 'Purpose and Documentation',
    },
    {
      data: trackingAndReceipts,
      title: 'Tracking and Receipts',
    },
  ];

  return (
    <>
      <Box {...props}>
        {tables.map(({ title, data }) => {
          if (!data.some(({ value }) => value)) {
            return null;
          }
          return (
            <Box
              key={title}
              alignItems='stretch'
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              marginBottom={12}
            >
              <Box>
                <Text marginBottom={6} variant='sectionHeaderStandard'>
                  {title}
                </Text>
                <Box>
                  {data.map(
                    ({ label, value }) =>
                      value && (
                        <Box
                          key={label}
                          alignItems='flex-start'
                          display='flex'
                          justifyContent='space-between'
                          marginBottom={6}
                        >
                          <Text className={styles.label} Tag='div' variant='labelSmallSubLabel'>
                            {label}
                          </Text>
                          <Text align='right' className={styles.value} variant='bodyCopySmall'>
                            {value}
                          </Text>
                        </Box>
                      ),
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {shouldShowCancelTransationButton(transactionSummary, 'transactionDetails') && (
        <Box
          alignItems='flex-end'
          display='flex'
          flexDirection='column'
          flexGrow={1}
          justifyContent='flex-end'
          marginBottom={8}
          marginRight={6}
          textAlign='right'
        >
          <CancelTransactionButton
            organizationId={organizationId}
            tooltipPosition='top-end'
            transactionSummary={transactionSummary}
          />
        </Box>
      )}
    </>
  );
};

export default TransactionDetails;
