export const HELLO_EMAIL = 'hello@conduit.fi';

export const LOCAL_STORAGE_PREFIX = 'conduit-';

export const DEFAULT_TRANSITION_TIME = 400;

export const enum Environment {
  Development = 'development',
  Local = 'local',
  Production = 'production',
  Staging = 'staging',
}

export const enum TimeZone {
  ET = 'America/New_York',
}

export const SYSTEM_ROLE_DESCRIPTIONS = {
  admin: 'Full control over all features.',
  member:
    'Restricted capabilities in creating transactions, managing team members, senders, and recipients.',
};

export const NO_PERMISSION_MESSAGE =
  'You do not have the permissions to access this feature. Please contact your admin.';

export const FINANCIAL_EMAIL = 'accounting@conduit.financial';
