import { toWei } from 'web3-utils';
import React, { FC, useMemo, useState } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import { AssetTypeBalanceValidity } from 'components/core/Form/AssetTypeField/AssetTypeField.types';
import { Organization, TransactionType } from 'store/api/api.types';
import { formatAssetType, getUriChain, getUriChainId, getUriId } from 'utils/format';
import { isGenericErrorType } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { useCreateTransactionMutation, useGetEnvironmentAccountQuery } from 'store/api/platformApi';
import { withdrawnAmountValidationFunction } from 'components/core/Form/AssetTypeField/AssetTypeField.utils';
import AssetTypeField from 'components/core/Form/AssetTypeField/AssetTypeField';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Icon from 'components/core/Icon/Icon';
import InputField from 'components/core/Form/InputField/InputField';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

const ModalWithdraw: FC<{
  assetType: AssetType;
  organizationId: Organization['id'];
}> = ({ assetType, organizationId }) => {
  const [amountInput, setAmountInput] = useState<string>('');
  const [isValidAmount, setIsValidAmount] = useState(false);

  const [destinationInput, setDestinationInput] = useState('');

  const [Transaction, { isLoading: isWithdrawing }] = useCreateTransactionMutation();

  const { data: environmentAccountData } = useGetEnvironmentAccountQuery({ organizationId });

  const chain = useMemo(() => getUriChain(assetType), [assetType]);

  const handleWithdraw = async e => {
    e.preventDefault();

    if (!assetType || !environmentAccountData?.data.id) {
      return;
    }

    const wallet = `wlt:${getUriChainId(assetType).toLowerCase()}:${destinationInput}`;

    const result = await Transaction({
      amount: toWei(amountInput, 'Mwei'),
      assetType: assetType.id,
      destination: wallet,
      organizationId,
      source: environmentAccountData.data.id,
      type: TransactionType.Withdrawal,
    });
    if (!('error' in result)) {
      showSuccess(
        `You have successfully withdrawn ${amountInput} ${getUriId(assetType)}. Your balance will update shortly.`,
      );
      modalService.closeCurrentModal();
    } else if (isGenericErrorType(result.error)) {
      showError('Failed to withdraw. Please try again later.');
    }
  };

  const isValidCryptoAddress = useMemo(() => {
    return assetType.validationRegEx ? assetType.validationRegEx!.test(destinationInput) : false;
  }, [destinationInput, assetType]);

  const canWithdraw = useMemo(() => {
    return isValidAmount && isValidCryptoAddress;
  }, [isValidAmount, isValidCryptoAddress]);

  const updateAmount = (value: string) => {
    setAmountInput(value);
  };

  const updateValidity = (status: AssetTypeBalanceValidity) => {
    setIsValidAmount(status === AssetTypeBalanceValidity.Valid);
  };

  if (assetType.isWithdrawalDisabled) {
    return (
      <>
        <ModalContent>
          <Box>
            <Text>{assetType.withdrawalDisabledMessage}</Text>
          </Box>
        </ModalContent>
        <ModalContent variant='footerButtonsWithoutTopBorder'>
          <Button
            label='Close'
            onClick={() => {
              modalService.closeCurrentModal();
            }}
            variant='secondary'
          />
        </ModalContent>
      </>
    );
  }
  return (
    <form onSubmit={handleWithdraw}>
      <ModalContent variant='noBottomPadding'>
        <Text align='right'>Balance {formatAssetType(assetType, 3)}</Text>
        <AssetTypeField
          amountFieldValue={amountInput}
          amountLabel='Send'
          amountValidatorFunction={withdrawnAmountValidationFunction}
          assetType={assetType}
          dataTestId='withdraw-amount-field'
          isDisabled={isWithdrawing}
          onAmountChange={updateAmount}
          onValidationChange={updateValidity}
        />
        <InputField
          dataTestId='wallet-addres-field'
          error={
            destinationInput !== '' && !isValidCryptoAddress
              ? `Please enter a valid ${chain?.id} address.`
              : ''
          }
          Icon={<Icon iconName='wallet' marginLeft={0.25} size={1.6} />}
          isDisabled={isWithdrawing}
          isRequired
          label={`Enter ${chain?.id} Wallet Address`}
          name='toInput'
          onChange={({ target: { value } }) => setDestinationInput(value)}
          value={destinationInput}
        />
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          dataTestId='withdrawal-cancel-button'
          label='Cancel'
          onClick={() => {
            modalService.closeCurrentModal();
          }}
          variant='textCta'
        />
        <Button
          dataTestId='modal-withdraw-button'
          isDisabled={!canWithdraw}
          isLoading={isWithdrawing}
          label='Withdraw'
          type='submit'
          variant='primary'
        />
      </ModalContent>
    </form>
  );
};

export default ModalWithdraw;
