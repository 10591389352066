import { createContext } from 'react';

import { OrganizationContextType } from './OrganizationContext.types';
import { getContextFromProvider } from './context.utils';

const OrganizationContext = createContext<OrganizationContextType | undefined>(undefined);

export const useOrganization = getContextFromProvider<OrganizationContextType>(OrganizationContext);

export default OrganizationContext;
