import { generatePath } from 'react-router-dom';

import { ActionName, CurrentUserContextType } from 'context/CurrentUserContext.types';
import { Organization } from 'store/api/api.types';
import { SvgImgProp } from 'components/core/Svg/Svg.types';
import {
  balance,
  balanceFilled,
  contact,
  contactFilled,
  developers,
  developersFilled,
  group,
  groupFilled,
  receipt,
  receiptFilled,
} from 'components/core/Svg/images/icons';

import { ORGANIZATION_ROUTES } from './constants';
import { Path } from './utils';

interface OrganizationMenuItem {
  icon: SvgImgProp;
  iconActive: SvgImgProp;
  isDisabled?: boolean;
  label: string;
  path: Path;
  requiredPermissions?: ActionName;
}

const MENU_ITEMS: OrganizationMenuItem[] = [
  {
    icon: balance,
    iconActive: balanceFilled,
    label: 'Send Money',
    path: ORGANIZATION_ROUTES.SEND_MONEY,
    requiredPermissions: ActionName.CreateQuote,
  },
  {
    icon: receipt,
    iconActive: receiptFilled,
    label: 'Transactions',
    path: ORGANIZATION_ROUTES.TRANSACTIONS,
    requiredPermissions: ActionName.ReadTransaction,
  },
  {
    icon: contact,
    iconActive: contactFilled,
    label: 'Counterparties',
    path: ORGANIZATION_ROUTES.SENDERS_RECIPIENTS,
    requiredPermissions: ActionName.ReadIdentity,
  },
  {
    icon: developers,
    iconActive: developersFilled,
    label: 'API Keys',
    path: ORGANIZATION_ROUTES.API_KEYS,
    requiredPermissions: ActionName.ReadKey,
  },
  {
    icon: group,
    iconActive: groupFilled,
    label: 'Team',
    path: ORGANIZATION_ROUTES.MEMBERS,
    requiredPermissions: ActionName.ReadMember,
  },
];

export const getOrganizationMenuItemPath = (
  organizationId: Organization['id'],
  path: Path,
): Path => ({
  absolute: generatePath(path.absolute, {
    organizationId,
  }),
  relative: path.relative,
});

export const getOrganizationMenuItems = (
  organization: Organization,
  isUserAllowedTo: CurrentUserContextType['isUserAllowedTo'],
): OrganizationMenuItem[] => {
  const canUsePlatform = organization?.platform?.canUsePlatform || false;
  return MENU_ITEMS.filter(menuItem => {
    if (!menuItem.requiredPermissions) {
      return true;
    }
    return isUserAllowedTo(organization.id, menuItem.requiredPermissions);
  }).map(item => ({
    icon: item.icon,
    iconActive: item.iconActive,
    isDisabled: !canUsePlatform,
    label: item.label,
    path: getOrganizationMenuItemPath(organization.id, item.path),
  }));
};
