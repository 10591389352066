import React, { ReactElement, useState } from 'react';

import { CreateTransactionContextProvider } from 'context/CreateTransactionContextProvider';
import { QuoteForm, QuotePreview } from 'components/dedicated/organization/send-money';

import { QuoteViewStep } from './QuoteView.types';
import styles from './QuoteView.module.scss';

const QuoteView = (): ReactElement => {
  const [step, setStep] = useState<QuoteViewStep>(QuoteViewStep.FORM);

  const getStepClass = (nextStep: QuoteViewStep) => {
    return nextStep === step ? styles.show : styles.hide;
  };

  return (
    <CreateTransactionContextProvider>
      <div className={getStepClass(QuoteViewStep.FORM)}>
        <QuoteForm changeStep={setStep} />
      </div>
      <div className={getStepClass(QuoteViewStep.PREVIEW)}>
        <QuotePreview changeStep={setStep} />
      </div>
    </CreateTransactionContextProvider>
  );
};

export default QuoteView;
