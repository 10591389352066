import { fromWei } from 'web3-utils';
import { toNumber } from 'lodash';
import React, { FC, useMemo } from 'react';

import { ASSET_TYPES } from 'constants/assetTypes';
import { ActionName } from 'context/CurrentUserContext.types';
import {
  AssetTypesHeaderProps,
  WalletGroupProps,
} from 'components/dedicated/organization/send-money/AssetTypesHeader/AssetTypesHeader.types';
import { useAssetsSortedByType } from 'components/dedicated/organization/send-money/AssetTypesHeader/AssetTypesHeader.hooks';
import { useCurrentUser } from 'context/CurrentUserContext';
import Box from 'components/core/Box/Box';
import Text from 'components/core/Text/Text';

import AssetTypeHeaderBox from './AssetTypeHeaderBox/AssetTypeHeaderBox';
import styles from './AssetTypesHeader.module.scss';

const WalletGroup = ({
  wallets,
  organizationId,
  canWithdraw,
  useShowcaseMode,
}: WalletGroupProps) => {
  return wallets?.map(stablecoin => (
    <AssetTypeHeaderBox
      key={stablecoin.id}
      assetType={stablecoin}
      canWithdraw={canWithdraw}
      organizationId={organizationId}
      useShowcaseMode={useShowcaseMode}
    />
  ));
};

const AssetTypesHeader: FC<AssetTypesHeaderProps> = ({ organizationId, assetTypes }) => {
  const { isUserAllowedTo } = useCurrentUser();

  const { supportedStablecoins, supportedFiatCurrencies } = useAssetsSortedByType(assetTypes);

  const canWithdraw = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateTransaction),
    [organizationId, isUserAllowedTo],
  );

  const usdBalance = useMemo(() => {
    const stableCoinsBalance = supportedStablecoins.reduce(
      (total, assetType) =>
        total + toNumber(fromWei(assetType?.balance || 0, assetType?.precision || 'Mwei')),
      0,
    );

    const usdWalletObject = supportedFiatCurrencies?.find(
      assetType => assetType.id === ASSET_TYPES.FIAT_USD.id,
    );

    const usdWallet = {
      ...ASSET_TYPES.FIAT_USD,
      balance: usdWalletObject?.balance || 0,
      min: usdWalletObject?.withdrawMin,
    };

    const usdFiatBalance = fromWei(usdWallet?.balance || 0, usdWallet?.precision || 'Mwei');

    return stableCoinsBalance + toNumber(usdFiatBalance);
  }, [supportedStablecoins, supportedFiatCurrencies]);

  return (
    <header className={styles.root}>
      <Box className={styles.balanceBox} marginRight={10}>
        <Text variant='bodyCopySmall'>Total Assets</Text>
        <Text variant='sectionHeaderLarge'>
          {usdBalance?.toLocaleString('en-US', { currency: 'USD', style: 'currency' })}
        </Text>
      </Box>
      <WalletGroup
        canWithdraw={canWithdraw}
        organizationId={organizationId}
        wallets={supportedStablecoins}
      />
    </header>
  );
};

export default AssetTypesHeader;
