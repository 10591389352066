import { SvgImgProp } from '../Svg.types';

/**
 * TRANSACTION TYPES
 */

export const arrowUp: SvgImgProp = {
  path: 'M.3 7.08a1 1 0 0 0 0 1.44c.42.4 1.08.4 1.5 0l5.15-5v11.46c0 .56.47 1.02 1.05 1.02.58 0 1.06-.46 1.06-1.03V3.54l5.13 5c.42.4 1.09.4 1.5 0a1 1 0 0 0 0-1.46L8.71.28a1.02 1.02 0 0 0-1.41 0l-7 6.8Z',
  viewBox: [0, 0, 16, 16],
};

export const arrowDown: SvgImgProp = {
  path: 'M15.7 8.92a1 1 0 0 0 0-1.44c-.42-.4-1.08-.4-1.5 0l-5.15 5V1.02C9.05.46 8.58 0 8 0c-.58 0-1.06.46-1.06 1.03v11.44l-5.13-5c-.42-.4-1.09-.4-1.5 0a1 1 0 0 0 0 1.46l6.98 6.79c.4.37 1.02.37 1.41 0l7-6.8Z',
  viewBox: [0, 0, 16, 16],
};

export const arrowLeft: SvgImgProp = {
  path: 'm3.52 9.05 5 5.15c.41.42.4 1.09 0 1.5a1 1 0 0 1-1.44 0l-6.8-7a1.02 1.02 0 0 1 0-1.4l6.8-7a1 1 0 0 1 1.44 0c.4.41.41 1.08 0 1.5l-5 5.15h11.45c.57 0 1.03.47 1.03 1.05 0 .58-.46 1.05-1.03 1.05H3.52Z',
  viewBox: [0, 0, 16, 16],
};

export const arrowRight: SvgImgProp = {
  path: 'M12.48 9.05H1.03C.46 9.05 0 8.58 0 8c0-.58.46-1.05 1.03-1.05h11.45l-5-5.15c-.41-.42-.4-1.09 0-1.5a1 1 0 0 1 1.44 0l6.8 7c.38.38.38 1.02 0 1.4l-6.8 7a1 1 0 0 1-1.44 0c-.4-.41-.41-1.08 0-1.5l5-5.15Z',
  viewBox: [0, 0, 16, 16],
};

export const arrowDownward: SvgImgProp = {
  path: 'M11.36 13a1.03 1.03 0 1 0 0-2.07H3.52l9.18-9.17A1.03 1.03 0 0 0 11.24.3L2.07 9.48V1.64a1.03 1.03 0 1 0-2.07 0v10.33C0 12.54.46 13 1.03 13h10.33Z',
  viewBox: [0, 0, 13, 13],
};

export const arrowUpward: SvgImgProp = {
  path: 'M1.64 0a1.03 1.03 0 0 0 0 2.07h7.84L.3 11.24a1.03 1.03 0 0 0 1.46 1.46l9.17-9.18v7.84a1.03 1.03 0 1 0 2.07 0V1.03C13 .46 12.54 0 11.97 0H1.64Z',
  viewBox: [0, 0, 13, 13],
};

export const add: SvgImgProp = {
  path: 'M9.12 2a1.13 1.13 0 0 0-2.25 0v4.88H2a1.13 1.13 0 0 0 0 2.25h4.87V14a1.13 1.13 0 0 0 2.25 0V9.12H14a1.13 1.13 0 0 0 0-2.24H9.12V2Z',
  viewBox: [0, 0, 16, 16],
};

export const subtract: SvgImgProp = {
  path: 'M.88 2c0-.62.5-1.13 1.12-1.13h12a1.13 1.13 0 0 1 0 2.25H2C1.38 3.13.87 2.63.87 2Z',
  viewBox: [0, 0, 16, 4],
};

/**
 * COMMUNICATIVE
 */

export const alert: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5a8.49 8.49 0 1 1 0 17 8.49 8.49 0 1 1 0-17Zm-.01 2.99a.75.75 0 0 0-.74.76v6a.75.75 0 1 0 1.5 0v-6a.75.75 0 0 0-.76-.76ZM10 13.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z',
  viewBox: [0, 0, 20, 20],
};

export const alertFilled: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm-.75 5.25a.75.75 0 1 1 1.5 0v6a.75.75 0 1 1-1.5 0v-6ZM10 15.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z',
  viewBox: [0, 0, 20, 20],
};

export const warning: SvgImgProp = {
  path: 'M11 0c-.87 0-1.64.46-2.08 1.22L.32 16.35A2.44 2.44 0 0 0 2.4 20h17.2a2.44 2.44 0 0 0 2.08-3.65l-8.6-15.13A2.37 2.37 0 0 0 11 0Zm0 1.95c.08 0 .29.02.41.24l8.6 15.13c.13.22.05.42 0 .49a.47.47 0 0 1-.41.24H2.4a.47.47 0 0 1-.42-.24.48.48 0 0 1 0-.49l8.6-15.13a.47.47 0 0 1 .42-.24Zm0 3.44c-.34 0-.6.1-.82.31a1 1 0 0 0-.3.77l.16 5.77c.02.65.34.97.97.97.6 0 .91-.32.92-.97l.18-5.75a1 1 0 0 0-.3-.78 1.11 1.11 0 0 0-.82-.32Zm0 9.1c-.74 0-1.2.64-1.2 1.17 0 .54.45 1.17 1.2 1.17s1.2-.6 1.2-1.17-.46-1.17-1.2-1.17Z',
  viewBox: [0, 0, 22, 20],
};

export const warningFilled: SvgImgProp = {
  path: 'M21.68 16.35A2.44 2.44 0 0 1 19.6 20H2.4a2.44 2.44 0 0 1-2.08-3.65l8.6-15.13A2.37 2.37 0 0 1 11 0c.87 0 1.64.46 2.08 1.22l8.6 15.13ZM10.18 5.7a1 1 0 0 0-.3.77l.16 5.77c.02.65.34.97.97.97.6 0 .91-.32.92-.97l.18-5.75a1 1 0 0 0-.3-.78 1.11 1.11 0 0 0-.82-.32c-.33 0-.6.1-.8.31ZM11 16.83c.75 0 1.2-.6 1.2-1.17s-.46-1.17-1.2-1.17-1.2.64-1.2 1.17c0 .54.45 1.17 1.2 1.17Z',
  viewBox: [0, 0, 22, 20],
};

export const checkmark: SvgImgProp = {
  path: 'M16.21.88c-.23 0-.45.1-.6.28l-9.27 9.73L2.4 6.75a.89.89 0 0 0-.63-.28.85.85 0 0 0-.64.27.93.93 0 0 0-.25.66.96.96 0 0 0 .26.66l4.57 4.8a.87.87 0 0 0 1.26 0l9.89-10.4a.96.96 0 0 0 .2-1.02.92.92 0 0 0-.34-.42.86.86 0 0 0-.5-.14Z',
  viewBox: [0, 0, 18, 14],
};

export const circledCheckmark: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5a8.49 8.49 0 1 1 0 17 8.49 8.49 0 1 1 0-17ZM13.74 7c-.2 0-.38.08-.52.22l-4.47 4.47-1.97-1.97a.75.75 0 1 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.06 0l5-5a.75.75 0 0 0-.54-1.29Z',
  viewBox: [0, 0, 20, 20],
};

export const circledCheckmarkFilled: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm4.28 8.28-5 5a.75.75 0 0 1-1.06 0l-2.5-2.5a.75.75 0 1 1 1.06-1.06l1.97 1.97 4.47-4.47a.75.75 0 0 1 1.06 1.06Z',
  viewBox: [0, 0, 20, 20],
};

export const circledCheckmarkInProgress: SvgImgProp = {
  path: 'M12 0c-.44 0-.87.02-1.3.07a.9.9 0 1 0 .2 1.79A10.19 10.19 0 0 1 22.2 12a10.19 10.19 0 0 1-11.4 10.13.9.9 0 0 0-.1 1.8c.4.04.82.07 1.23.07H12c6.62 0 12-5.38 12-12C24 5.4 18.65.04 12.07 0A.9.9 0 0 0 12 0ZM7.53.98l-.42.06-.06.03-.07.03-.8.4-.06.04-.77.47-.06.04-.36.27-.37.68.3.71.74.22.39-.16.33-.25.7-.43.75-.37.02-.01.53-.58-.12-.76-.67-.4Zm-4.16 3.7-.77.02-.32.27-.3.43-.04.06-.46.77-.04.06-.4.8v.78l.59.5.76-.15.27-.32.37-.77.43-.71.29-.4.13-.76-.5-.59Zm13.11 3.71a.9.9 0 0 0-.62.27l-5.36 5.37-2.36-2.37a.9.9 0 1 0-1.28 1.28l3 3a.9.9 0 0 0 1.28 0l6-6a.9.9 0 0 0-.66-1.55ZM.9 9.95l-.67.39-.15.4-.02.2-.01.07-.04.95v.07l.03.84v.07l.04.32.41.66.77.1.56-.54.05-.42-.03-.3L1.8 12l.04-.88.02-.16-.24-.74-.73-.27Zm1.21 5.69-.76.13-.38.67.07.42.1.22.02.06.37.7.03.06.4.67.04.06.29.4.68.38.72-.3.22-.73-.15-.4-.27-.37L3.1 17l-.33-.64-.09-.2-.58-.51Zm3.1 4.46-.58.52.04.77.29.32.6.41.06.04.68.4.06.03.7.35h.78l.5-.59-.15-.76-.33-.27-.68-.33-.62-.37-.59-.4-.76-.12Z',
  viewBox: [0, 0, 24, 24],
};

export const info: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5a8.49 8.49 0 1 1 0 17 8.49 8.49 0 1 1 0-17ZM10 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-.01 3.49a.75.75 0 0 0-.74.76v5.5a.75.75 0 1 0 1.5 0v-5.5a.75.75 0 0 0-.76-.76Z',
  viewBox: [0, 0, 20, 20],
};

export const infoFilled: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm.75 14.75a.75.75 0 0 1-1.5 0v-5.5a.75.75 0 0 1 1.5 0v5.5ZM10 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z',
  viewBox: [0, 0, 20, 20],
};

export const close: SvgImgProp = {
  path: 'M1.7.3A1 1 0 0 0 .3 1.7L6.58 8l-6.3 6.3a1 1 0 1 0 1.42 1.4L8 9.43l6.28 6.29a1 1 0 1 0 1.42-1.42L9.4 8l6.3-6.3A1 1 0 1 0 14.29.3L8 6.58 1.7.29Z',
  viewBox: [0, 0, 16, 16],
};

export const clear: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5a8.49 8.49 0 1 1 0 17 8.49 8.49 0 1 1 0-17Zm3.24 4.49c-.2 0-.38.09-.52.23L10 8.94 7.28 6.22a.75.75 0 1 0-1.06 1.06L8.94 10l-2.72 2.72a.75.75 0 1 0 1.06 1.06L10 11.06l2.72 2.72a.75.75 0 1 0 1.06-1.06L11.06 10l2.72-2.72a.75.75 0 0 0-.54-1.3Z',
  viewBox: [0, 0, 20, 20],
};

export const clearFilled: SvgImgProp = {
  path: 'M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm3.78 12.72a.75.75 0 1 1-1.06 1.06L10 11.06l-2.72 2.72a.75.75 0 0 1-1.06 0 .75.75 0 0 1 0-1.06L8.94 10 6.22 7.28a.75.75 0 1 1 1.06-1.06L10 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06L11.06 10l2.72 2.72Z',
  viewBox: [0, 0, 20, 20],
};

export const help: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5a8.49 8.49 0 1 1 0 17 8.49 8.49 0 1 1 0-17Zm0 3c-1.65 0-3 1.35-3 3v.25a.75.75 0 1 0 1.5 0V7.5c0-.84.66-1.5 1.5-1.5s1.5.66 1.5 1.5c0 1.17-.3 1.32-.84 1.74-.26.2-.61.45-.91.85-.3.41-.5.98-.5 1.66a.75.75 0 1 0 1.5 0c0-.43.08-.6.2-.77.13-.17.34-.33.64-.56A3.54 3.54 0 0 0 13 7.5c0-1.65-1.35-3-3-3Zm0 9.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z',
  viewBox: [0, 0, 20, 20],
};

export const helpFilled: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 16a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1.5-5.51c-.5.38-.75.58-.75 1.26a.75.75 0 1 1-1.5 0c0-1.41.76-2 1.32-2.44.54-.42.93-.73.93-1.81a1.5 1.5 0 0 0-3 0v.25a.75.75 0 1 1-1.5 0V7.5a3 3 0 0 1 6 0c0 1.82-.87 2.5-1.5 2.99Z',
  viewBox: [0, 0, 20, 20],
};

export const notifications: SvgImgProp = {
  path: 'M8.64 0C4.59.2 1.49 3.78 1.49 7.9v3.72L.17 14.35v.01c-.54 1.17.32 2.56 1.57 2.56H6A3.05 3.05 0 0 0 9 20c1.65 0 3-1.39 3-3.08h4.25c1.26 0 2.12-1.4 1.59-2.56v-.01L16.5 11.6V7.7A7.6 7.6 0 0 0 8.64.01Zm.07 1.55A6.06 6.06 0 0 1 15 7.69v4.1c0 .12.02.24.07.35l1.4 2.88c.1.2-.01.36-.23.36H1.75c-.22 0-.32-.16-.23-.36l1.4-2.88c.05-.1.08-.23.08-.35V7.9C3 4.55 5.49 1.7 8.7 1.55ZM7.5 16.92h3A1.5 1.5 0 0 1 9 18.46a1.5 1.5 0 0 1-1.5-1.54Z',
  viewBox: [0, 0, 18, 20],
};

export const notificationsFilled: SvgImgProp = {
  path: 'M16.25 16.41H1.75c-.6 0-1.15-.31-1.48-.83a1.83 1.83 0 0 1-.1-1.73L1.5 11.1V7.9C1.5 3.66 4.64.2 8.64 0c2.07-.1 4.04.65 5.54 2.12a7.72 7.72 0 0 1 2.32 5.56v3.41l1.33 2.74c.26.57.22 1.22-.1 1.74-.33.52-.88.83-1.48.83Zm-10.2 1.03A3.03 3.03 0 0 0 9 20a3.03 3.03 0 0 0 2.96-2.56H6.05Z',
  viewBox: [0, 0, 18, 20],
};

/**
 * NAVIGATION
 */

export const home: SvgImgProp = {
  path: 'M8.98 0a.74.74 0 0 0-.44.17L1.43 5.9A3.89 3.89 0 0 0 0 8.93v9.79C0 19.42.57 20 1.25 20h5c.68 0 1.25-.58 1.25-1.28v-5.13c0-.15.1-.26.25-.26h2.5c.15 0 .25.1.25.26v5.13c0 .7.57 1.28 1.25 1.28h5c.68 0 1.25-.58 1.25-1.28v-9.8c0-1.17-.53-2.28-1.43-3.01L9.47.17a.74.74 0 0 0-.5-.17ZM9 1.75l6.64 5.37c.54.43.86 1.1.86 1.8v9.54H12V13.6c0-.98-.8-1.8-1.75-1.8h-2.5c-.96 0-1.75.82-1.75 1.8v4.87H1.5V8.93c0-.7.32-1.38.86-1.81L9 1.75Z',
  viewBox: [0, 0, 18, 20],
};

export const homeFilled: SvgImgProp = {
  path: 'M16.75 20h-4.5c-.7 0-1.25-.57-1.25-1.28V14.1c0-.56-.45-1.02-1-1.02H8c-.55 0-1 .46-1 1.02v4.62c0 .7-.56 1.28-1.25 1.28h-4.5C.55 20 0 19.43 0 18.72V8.93c0-1.18.53-2.3 1.43-3.02L8.53.16a.74.74 0 0 1 .93 0l7.11 5.75c.9.73 1.43 1.84 1.43 3.02v9.79c0 .7-.56 1.28-1.25 1.28Z',
  viewBox: [0, 0, 18, 20],
};

export const settings: SvgImgProp = {
  path: 'M9.5 0c-.78 0-1.53.1-2.25.27a.74.74 0 0 0-.57.65l-.16 1.45c-.05.47-.32.9-.73 1.13-.4.24-.9.27-1.34.08l-1.32-.59a.73.73 0 0 0-.84.18A10 10 0 0 0 .03 7.1a.76.76 0 0 0 .27.82l1.17.87a1.5 1.5 0 0 1 0 2.42l-1.17.87a.75.75 0 0 0-.27.82 10 10 0 0 0 2.26 3.94.74.74 0 0 0 .84.17l1.32-.59c.43-.2.93-.16 1.34.08.41.23.68.66.73 1.13l.16 1.45a.75.75 0 0 0 .57.65 9.8 9.8 0 0 0 4.5 0 .74.74 0 0 0 .57-.65l.16-1.45c.05-.47.32-.9.73-1.13.4-.24.9-.27 1.34-.08l1.32.59a.74.74 0 0 0 .84-.18 10.01 10.01 0 0 0 2.26-3.93.76.76 0 0 0-.27-.82l-1.17-.87a1.5 1.5 0 0 1 0-2.42l1.17-.87a.75.75 0 0 0 .27-.82 10.01 10.01 0 0 0-2.26-3.93.74.74 0 0 0-.84-.18l-1.32.59a1.5 1.5 0 0 1-2.07-1.21L12.32.92a.75.75 0 0 0-.57-.65A9.79 9.79 0 0 0 9.5 0Zm0 1.5c.48 0 .94.09 1.4.17l.1.86a3 3 0 0 0 1.47 2.27c.82.48 1.81.54 2.68.15l.78-.35c.6.73 1.08 1.56 1.41 2.47l-.7.51a3.01 3.01 0 0 0 0 4.84l.7.51c-.33.9-.8 1.74-1.4 2.47l-.8-.35a2.94 2.94 0 0 0-2.67.15A3 3 0 0 0 11 17.47l-.1.86c-.46.08-.92.17-1.4.17-.48 0-.94-.09-1.4-.17l-.1-.86a3 3 0 0 0-1.47-2.27 2.94 2.94 0 0 0-2.68-.15l-.78.35a8.45 8.45 0 0 1-1.41-2.47l.7-.51a3.01 3.01 0 0 0 0-4.84l-.7-.52c.33-.9.8-1.73 1.4-2.46l.8.35c.86.39 1.85.33 2.67-.15A3 3 0 0 0 8 2.53l.1-.86c.46-.08.92-.17 1.4-.17Zm0 4.5a3.99 3.99 0 0 0-3.96 4c0 2.2 1.78 4 3.96 4 2.18 0 3.96-1.8 3.96-4S11.68 6 9.5 6Zm0 1.5a2.48 2.48 0 0 1 2.47 2.5 2.48 2.48 0 1 1-4.94 0c0-1.39 1.1-2.5 2.47-2.5Z',
  viewBox: [0, 0, 19, 20],
};

export const settingsFilled: SvgImgProp = {
  path: 'M17.05 11.14a1.4 1.4 0 0 1 0-2.25l1.64-1.2c.27-.2.37-.54.27-.85a9.94 9.94 0 0 0-1.99-3.45.75.75 0 0 0-.86-.2l-1.85.82a1.4 1.4 0 0 1-1.94-1.12L12.1.85a.75.75 0 0 0-.6-.65c-1.3-.26-2.65-.27-3.97 0a.75.75 0 0 0-.6.65l-.22 2.02A1.4 1.4 0 0 1 4.77 4L2.9 3.18a.75.75 0 0 0-.86.19 9.94 9.94 0 0 0-2 3.44c-.1.31 0 .65.27.85l1.63 1.2a1.4 1.4 0 0 1 0 2.25L.3 12.3a.75.75 0 0 0-.27.85 9.94 9.94 0 0 0 1.99 3.45c.21.24.56.32.86.19l1.85-.82a1.4 1.4 0 0 1 1.94 1.13l.22 2.03c.04.33.28.6.6.66a10.02 10.02 0 0 0 3.97 0 .75.75 0 0 0 .6-.65l.22-2.02A1.4 1.4 0 0 1 14.23 16l1.86.82c.3.13.65.06.86-.19a9.94 9.94 0 0 0 2-3.45c.1-.3 0-.65-.27-.84l-1.63-1.2ZM9.5 13.5a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z',
  viewBox: [0, 0, 19, 20],
};

export const menu: SvgImgProp = {
  path: 'M0 12v-2h18v2H0Zm0-5V5h18v2H0Zm0-5V0h18v2H0Z',
  viewBox: [0, 0, 18, 12],
};

export const apiKey: SvgImgProp = {
  path: 'M12.5 0A6.51 6.51 0 0 0 6 6.5c0 .44.1.85.18 1.26L.22 13.72a.75.75 0 0 0-.22.53v4a.75.75 0 0 0 .75.75h4a.75.75 0 0 0 .75-.75V17h1.75a.75.75 0 0 0 .75-.75V14.5h1.75a.75.75 0 0 0 .75-.75v-1.1c.64.2 1.3.35 2 .35a6.51 6.51 0 0 0 0-13Zm0 1.5a4.99 4.99 0 1 1 0 10c-.87 0-1.68-.22-2.4-.6a.75.75 0 0 0-1.1.65V13H7.25a.75.75 0 0 0-.75.75v1.75H4.75a.75.75 0 0 0-.75.75v1.25H1.5v-2.94l6-6a.75.75 0 0 0 .19-.73A4.97 4.97 0 0 1 12.5 1.5Zm1 2.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z',
  viewBox: [0, 0, 19, 19],
};

export const apiKeyFilled: SvgImgProp = {
  path: 'M12.75 0a6.25 6.25 0 0 0-6.02 7.9L.22 14.43a.75.75 0 0 0-.22.53v3.3c0 .41.34.75.75.75h3.5c.41 0 .75-.34.75-.75V17h1.75c.41 0 .75-.34.75-.75V14.5h1.75c.41 0 .75-.34.75-.75v-1.9A6.25 6.25 0 1 0 12.75 0Zm.75 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z',
  viewBox: [0, 0, 19, 19],
};

export const more: SvgImgProp = {
  path: 'M2.05 0C.92 0 0 .9 0 2s.92 2 2.05 2c1.12 0 2.04-.9 2.04-2s-.92-2-2.04-2Zm11.9 0c-1.12 0-2.04.9-2.04 2s.92 2 2.04 2C15.08 4 16 3.1 16 2s-.92-2-2.05-2ZM8 0C6.88 0 5.95.9 5.95 2S6.88 4 8 4c1.12 0 2.05-.9 2.05-2S9.12 0 8 0Z',
  viewBox: [0, 0, 16, 4],
};

export const global: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5c.37 0 .74.15 1.15.53.4.37.82.97 1.17 1.74.29.62.5 1.42.7 2.23H6.98c.2-.81.4-1.6.69-2.23.35-.77.77-1.37 1.17-1.74.41-.38.78-.53 1.15-.53Zm-3.11.6c-.2.32-.4.67-.58 1.05-.38.82-.66 1.8-.88 2.85H2.5a8.5 8.5 0 0 1 4.39-3.9Zm6.22 0a8.5 8.5 0 0 1 4.4 3.9h-2.94a13.8 13.8 0 0 0-.88-2.85 8.78 8.78 0 0 0-.58-1.06ZM1.87 7.5h3.36C5.13 8.32 5 9.12 5 10c0 .88.13 1.68.23 2.5H1.87a8.53 8.53 0 0 1 0-5Zm4.86 0h6.54c.1.8.23 1.61.23 2.5 0 .89-.12 1.7-.23 2.5H6.73c-.1-.8-.23-1.61-.23-2.5 0-.89.12-1.7.23-2.5Zm8.04 0h3.36a8.53 8.53 0 0 1 0 5h-3.36c.1-.82.23-1.62.23-2.5 0-.88-.13-1.68-.23-2.5ZM2.5 14h2.93c.22 1.05.5 2.03.88 2.85.18.38.37.73.58 1.06A8.5 8.5 0 0 1 2.49 14Zm4.49 0H13c-.2.81-.4 1.6-.69 2.23a5.54 5.54 0 0 1-1.17 1.74c-.41.38-.78.53-1.15.53-.37 0-.74-.15-1.15-.53-.4-.37-.82-.97-1.17-1.74-.29-.62-.5-1.42-.7-2.23Zm7.58 0h2.93a8.5 8.5 0 0 1-4.39 3.9c.2-.32.4-.67.58-1.05.38-.82.66-1.8.88-2.85Z',
  viewBox: [0, 0, 20, 20],
};

export const developers: SvgImgProp = {
  path: 'M3.06 0A3.07 3.07 0 0 0 0 3.06v13.88A3.07 3.07 0 0 0 3.06 20h13.88A3.07 3.07 0 0 0 20 16.94V3.06A3.07 3.07 0 0 0 16.94 0H3.06Zm0 1.67h13.88c.78 0 1.4.6 1.4 1.39v13.88c0 .78-.62 1.4-1.4 1.4H3.06c-.78 0-1.4-.62-1.4-1.4V3.06c0-.78.62-1.4 1.4-1.4Zm7.91 3.32a.83.83 0 0 0-.82.66l-1.92 8.33a.83.83 0 1 0 1.62.37l1.92-8.33a.83.83 0 0 0-.8-1.03ZM6.41 6.66a.83.83 0 0 0-.64.29l-2.23 2.5a.83.83 0 0 0 0 1.1l2.23 2.5A.83.83 0 1 0 7 11.95L5.28 10l1.73-1.95a.83.83 0 0 0-.6-1.4Zm7.16 0a.83.83 0 0 0-.58 1.4L14.72 10l-1.73 1.95a.83.83 0 1 0 1.24 1.1l2.23-2.5a.83.83 0 0 0 0-1.1l-2.23-2.5a.83.83 0 0 0-.66-.3Z',
  viewBox: [0, 0, 20, 20],
};

export const developersFilled: SvgImgProp = {
  path: 'M16.94 0H3.06A3.06 3.06 0 0 0 0 3.06v13.88A3.06 3.06 0 0 0 3.06 20h13.88A3.06 3.06 0 0 0 20 16.94V3.06A3.06 3.06 0 0 0 16.94 0ZM7.01 11.95a.83.83 0 1 1-1.24 1.1l-2.23-2.5a.83.83 0 0 1 0-1.1l2.23-2.5A.83.83 0 0 1 7 8.05L5.28 10l1.73 1.95Zm4.76-5.93-1.92 8.33a.83.83 0 1 1-1.62-.37l1.92-8.33a.83.83 0 0 1 1.62.37Zm4.69 4.53-2.23 2.5a.83.83 0 0 1-1.17.07.83.83 0 0 1-.07-1.17L14.72 10l-1.73-1.95a.83.83 0 1 1 1.24-1.1l2.23 2.5c.28.31.28.79 0 1.1Z',
  viewBox: [0, 0, 20, 20],
};

export const closePanel: SvgImgProp = {
  path: 'm11.74 0-.11.01H2.75A2.76 2.76 0 0 0 0 2.76v12.48C0 16.75 1.24 18 2.75 18h13.5A2.76 2.76 0 0 0 19 15.24V2.76C19 1.25 17.76 0 16.25 0h-4.38a.75.75 0 0 0-.13-.01ZM2.75 1.5H11v15H2.75c-.7 0-1.25-.56-1.25-1.26V2.76c0-.7.55-1.25 1.25-1.25Zm9.75 0h3.75c.7 0 1.25.56 1.25 1.26v12.48c0 .7-.55 1.25-1.25 1.25H12.5V1.51ZM5.99 6c-.2 0-.38.08-.52.22L3.22 8.47a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06l-.97-.97 3.7-.01a.75.75 0 1 0-.01-1.5H5.56l.97-.96A.75.75 0 0 0 5.99 6Z',
  viewBox: [0, 0, 19, 18],
};

export const openPanel: SvgImgProp = {
  path: 'm11.74 0-.11.01H2.75A2.76 2.76 0 0 0 0 2.76v12.48C0 16.75 1.24 18 2.75 18h13.5A2.76 2.76 0 0 0 19 15.24V2.76C19 1.25 17.76 0 16.25 0h-4.38a.75.75 0 0 0-.13-.01ZM2.75 1.5H11v15H2.75c-.7 0-1.25-.56-1.25-1.26V2.76c0-.7.55-1.25 1.25-1.25Zm9.75 0h3.75c.7 0 1.25.56 1.25 1.26v12.48c0 .7-.55 1.25-1.25 1.25H12.5V1.51ZM7 6a.75.75 0 0 0-.53 1.28l.97.97H3.75a.75.75 0 1 0 0 1.49h3.7l-.98.98a.75.75 0 1 0 1.06 1.06l2.25-2.25a.75.75 0 0 0 0-1.06L7.53 6.22A.75.75 0 0 0 6.99 6Z',
  viewBox: [0, 0, 19, 18],
};

/**
 * SEARCH RESULTS
 */

export const wallet: SvgImgProp = {
  path: 'M2.25 0A2.26 2.26 0 0 0 0 2.25v13.5A2.26 2.26 0 0 0 2.25 18h13.5A2.26 2.26 0 0 0 18 15.75V5.25A2.26 2.26 0 0 0 15.75 3H2.25a.74.74 0 0 1-.75-.75c0-.42.33-.75.75-.75h12.5a.75.75 0 1 0 0-1.5H2.25ZM1.5 4.5h14.25c.42 0 .75.33.75.75v10.5c0 .42-.33.75-.75.75H2.25a.74.74 0 0 1-.75-.75V4.5ZM13.75 9a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z',
  viewBox: [0, 0, 18, 18],
};

export const walletFilled: SvgImgProp = {
  path: 'M2.25 0A2.26 2.26 0 0 0 0 2.25v13.5C0 16.99 1 18 2.25 18h13.5c1.24 0 2.25-1 2.25-2.25V5.25C18 4.01 17 3 15.75 3H2.25a.75.75 0 0 1-.75-.75c0-.42.33-.75.75-.75h12.5a.75.75 0 1 0 0-1.5H2.25Zm11.5 9a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Z',
  viewBox: [0, 0, 18, 18],
};

export const receipt: SvgImgProp = {
  path: 'M.76 0A.75.75 0 0 0 0 .75v15.5A2.76 2.76 0 0 0 2.75 19h10.5A2.76 2.76 0 0 0 16 16.25V.75a.75.75 0 0 0-1.05-.69l-1.97.88-2.2-.89a.75.75 0 0 0-.56 0L8 .95 5.78.04a.75.75 0 0 0-.56 0l-2.2.89L1.05.06A.75.75 0 0 0 .76 0ZM5.5 1.56l2.22.89c.18.07.38.07.56 0l2.22-.9 2.22.9a.75.75 0 0 0 .58-.01l1.2-.54v14.35c0 .7-.55 1.25-1.25 1.25H2.75c-.7 0-1.25-.55-1.25-1.25V1.9l1.2.54a.75.75 0 0 0 .58 0l2.22-.88ZM3.75 6a.75.75 0 1 0 0 1.5h8.5a.75.75 0 1 0 0-1.5h-8.5Zm0 4.5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5h-4.5Zm7.5 0a.75.75 0 1 0 0 1.5h1a.75.75 0 1 0 0-1.5h-1Zm-7.5 3a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5h-4.5Zm7.5 0a.75.75 0 1 0 0 1.5h1a.75.75 0 1 0 0-1.5h-1Z',
  viewBox: [0, 0, 16, 19],
};

export const receiptFilled: SvgImgProp = {
  path: 'M.76 0A.75.75 0 0 0 0 .75v15.5A2.76 2.76 0 0 0 2.75 19h10.5A2.76 2.76 0 0 0 16 16.25V.75a.75.75 0 0 0-1.05-.69l-1.97.88-2.2-.89a.75.75 0 0 0-.56 0L8 .95 5.78.04a.75.75 0 0 0-.56 0l-2.2.89L1.05.06A.75.75 0 0 0 .76 0Zm2.49 6h9.5a.75.75 0 1 1 0 1.5h-9.5a.75.75 0 1 1 0-1.5Zm0 4.5h5.5a.75.75 0 1 1 0 1.5h-5.5a.75.75 0 1 1 0-1.5Zm8.5 0h1a.75.75 0 1 1 0 1.5h-1a.75.75 0 1 1 0-1.5Zm-8.5 3h5.5a.75.75 0 1 1 0 1.5h-5.5a.75.75 0 1 1 0-1.5Zm8.5 0h1a.75.75 0 1 1 0 1.5h-1a.75.75 0 1 1 0-1.5Z',
  viewBox: [0, 0, 16, 19],
};

export const business: SvgImgProp = {
  path: 'M1.5 3.3c0-.97.82-1.8 1.89-1.8h9.22c1.06 0 1.89.83 1.89 1.8v15.2h-13V3.3ZM3.39 0A3.34 3.34 0 0 0 0 3.3v15.95c0 .41.34.75.75.75h14.5c.41 0 .75-.34.75-.75V3.3C16 1.46 14.46 0 12.6 0H3.39Zm6.26 15.74h1.31c.37 0 .66-.28.66-.64v-1.27c0-.35-.3-.64-.66-.64H9.65a.65.65 0 0 0-.66.64v1.27c0 .36.3.64.66.64Zm1.97-6.38v1.28c0 .35-.3.64-.66.64H9.65a.65.65 0 0 1-.66-.64V9.36c0-.35.3-.64.66-.64h1.31c.37 0 .66.29.66.64Zm-4.61 4.47v1.27c0 .36-.3.64-.66.64H5.03a.65.65 0 0 1-.66-.64v-1.27c0-.35.3-.64.66-.64h1.32c.36 0 .66.29.66.64Zm0-4.47v1.28c0 .35-.3.64-.66.64H5.03a.65.65 0 0 1-.66-.64V9.36c0-.35.3-.64.66-.64h1.32c.36 0 .66.29.66.64Zm4.61-4.46v1.27c0 .35-.3.64-.66.64H9.65a.65.65 0 0 1-.66-.64V4.9c0-.36.3-.64.66-.64h1.31c.37 0 .66.28.66.64Zm-4.61 0v1.27c0 .35-.3.64-.66.64H5.03a.65.65 0 0 1-.66-.64V4.9c0-.36.3-.64.66-.64h1.32c.36 0 .66.28.66.64Z',
  viewBox: [0, 0, 16, 20],
};

export const businessFilled: SvgImgProp = {
  path: 'M12.52 0H3.48A3.4 3.4 0 0 0 0 3.33V19c0 .55.47 1 1.04 1h13.92c.57 0 1.04-.45 1.04-1V3.33A3.4 3.4 0 0 0 12.52 0ZM6.96 15.33c0 .37-.32.67-.7.67H4.87c-.38 0-.7-.3-.7-.67V14c0-.37.32-.67.7-.67h1.4c.37 0 .69.3.69.67v1.33Zm0-4.66c0 .36-.32.66-.7.66H4.87c-.38 0-.7-.3-.7-.66V9.33c0-.36.32-.66.7-.66h1.4c.37 0 .69.3.69.66v1.34Zm0-4.67c0 .37-.32.67-.7.67H4.87c-.38 0-.7-.3-.7-.67V4.67c0-.37.32-.67.7-.67h1.4c.37 0 .69.3.69.67V6Zm4.87 9.33c0 .37-.32.67-.7.67h-1.4c-.37 0-.69-.3-.69-.67V14c0-.37.32-.67.7-.67h1.39c.38 0 .7.3.7.67v1.33Zm0-4.66c0 .36-.32.66-.7.66h-1.4c-.37 0-.69-.3-.69-.66V9.33c0-.36.32-.66.7-.66h1.39c.38 0 .7.3.7.66v1.34Zm0-4.67c0 .37-.32.67-.7.67h-1.4c-.37 0-.69-.3-.69-.67V4.67c0-.37.32-.67.7-.67h1.39c.38 0 .7.3.7.67V6Z',
  viewBox: [0, 0, 16, 20],
};

export const circledIndividual: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5a8.49 8.49 0 1 1 0 17 8.49 8.49 0 1 1 0-17ZM10 4c-.94 0-1.74.38-2.25.96A3.06 3.06 0 0 0 7 7c0 .73.24 1.47.75 2.04.51.58 1.31.96 2.25.96s1.74-.38 2.25-.96c.51-.57.75-1.31.75-2.04s-.24-1.47-.75-2.04A2.98 2.98 0 0 0 10 4Zm0 1.5c.56 0 .89.18 1.13.45.23.27.37.65.37 1.05s-.14.78-.37 1.05c-.24.27-.57.45-1.13.45-.56 0-.89-.18-1.13-.45A1.6 1.6 0 0 1 8.5 7c0-.4.14-.78.37-1.05.24-.27.57-.45 1.13-.45ZM6.63 11c-.9 0-1.63.74-1.63 1.63v.54c0 1 .63 1.86 1.52 2.43.9.57 2.1.9 3.48.9 1.39 0 2.58-.33 3.48-.9.9-.57 1.52-1.44 1.52-2.43v-.54c0-.9-.74-1.63-1.63-1.63H6.63Zm0 1.5h6.74c.08 0 .13.05.13.13v.54c0 .36-.23.78-.83 1.17-.6.38-1.53.66-2.67.66a5.06 5.06 0 0 1-2.67-.66c-.6-.39-.83-.81-.83-1.17v-.54c0-.08.05-.13.13-.13Z',
  viewBox: [0, 0, 20, 20],
};

export const circledIndividualFilled: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 4.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm4.5 8.27c0 1.43-1.82 2.73-4.5 2.73-2.68 0-4.5-1.3-4.5-2.73v-.34c0-.51.42-.93.93-.93h7.14c.51 0 .93.42.93.93v.34Z',
  viewBox: [0, 0, 20, 20],
};

export const individual: SvgImgProp = {
  path: 'M8 0a5.01 5.01 0 0 0 0 10A5.01 5.01 0 0 0 8 0Zm0 1.5c1.94 0 3.5 1.56 3.5 3.5S9.94 8.5 8 8.5A3.49 3.49 0 0 1 4.5 5c0-1.94 1.56-3.5 3.5-3.5ZM2.25 12A2.26 2.26 0 0 0 0 14.25v.6c0 1.47.93 2.79 2.35 3.7C3.78 19.45 5.72 20 8 20c2.28 0 4.22-.55 5.65-1.45 1.42-.91 2.35-2.23 2.35-3.7v-.6A2.26 2.26 0 0 0 13.75 12H2.25Zm0 1.5h11.5c.42 0 .75.33.75.75v.6c0 .83-.54 1.71-1.66 2.43A9.12 9.12 0 0 1 8 18.5c-2.03 0-3.71-.5-4.84-1.22-1.12-.72-1.66-1.6-1.66-2.43v-.6c0-.42.33-.75.75-.75Z',
  viewBox: [0, 0, 16, 20],
};

export const individualFilled: SvgImgProp = {
  path: 'M8 0a5.01 5.01 0 0 0 0 10A5.01 5.01 0 0 0 8 0ZM2.25 12A2.26 2.26 0 0 0 0 14.25v.6c0 1.47.93 2.79 2.35 3.7C3.78 19.45 5.72 20 8 20c2.28 0 4.22-.55 5.65-1.45 1.42-.91 2.35-2.23 2.35-3.7v-.6A2.26 2.26 0 0 0 13.75 12H2.25Z',
  viewBox: [0, 0, 16, 20],
};

export const group: SvgImgProp = {
  path: 'M4.5 0c-.94 0-1.74.38-2.25.96A3.06 3.06 0 0 0 1.5 3c0 .73.24 1.47.75 2.04.51.58 1.31.96 2.25.96s1.74-.38 2.25-.96c.51-.57.75-1.31.75-2.04S7.26 1.53 6.75.96A2.98 2.98 0 0 0 4.5 0Zm11 0c-.94 0-1.74.38-2.25.96A3.06 3.06 0 0 0 12.5 3c0 .73.24 1.47.75 2.04.51.58 1.31.96 2.25.96s1.74-.38 2.25-.96c.51-.57.75-1.31.75-2.04s-.24-1.47-.75-2.04A2.98 2.98 0 0 0 15.5 0Zm-11 1.5c.56 0 .89.18 1.13.45.23.27.37.65.37 1.05s-.14.78-.37 1.05c-.24.27-.57.45-1.13.45-.56 0-.89-.18-1.13-.45A1.6 1.6 0 0 1 3 3c0-.4.14-.78.37-1.05.24-.27.57-.45 1.13-.45Zm11 0c.56 0 .89.18 1.13.45.23.27.37.65.37 1.05s-.14.78-.37 1.05c-.24.27-.57.45-1.13.45-.56 0-.89-.18-1.13-.45A1.6 1.6 0 0 1 14 3c0-.4.14-.78.37-1.05.24-.27.57-.45 1.13-.45ZM10 5c-.94 0-1.74.38-2.25.96A3.06 3.06 0 0 0 7 8c0 .73.24 1.47.75 2.04.51.58 1.31.96 2.25.96s1.74-.38 2.25-.96c.51-.57.75-1.31.75-2.04s-.24-1.47-.75-2.04A2.98 2.98 0 0 0 10 5Zm0 1.5c.56 0 .89.18 1.13.45.23.27.37.65.37 1.05s-.14.78-.37 1.05c-.24.27-.57.45-1.13.45-.56 0-.89-.18-1.13-.45A1.6 1.6 0 0 1 8.5 8c0-.4.14-.78.37-1.05.24-.27.57-.45 1.13-.45ZM1.75 7C.79 7 0 7.79 0 8.75V10c0 1.68 1.98 3 4.5 3h.1c.23-.8.82-1.46 1.58-1.79a4.9 4.9 0 0 1-1.68.29c-1.71 0-3-.79-3-1.5V8.75c0-.14.11-.25.25-.25h4.28a4.13 4.13 0 0 1 .1-1.5H1.75Zm12.13 0a4.12 4.12 0 0 1 .09 1.5h4.28c.14 0 .25.11.25.25V10c0 .71-1.29 1.5-3 1.5a4.9 4.9 0 0 1-1.68-.29c.76.33 1.35.98 1.57 1.79h.11c2.52 0 4.5-1.32 4.5-3V8.75C20 7.79 19.21 7 18.25 7h-4.38Zm-6.63 5c-.96 0-1.75.8-1.75 1.75V15c0 .96.64 1.74 1.46 2.23.83.5 1.88.77 3.04.77 1.16 0 2.21-.27 3.04-.77.82-.49 1.46-1.27 1.46-2.23v-1.25c0-.96-.8-1.75-1.75-1.75h-5.5Zm0 1.5h5.5c.15 0 .25.1.25.25V15c0 .28-.2.63-.73.95-.54.32-1.36.55-2.27.55-.91 0-1.73-.23-2.27-.55C7.2 15.63 7 15.28 7 15v-1.25c0-.15.1-.25.25-.25Z',
  viewBox: [0, 0, 20, 18],
};

export const groupFilled: SvgImgProp = {
  path: 'M2.25.96A2.98 2.98 0 0 1 4.5 0c.94 0 1.74.38 2.25.96.51.57.75 1.31.75 2.04s-.24 1.47-.75 2.04C6.24 5.62 5.44 6 4.5 6s-1.74-.38-2.25-.96A3.06 3.06 0 0 1 1.5 3c0-.73.24-1.47.75-2.04Zm11 0A2.98 2.98 0 0 1 15.5 0c.94 0 1.74.38 2.25.96.51.57.75 1.31.75 2.04s-.24 1.47-.75 2.04c-.51.58-1.31.96-2.25.96s-1.74-.38-2.25-.96A3.06 3.06 0 0 1 12.5 3c0-.73.24-1.47.75-2.04ZM10 5c-.94 0-1.74.38-2.25.96A3.06 3.06 0 0 0 7 8c0 .73.24 1.47.75 2.04.51.58 1.31.96 2.25.96s1.74-.38 2.25-.96c.51-.57.75-1.31.75-2.04s-.24-1.47-.75-2.04A2.98 2.98 0 0 0 10 5Zm5.5 8h-.1c-.14-.56-.85-1.75-2.65-2.09C13.5 10 14 9 13.88 7h4.37c.96 0 1.75.79 1.75 1.75V10c0 1.68-1.98 3-4.5 3Zm-8.25-1c-.96 0-1.75.8-1.75 1.75V15c0 .96.64 1.74 1.46 2.23.83.5 1.88.77 3.04.77 1.16 0 2.21-.27 3.04-.77.82-.49 1.46-1.27 1.46-2.23v-1.25c0-.96-.8-1.75-1.75-1.75h-5.5Zm-2.64 1H4.5C1.98 13 0 11.68 0 10V8.75C0 7.79.79 7 1.75 7h4.38C6 9 6.5 10 7.25 10.91c-1.8.34-2.51 1.53-2.64 2.09Z',
  viewBox: [0, 0, 20, 18],
};

export const contact: SvgImgProp = {
  path: 'M2.75 0A2.76 2.76 0 0 0 0 2.75v12.5A2.76 2.76 0 0 0 2.75 18h12.5A2.76 2.76 0 0 0 18 15.25V2.75A2.76 2.76 0 0 0 15.25 0H2.75Zm0 1.5h12.5c.7 0 1.25.55 1.25 1.25v12.5c0 .7-.55 1.25-1.25 1.25H2.75c-.7 0-1.25-.55-1.25-1.25V2.75c0-.7.55-1.25 1.25-1.25ZM9 3c-.94 0-1.74.38-2.25.96A3.06 3.06 0 0 0 6 6c0 .73.24 1.47.75 2.04C7.26 8.62 8.06 9 9 9s1.74-.38 2.25-.96c.51-.57.75-1.31.75-2.04s-.24-1.47-.75-2.04A2.98 2.98 0 0 0 9 3Zm0 1.5c.56 0 .89.18 1.13.45.23.27.37.65.37 1.05s-.14.78-.37 1.05c-.24.27-.57.45-1.13.45-.56 0-.89-.18-1.13-.45A1.6 1.6 0 0 1 7.5 6c0-.4.14-.78.37-1.05.24-.27.57-.45 1.13-.45ZM5.63 10c-.9 0-1.63.74-1.63 1.63v.54c0 1 .63 1.86 1.52 2.43.9.57 2.1.9 3.48.9 1.39 0 2.58-.33 3.48-.9.9-.57 1.52-1.44 1.52-2.43v-.54c0-.9-.74-1.63-1.63-1.63H5.63Zm0 1.5h6.74c.08 0 .13.05.13.13v.54c0 .36-.23.78-.83 1.17-.6.38-1.53.66-2.67.66a5.06 5.06 0 0 1-2.67-.66c-.6-.39-.83-.81-.83-1.17v-.54c0-.08.05-.13.13-.13Z',
  viewBox: [0, 0, 18, 18],
};

export const contactFilled: SvgImgProp = {
  path: 'M15.25 0H2.75A2.75 2.75 0 0 0 0 2.75v12.5A2.75 2.75 0 0 0 2.75 18h12.5A2.75 2.75 0 0 0 18 15.25V2.75A2.75 2.75 0 0 0 15.25 0ZM9 3.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm4.5 7.77C13.5 12.7 11.68 14 9 14c-2.68 0-4.5-1.3-4.5-2.73v-.34c0-.51.42-.93.93-.93h7.14c.51 0 .93.42.93.93v.34Z',
  viewBox: [0, 0, 18, 18],
};

/**
 * ACTIONS
 */

export const edit: SvgImgProp = {
  path: 'M15.5 0c-.9 0-1.8.34-2.47 1.02L1.96 12.1c-.21.21-.37.47-.45.76l-1.48 5.2a.75.75 0 0 0 .93.92l5.2-1.48c.28-.09.54-.24.75-.45L17.98 5.97A3.51 3.51 0 0 0 15.5 0Zm0 1.5a2 2 0 0 1 1.42.58c.78.79.79 2.04 0 2.83l-.97.97-2.83-2.83.97-.97a2 2 0 0 1 1.41-.59ZM12.06 4.1l2.83 2.83-9.04 9.04a.25.25 0 0 1-.11.06l-3.9 1.12 1.12-3.9a.25.25 0 0 1 .06-.1l9.04-9.05Z',
  viewBox: [0, 0, 19, 19],
};

export const editFilled: SvgImgProp = {
  path: 'm16.83 6.77 1.22-1.22a3.25 3.25 0 0 0-4.6-4.6l-1.22 1.22 4.6 4.6Zm-5.66-3.54-9.03 9.04c-.2.19-.34.43-.43.68L.04 18.01a.75.75 0 0 0 .95.95l5.06-1.68c.25-.08.5-.23.68-.42l9.04-9.03-4.6-4.6Z',
  viewBox: [0, 0, 19, 19],
};

export const trash: SvgImgProp = {
  path: 'M9.5 0a3.5 3.5 0 0 0-3.46 3H2.63a.75.75 0 0 0-.24 0H.76a.75.75 0 1 0 0 1.5h1.07l1.26 13.01A2.76 2.76 0 0 0 5.82 20h7.36c1.4 0 2.6-1.08 2.73-2.49L17.17 4.5h1.07a.75.75 0 1 0 0-1.5h-1.63a.75.75 0 0 0-.24 0h-3.41A3.5 3.5 0 0 0 9.5 0Zm0 1.5c.94 0 1.7.63 1.93 1.5H7.57c.22-.87 1-1.5 1.93-1.5Zm-6.17 3h12.34l-1.25 12.87c-.06.65-.6 1.13-1.24 1.13H5.82c-.65 0-1.18-.48-1.24-1.13L3.33 4.5Zm4.41 2.49a.75.75 0 0 0-.74.76v7.5a.75.75 0 1 0 1.5 0v-7.5a.75.75 0 0 0-.76-.76Zm3.5 0a.75.75 0 0 0-.74.76v7.5a.75.75 0 0 0 1.44.29c.04-.1.06-.2.06-.29v-7.5a.75.75 0 0 0-.76-.76Z',
  viewBox: [0, 0, 19, 20],
};

export const trashFilled: SvgImgProp = {
  path: 'M9.5 0a3.5 3.5 0 0 0-3.46 3H.76a.75.75 0 1 0 0 1.5h1.07l1.26 13.01A2.74 2.74 0 0 0 5.82 20h7.36c1.42 0 2.6-1.07 2.73-2.49L17.17 4.5h1.07a.75.75 0 1 0 0-1.5h-5.28A3.5 3.5 0 0 0 9.5 0Zm0 1.5c.94 0 1.7.63 1.93 1.5H7.57c.22-.87 1-1.5 1.93-1.5ZM7.25 7c.42 0 .75.34.75.75v7.5a.75.75 0 1 1-1.5 0v-7.5c0-.41.34-.75.75-.75Zm4.5 0c.41 0 .75.34.75.75v7.5a.75.75 0 1 1-1.5 0v-7.5c0-.41.33-.75.75-.75Z',
  viewBox: [0, 0, 19, 20],
};

export const download: SvgImgProp = {
  path: 'M8.99 0A1 1 0 0 0 8 1.01v9.58L6.7 9.3a1 1 0 1 0-1.4 1.41l3 3a1 1 0 0 0 1.4 0l3-3a1 1 0 1 0-1.4-1.41L10 10.6V1A1 1 0 0 0 8.99 0Zm-8 12.5a1 1 0 0 0-.99 1V15c0 1.65 1.36 3 3 3h12c1.65 0 3-1.35 3-3v-1.5a1 1 0 1 0-2 0V15c0 .57-.44 1-1 1H3c-.56 0-1-.43-1-1v-1.5a1 1 0 0 0-1.01-1Z',
  viewBox: [0, 0, 18, 18],
};

export const upload: SvgImgProp = {
  path: 'M8.99 0c-.2 0-.38.08-.52.22l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v10.69a.75.75 0 1 0 1.5 0V2.56l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3A.75.75 0 0 0 8.99 0ZM2.75 5.5A2.76 2.76 0 0 0 0 8.25v7A2.76 2.76 0 0 0 2.75 18h12.5A2.76 2.76 0 0 0 18 15.25v-7a2.76 2.76 0 0 0-2.75-2.75h-1a.75.75 0 1 0 0 1.5h1c.7 0 1.25.55 1.25 1.25v7c0 .7-.55 1.25-1.25 1.25H2.75c-.7 0-1.25-.55-1.25-1.25v-7c0-.7.55-1.25 1.25-1.25h1a.75.75 0 1 0 0-1.5h-1Z',
  viewBox: [0, 0, 18, 18],
};

export const uploadThick: SvgImgProp = {
  path: 'M8.99 0a1 1 0 0 0-.7.3l-3 3a1 1 0 1 0 1.42 1.4L8 3.42v9.09a1 1 0 1 0 2 0V3.41l1.3 1.3a1 1 0 1 0 1.4-1.42l-3-3A1 1 0 0 0 9 0ZM3 5.5c-1.65 0-3 1.35-3 3V15c0 1.64 1.35 3 3 3h12c1.64 0 3-1.36 3-3V8.5c0-1.65-1.36-3-3-3h-.5a1 1 0 1 0 0 2h.5c.56 0 1 .44 1 1V15c0 .56-.44 1-1 1H3c-.56 0-1-.44-1-1V8.5c0-.56.44-1 1-1h.5a1 1 0 1 0 0-2H3Z',
  viewBox: [0, 0, 18, 18],
};

export const copy: SvgImgProp = {
  path: 'M4.75 0A4.75 4.75 0 0 0 0 4.75v8.68a2 2 0 0 0 .9 1.66l.6.41V4.75c0-1.8 1.46-3.25 3.25-3.25H15.5l-.4-.6a2 2 0 0 0-1.67-.9H4.75Zm.5 2.5A2.76 2.76 0 0 0 2.5 5.25v10A2.76 2.76 0 0 0 5.25 18h10A2.76 2.76 0 0 0 18 15.25v-10a2.76 2.76 0 0 0-2.75-2.75h-10Zm0 1.5h10c.7 0 1.25.55 1.25 1.25v10c0 .7-.55 1.25-1.25 1.25h-10c-.7 0-1.25-.55-1.25-1.25v-10C4 4.55 4.55 4 5.25 4Z',
  viewBox: [0, 0, 18, 18],
};

export const search: SvgImgProp = {
  path: 'M7.25 0a7.26 7.26 0 0 0 0 14.5 7.2 7.2 0 0 0 4.56-1.63l4.9 4.9a.75.75 0 1 0 1.06-1.06l-4.9-4.9A7.26 7.26 0 0 0 7.25 0Zm0 1.5a5.74 5.74 0 1 1 0 11.5 5.74 5.74 0 1 1 0-11.5Z',
  viewBox: [0, 0, 18, 18],
};

export const searchThick: SvgImgProp = {
  path: 'M7.24 0A7.26 7.26 0 0 0 0 7.24a7.26 7.26 0 0 0 7.24 7.25A7.2 7.2 0 0 0 11.6 13l4.68 4.68a1 1 0 1 0 1.41-1.41l-4.68-4.68a7.2 7.2 0 0 0 1.48-4.36A7.26 7.26 0 0 0 7.24 0Zm0 2a5.23 5.23 0 0 1 3.6 9.06A5.23 5.23 0 0 1 2 7.24C2 4.34 4.34 2 7.24 2Z',
  viewBox: [0, 0, 18, 18],
};

export const refresh: SvgImgProp = {
  path: 'M8 16a7.72 7.72 0 0 1-5.67-2.32A7.72 7.72 0 0 1 0 8c0-2.23.78-4.13 2.33-5.67A7.72 7.72 0 0 1 8 0a7.91 7.91 0 0 1 6 2.75V1c0-.28.1-.52.29-.71.19-.2.43-.29.71-.29.28 0 .52.1.71.29.2.19.29.43.29.71v5c0 .28-.1.52-.29.71-.19.2-.43.29-.71.29h-5a.97.97 0 0 1-.71-.29A.97.97 0 0 1 9 6c0-.28.1-.52.29-.71.19-.2.43-.29.71-.29h3.2A5.93 5.93 0 0 0 8 2c-1.67 0-3.08.58-4.25 1.75A5.79 5.79 0 0 0 2 8c0 1.67.58 3.08 1.75 4.25A5.79 5.79 0 0 0 8 14a5.9 5.9 0 0 0 5.38-3.35c.08-.18.22-.34.4-.46.2-.13.4-.19.6-.19.38 0 .67.13.86.4.19.27.21.57.06.9a7.8 7.8 0 0 1-2.93 3.41A7.84 7.84 0 0 1 8 16Z',
  viewBox: [0, 0, 16, 16],
};

export const filter: SvgImgProp = {
  path: 'M14.25 0A2.26 2.26 0 0 0 12 2.25v.25H.76a.75.75 0 1 0 0 1.5H12v.25a2.26 2.26 0 0 0 2.25 2.25 2.26 2.26 0 0 0 2.24-2.25V4h2.75a.75.75 0 1 0 0-1.5h-2.75v-.25A2.26 2.26 0 0 0 14.25 0Zm0 1.5c.42 0 .74.33.74.75v.88a.75.75 0 0 0 0 .24v.88c0 .42-.32.75-.74.75a.74.74 0 0 1-.75-.75v-.88a.75.75 0 0 0 0-.24v-.88c0-.42.32-.75.75-.75Zm-8.5 4.75A2.26 2.26 0 0 0 3.51 8.5v.25H.76a.75.75 0 1 0 0 1.5h2.75v.25a2.26 2.26 0 0 0 2.24 2.25A2.26 2.26 0 0 0 8 10.5v-.25h11.24a.75.75 0 1 0 0-1.5H8V8.5a2.26 2.26 0 0 0-2.25-2.25Zm0 1.5c.43 0 .75.33.75.75v.88a.75.75 0 0 0 0 .24v.88c0 .42-.32.75-.75.75a.74.74 0 0 1-.74-.75v-.88a.75.75 0 0 0 0-.24V8.5c0-.42.32-.75.74-.75Zm7.5 4.75A2.26 2.26 0 0 0 11 14.75V15H.76a.75.75 0 1 0 0 1.5H11v.25A2.26 2.26 0 0 0 13.25 19a2.26 2.26 0 0 0 2.24-2.25v-.25h3.75a.75.75 0 1 0 0-1.5h-3.75v-.25a2.26 2.26 0 0 0-2.24-2.25Zm0 1.5c.42 0 .75.33.75.75v.88a.75.75 0 0 0 0 .24v.88c0 .42-.33.75-.75.75a.74.74 0 0 1-.75-.75v-.88a.75.75 0 0 0 0-.24v-.88c0-.42.32-.75.75-.75Z',
  viewBox: [0, 0, 20, 19],
};

export const newWindow: SvgImgProp = {
  path: 'M17.27 0h-6.62a.71.71 0 1 0 0 1.43h4.92l-7.31 7.3a.71.71 0 1 0 1 1.01l7.31-7.3v4.9a.71.71 0 1 0 1.42 0V.82a.71.71 0 0 0-.72-.81ZM3.55 1.43A3.56 3.56 0 0 0 0 4.98v9.47C0 16.4 1.6 18 3.55 18h9.47c1.95 0 3.55-1.6 3.55-3.55V9.7a.71.71 0 1 0-1.42 0v4.74c0 1.18-.94 2.13-2.13 2.13H3.55a2.12 2.12 0 0 1-2.13-2.13V4.98c0-1.19.95-2.13 2.13-2.13H8.3a.71.71 0 1 0 0-1.42H3.55Z',
  viewBox: [0, 0, 18, 18],
};

export const newWindowThick: SvgImgProp = {
  path: 'm17.02 0-.1.01h-6.03a.95.95 0 1 0 0 1.9h3.87L8.33 8.33a.95.95 0 1 0 1.33 1.33l6.44-6.43v3.87a.95.95 0 0 0 1.3.89.95.95 0 0 0 .59-.89V1.07A.95.95 0 0 0 17.03 0ZM3.55 1.43A3.57 3.57 0 0 0 0 4.98v9.47C0 16.4 1.6 18 3.55 18h9.47c1.95 0 3.55-1.6 3.55-3.55v-4.5a.95.95 0 1 0-1.9 0v4.5c0 .92-.73 1.66-1.65 1.66H3.55c-.92 0-1.66-.74-1.66-1.66V4.98c0-.92.74-1.66 1.66-1.66h4.5a.95.95 0 1 0 0-1.89h-4.5Z',
  viewBox: [0, 0, 18, 18],
};

export const signOut: SvgImgProp = {
  path: 'M2.75 0A2.76 2.76 0 0 0 0 2.75v12.5A2.76 2.76 0 0 0 2.75 18h9a2.76 2.76 0 0 0 2.75-2.75.75.75 0 1 0-1.5 0c0 .7-.55 1.25-1.25 1.25h-9c-.7 0-1.25-.55-1.25-1.25V2.75c0-.7.55-1.25 1.25-1.25h9c.7 0 1.25.55 1.25 1.25a.75.75 0 1 0 1.5 0A2.76 2.76 0 0 0 11.75 0h-9Zm11 4.74a.75.75 0 0 0-.53 1.29l2.22 2.22H4.75a.75.75 0 1 0 0 1.5h10.69l-2.22 2.22a.75.75 0 1 0 1.06 1.06l3.5-3.5a.75.75 0 0 0 0-1.06l-3.5-3.5a.75.75 0 0 0-.54-.23Z',
  viewBox: [0, 0, 18, 18],
};

export const signOutThick: SvgImgProp = {
  path: 'M3 0C1.35 0 0 1.35 0 3v12c0 1.64 1.35 3 3 3h8.5c1.39 0 2.62-.91 2.97-2.25a1 1 0 0 0-1.94-.5c-.1.38-.55.75-1.03.75H3c-.56 0-1-.44-1-1V3c0-.56.44-1 1-1h8.5c.48 0 .93.37 1.03.75a1 1 0 0 0 1.94-.5A3.04 3.04 0 0 0 11.5 0H3Zm10.99 4.99a1 1 0 0 0-.7 1.72L14.6 8H5.5a1 1 0 1 0 0 2h9.09l-1.3 1.3a1 1 0 1 0 1.42 1.4l3-3a1 1 0 0 0 0-1.4l-3-3a1 1 0 0 0-.72-.31Z',
  viewBox: [0, 0, 18, 18],
};

/**
 * CURRENCY
 */

export const refreshCurrency: SvgImgProp = {
  path: 'M11.01 0a9 9 0 0 0-7.35 3.82.75.75 0 1 0 1.22.86 7.48 7.48 0 0 1 13.61 4l-1.2-1.21a.75.75 0 1 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.06 0l.18-.17.14-.15 1.93-1.93a.75.75 0 1 0-1.06-1.06l-.76.76A9 9 0 0 0 11.01 0ZM3 7c-.2 0-.38.08-.52.22l-.17.17c-.06.04-.1.1-.15.15L.23 9.47a.75.75 0 1 0 1.06 1.06l.76-.76a9 9 0 0 0 16.31 4.41.75.75 0 1 0-1.22-.86 7.48 7.48 0 0 1-13.61-4l1.2 1.21A.75.75 0 1 0 5.8 9.47L3.54 7.22A.75.75 0 0 0 3 7Z',
  viewBox: [0, 0, 23, 18],
};

export const refreshCurrencyThick: SvgImgProp = {
  path: 'M10.76 0a8.99 8.99 0 0 0-7.03 3.4A1 1 0 1 0 5.3 4.63a6.98 6.98 0 0 1 12.4 3.37l-.48-.47a1 1 0 1 0-1.41 1.42l2.25 2.25a1 1 0 0 0 1.41 0l2.25-2.25a1 1 0 1 0-1.41-1.42l-.59.59A9.02 9.02 0 0 0 10.76 0ZM3.25 6.5a1 1 0 0 0-.7.3L.32 9.03a1 1 0 1 0 1.41 1.42l.59-.6a8.99 8.99 0 0 0 15.97 4.73 1 1 0 1 0-1.56-1.25A6.98 6.98 0 0 1 4.35 10l.46.47a1 1 0 1 0 1.41-1.42L3.97 6.8a1 1 0 0 0-.72-.29Z',
  viewBox: [0, 0, 23, 18],
};

export const swap: SvgImgProp = {
  path: 'M0 4.51a.71.71 0 0 0 1.22.5l2.57-2.58v14.85a.71.71 0 1 0 1.42 0V2.43L7.8 5.01a.71.71 0 1 0 1-1L5.01.2a.71.71 0 0 0-1 0L.2 4.02a.71.71 0 0 0-.2.5Zm9 9c0 .18.08.36.2.49l3.8 3.78a.71.71 0 0 0 1 0L17.78 14a.71.71 0 1 0-1-1l-2.57 2.57V.72A.71.71 0 0 0 12.99.2a.7.7 0 0 0-.2.5v14.86l-2.58-2.58a.71.71 0 0 0-1.22.52Z',
  viewBox: [0, 0, 18, 18],
};

export const swapThick: SvgImgProp = {
  path: 'M0 4.75a.95.95 0 0 0 1.62.66L3.8 3.24v13.8a.95.95 0 1 0 1.9 0V3.24l2.17 2.17a.95.95 0 1 0 1.33-1.34L5.41.3a.95.95 0 0 0-1.34 0L.3 4.07a.95.95 0 0 0-.29.68Zm8.52 8.53c0 .24.1.47.28.65l3.79 3.78a.95.95 0 0 0 1.33 0l3.79-3.78a.95.95 0 1 0-1.34-1.34l-2.17 2.17V.96a.94.94 0 1 0-1.9 0v13.8l-2.16-2.17a.95.95 0 0 0-1.62.69Z',
  viewBox: [0, 0, 18, 18],
};

export const balance: SvgImgProp = {
  path: 'M2.75 0A2.76 2.76 0 0 0 0 2.75v12a2.76 2.76 0 0 0 2.75 2.75h12.5A2.76 2.76 0 0 0 18 14.75v-12A2.76 2.76 0 0 0 15.25 0H2.75Zm0 1.5h12.5c.7 0 1.25.55 1.25 1.25v12c0 .7-.55 1.25-1.25 1.25H2.75c-.7 0-1.25-.55-1.25-1.25v-12c0-.7.55-1.25 1.25-1.25Zm5.99.99a.75.75 0 0 0-.74.76v.8a2.74 2.74 0 0 0-2.5 2.7A2.76 2.76 0 0 0 8.25 9.5h1.5c.7 0 1.25.55 1.25 1.25S10.45 12 9.75 12h-.88a.75.75 0 0 0-.24 0h-.38c-.59 0-1.07-.4-1.21-.93a.75.75 0 1 0-1.45.37A2.74 2.74 0 0 0 8 13.46v.79a.75.75 0 1 0 1.5 0v-.75h.25a2.76 2.76 0 0 0 2.75-2.75A2.76 2.76 0 0 0 9.75 8h-1.5C7.55 8 7 7.45 7 6.75S7.55 5.5 8.25 5.5h1c.58 0 1.06.4 1.2.92a.75.75 0 1 0 1.46-.39A2.74 2.74 0 0 0 9.5 4.04v-.79a.75.75 0 0 0-.76-.76Z',
  viewBox: [0, 0, 18, 18],
};

export const balanceFilled: SvgImgProp = {
  path: 'M15.25 17.5H2.75A2.75 2.75 0 0 1 0 14.75v-12A2.75 2.75 0 0 1 2.75 0h12.5A2.75 2.75 0 0 1 18 2.75v12a2.75 2.75 0 0 1-2.75 2.75ZM9.75 8h-1.5a1.25 1.25 0 0 1 0-2.5h1c.56 0 1.06.38 1.2.92a.75.75 0 0 0 1.46-.39 2.75 2.75 0 0 0-2.41-2v-.78a.75.75 0 0 0-1.5 0v.76a2.75 2.75 0 0 0 .25 5.49h1.5a1.25 1.25 0 0 1 0 2.5h-1.5c-.57 0-1.07-.38-1.21-.93a.75.75 0 0 0-1.45.38A2.74 2.74 0 0 0 8 13.48v.77a.75.75 0 0 0 1.5 0v-.75h.25a2.75 2.75 0 0 0 0-5.5Z',
  viewBox: [0, 0, 18, 18],
};

export const currency: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm0 1.5a8.49 8.49 0 1 1 0 17 8.49 8.49 0 1 1 0-17Zm-.26 1.99a.75.75 0 0 0-.74.76v.58c-1.4.19-2.5 1.35-2.5 2.8 0 1.57 1.3 2.87 2.88 2.87h1c.9 0 1.62.72 1.62 1.63 0 .9-.72 1.62-1.63 1.62H9.25c-.59 0-1.07-.4-1.21-.93a.75.75 0 1 0-1.45.37A2.74 2.74 0 0 0 9 15.21v.54a.75.75 0 1 0 1.5 0v-.53c1.66-.07 3-1.42 3-3.1 0-1.71-1.4-3.12-3.13-3.12h-1C8.62 9 8 8.4 8 7.62c0-.76.6-1.37 1.38-1.37h.87c.58 0 1.06.4 1.2.92a.75.75 0 1 0 1.46-.39A2.74 2.74 0 0 0 10.5 4.8v-.54a.75.75 0 0 0-.76-.76Z',
  viewBox: [0, 0, 20, 20],
};

export const currencyFilled: SvgImgProp = {
  path: 'M10 0a10.01 10.01 0 0 0 0 20 10.01 10.01 0 0 0 0-20Zm-.25 3.5c.41 0 .75.34.75.75v.52c1.13.1 2.1.9 2.4 2.01a.75.75 0 1 1-1.44.4 1.25 1.25 0 0 0-1.21-.93h-.88a1.38 1.38 0 0 0 0 2.75h1a3.13 3.13 0 0 1 .13 6.24v.51a.75.75 0 1 1-1.5 0v-.52a2.74 2.74 0 0 1-2.41-2.03.75.75 0 1 1 1.45-.38c.14.55.64.93 1.2.93h1.13a1.63 1.63 0 0 0 0-3.25h-1A2.88 2.88 0 0 1 9 4.78v-.53c0-.41.34-.75.75-.75Z',
  viewBox: [0, 0, 20, 20],
};

export const bank: SvgImgProp = {
  path: 'M10 0c-.63 0-1.26.2-1.79.58L.31 6.29a.75.75 0 0 0 0 1.24c.15.1.31.15.48.15h18.42a.8.8 0 0 0 .46-.14.75.75 0 0 0 0-1.24L11.8.57A3.05 3.05 0 0 0 10 0Zm0 1.54c.3 0 .6.09.85.27l5.97 4.33H3.18l5.97-4.33c.26-.18.55-.27.85-.27Zm0 1.52c-.28 0-.55.11-.74.3a1.01 1.01 0 0 0 0 1.45 1.07 1.07 0 0 0 1.48 0 1.01 1.01 0 0 0 0-1.45c-.2-.19-.46-.3-.74-.3ZM2.37 8.71v6.68C1.08 15.44 0 16.43 0 17.69 0 18.99 1.14 20 2.46 20h15.08c1.32 0 2.46-1.02 2.46-2.31 0-1.26-1.08-2.25-2.37-2.3V8.71h-1.58v6.67h-1.84V8.71h-1.58v6.67H10.8V8.71H9.21v6.67H7.37V8.71H5.79v6.67H3.95V8.71H2.37Zm.1 8.21h15.07c.52 0 .88.36.88.77 0 .4-.36.77-.88.77H2.46c-.52 0-.88-.36-.88-.77 0-.4.36-.77.88-.77Z',
  viewBox: [0, 0, 20, 20],
};

export const bankFilled: SvgImgProp = {
  path: 'M10 0c-.63 0-1.26.2-1.79.58L.31 6.29a.75.75 0 0 0 0 1.24c.15.1.31.15.48.15h18.42a.8.8 0 0 0 .46-.14c.14-.1.24-.23.29-.39a.75.75 0 0 0-.28-.86L11.78.58A3.05 3.05 0 0 0 10 0Zm0 3.06c.58 0 1.05.46 1.05 1.03 0 .57-.47 1.02-1.05 1.02-.58 0-1.05-.45-1.05-1.02 0-.57.47-1.03 1.05-1.03ZM2.37 8.71v7.7H1.9c-1.02 0-1.9.79-1.9 1.8C0 19.2.88 20 1.9 20h16.18c1.02 0 1.91-.79 1.91-1.8 0-1-.89-1.8-1.9-1.8h-.47V8.7h-1.58v7.7h-1.84V8.7h-1.58v7.7H10.8V8.7H9.2v7.7H7.37V8.7H5.79v7.7H3.95V8.7H2.37Z',
  viewBox: [0, 0, 20, 20],
};

/**
 * SUPPORTING
 */

export const carretUp: SvgImgProp = {
  path: 'M5 0c.12 0 .23.04.32.12L9.87 4.3c.13.12.17.3.1.45a.46.46 0 0 1-.42.26H.45a.46.46 0 0 1-.42-.26.4.4 0 0 1 .1-.45L4.68.12C4.77.04 4.88 0 5 0Z',
  viewBox: [0, 0, 10, 5],
};

export const carretDown: SvgImgProp = {
  path: 'M5 5a.48.48 0 0 1-.32-.12L.13.7a.4.4 0 0 1-.1-.45A.46.46 0 0 1 .45 0h9.1c.18 0 .35.1.42.26a.4.4 0 0 1-.1.45L5.32 4.88A.48.48 0 0 1 5 5Z',
  viewBox: [0, 0, 10, 5],
};

export const chevronRight: SvgImgProp = {
  path: 'M4.35 6 0 1.4 1.32 0 7 6l-5.68 6L0 10.6 4.35 6Z',
  viewBox: [0, 0, 7, 12],
};

export const chevronLeft: SvgImgProp = {
  path: 'M2.65 6 7 1.4 5.68 0 0 6l5.68 6L7 10.6 2.65 6Z',
  viewBox: [0, 0, 7, 12],
};

export const chevronUp: SvgImgProp = {
  path: 'M6 2.65 10.6 7 12 5.68 6 0 0 5.68 1.4 7 6 2.65Z',
  viewBox: [0, 0, 12, 7],
};

export const chevronDown: SvgImgProp = {
  path: 'M6 4.65 1.4.3 0 1.62 6 7.3l6-5.68L10.6.3 6 4.65Z',
  viewBox: [0, 0, 12, 8],
};

export const calendar: SvgImgProp = {
  path: 'M3.25 0A3.26 3.26 0 0 0 0 3.25v11.5A3.26 3.26 0 0 0 3.25 18h11.5A3.26 3.26 0 0 0 18 14.75V3.25A3.26 3.26 0 0 0 14.75 0H3.25Zm0 1.5h11.5c.97 0 1.75.77 1.75 1.75V4h-15v-.75c0-.98.77-1.75 1.75-1.75Zm-1.75 4h15v9.25c0 .97-.78 1.75-1.75 1.75H3.25c-.98 0-1.75-.78-1.75-1.75V5.5Zm3.25 2a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9 7.5A1.25 1.25 0 1 0 9 10a1.25 1.25 0 0 0 0-2.5Zm4.25 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM4.75 12a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5ZM9 12a1.25 1.25 0 1 0 0 2.5A1.25 1.25 0 0 0 9 12Z',
  viewBox: [0, 0, 18, 18],
};

export const calendarFilled: SvgImgProp = {
  path: 'M18 4v-.75C18 1.45 16.54 0 14.75 0H3.25A3.25 3.25 0 0 0 0 3.25V4h18ZM0 5.5v9.25C0 16.55 1.46 18 3.25 18h11.5c1.8 0 3.25-1.46 3.25-3.25V5.5H0ZM4.75 15a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm0-4.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5ZM9 15a1.25 1.25 0 1 1 0-2.5A1.25 1.25 0 0 1 9 15Zm0-4.5A1.25 1.25 0 1 1 9 8a1.25 1.25 0 0 1 0 2.5Zm4.25 0a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z',
  viewBox: [0, 0, 18, 18],
};

export const photo: SvgImgProp = {
  path: 'M2.75 0A2.76 2.76 0 0 0 0 2.75v12.5A2.76 2.76 0 0 0 2.75 18h12.5A2.76 2.76 0 0 0 18 15.25V2.75A2.76 2.76 0 0 0 15.25 0H2.75Zm0 1.5h12.5c.7 0 1.25.55 1.25 1.25v12.5l-.02.2-6.07-5.88a2.02 2.02 0 0 0-2.82 0l-6.07 5.87-.02-.19V2.75c0-.7.55-1.25 1.25-1.25Zm9.5 2c-.69 0-1.3.28-1.69.72-.38.44-.56.99-.56 1.53s.18 1.1.56 1.53c.4.44 1 .72 1.69.72s1.3-.28 1.69-.72c.38-.44.56-.99.56-1.53s-.18-1.1-.56-1.53c-.4-.44-1-.72-1.69-.72Zm0 1.5c.31 0 .45.09.56.22.12.12.19.32.19.53 0 .2-.07.4-.19.53-.1.13-.25.22-.56.22-.31 0-.45-.09-.56-.22a.82.82 0 0 1-.19-.53c0-.2.07-.4.19-.53.1-.13.25-.22.56-.22ZM9 10.5c.13 0 .26.04.36.15l6.04 5.84-.15.01H2.75l-.15-.01 6.04-5.84c.1-.1.23-.16.36-.16Z',
  viewBox: [0, 0, 18, 18],
};

export const photoFilled: SvgImgProp = {
  path: 'M2.75 0A2.75 2.75 0 0 0 0 2.75v12.5c0 .5.14.97.37 1.38L7.6 9.57a2.01 2.01 0 0 1 2.82 0l7.21 7.06c.24-.4.38-.88.38-1.38V2.75A2.75 2.75 0 0 0 15.25 0H2.75Zm9.5 4a1.75 1.75 0 1 1 0 3.5 1.75 1.75 0 0 1 0-3.5ZM9 10.5a.52.52 0 0 0-.36.15l-7.19 7.02c.39.21.83.33 1.3.33h12.5c.47 0 .9-.12 1.3-.32l-7.19-7.03A.52.52 0 0 0 9 10.5Z',
  viewBox: [0, 0, 18, 18],
};

export const show: SvgImgProp = {
  path: 'M12 0C6.32 0 1.34 3.92.01 9.06a.75.75 0 1 0 1.46.38C2.62 5 7.03 1.5 11.99 1.5S21.4 5 22.52 9.44a.75.75 0 1 0 1.46-.38C22.65 3.92 17.65 0 11.99 0Zm0 4a4.84 4.84 0 0 0 0 9.66A4.84 4.84 0 0 0 12 4Zm0 1.5a3.32 3.32 0 0 1 3.33 3.33A3.32 3.32 0 0 1 12 12.16a3.32 3.32 0 0 1-3.33-3.33A3.32 3.32 0 0 1 12 5.5Z',
  viewBox: [0, 0, 24, 14],
};

export const hide: SvgImgProp = {
  path: 'M21.23 2c-.19 0-.38.09-.51.23l-6.7 6.7H14l-1.18 1.18-4.23 4.23-1.18 1.17v.01l-5.2 5.2a.75.75 0 1 0 1.06 1.07l4.98-4.98a4.98 4.98 0 0 0 8.74-3.3c0-1.5-.66-2.83-1.7-3.75l2.23-2.22a10.64 10.64 0 0 1 5 6.4.75.75 0 1 0 1.44-.37 12 12 0 0 0-5.33-7.14l3.14-3.14A.75.75 0 0 0 21.23 2ZM12 4.5C6.41 4.5 1.37 8.33.02 13.58c-.1.4.14.81.54.91.4.1.81-.13.91-.54C2.64 9.42 7.17 6.01 12 6.01c.86 0 1.7.1 2.52.3l1.23-1.22c-1.2-.38-2.46-.58-3.75-.58Zm0 4a5 5 0 0 0-5 5l.02.3 1.87-1.86c.33-.67.88-1.22 1.55-1.56l1.86-1.86-.3-.01Zm2.25 2.33A3.48 3.48 0 0 1 12 17.01c-1.08 0-2.04-.5-2.68-1.27l1.42-1.42a1.5 1.5 0 1 0 2.08-2.07l1.42-1.42Z',
  viewBox: [0, 0, 24, 24],
};

export const document: SvgImgProp = {
  path: 'M2.25 0A2.26 2.26 0 0 0 0 2.25v15.5A2.26 2.26 0 0 0 2.25 20h11.5A2.26 2.26 0 0 0 16 17.75V2.25A2.26 2.26 0 0 0 13.75 0H2.25Zm0 1.5h11.5c.42 0 .75.33.75.75v15.5c0 .42-.33.75-.75.75H2.25a.74.74 0 0 1-.75-.75V2.25c0-.42.33-.75.75-.75ZM4.75 4a.75.75 0 1 0 0 1.5h2.5a.75.75 0 1 0 0-1.5h-2.5Zm0 4.5a.75.75 0 1 0 0 1.5h6.5a.75.75 0 1 0 0-1.5h-6.5Zm0 3a.75.75 0 1 0 0 1.5h6.5a.75.75 0 1 0 0-1.5h-6.5Zm0 3a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5h-4.5Z',
  viewBox: [0, 0, 18, 20],
};

export const documentFilled: SvgImgProp = {
  path: 'M13.75 0H2.25A2.25 2.25 0 0 0 0 2.25v15.5A2.25 2.25 0 0 0 2.25 20h11.5c1.24 0 2.25-1 2.25-2.25V2.25C16 1.01 15 0 13.75 0Zm-9.5 4h3.5a.75.75 0 1 1 0 1.5h-3.5a.75.75 0 1 1 0-1.5Zm5.5 12h-5.5a.75.75 0 1 1 0-1.5h5.5a.75.75 0 1 1 0 1.5Zm2-3h-7.5a.75.75 0 1 1 0-1.5h7.5a.75.75 0 1 1 0 1.5Zm0-3h-7.5a.75.75 0 1 1 0-1.5h7.5a.75.75 0 1 1 0 1.5Z',
  viewBox: [0, 0, 18, 20],
};

export const attachment: SvgImgProp = {
  path: 'M11.97 0C10.68 0 9.4.5 8.42 1.52L.27 9.94a.9.9 0 0 0-.27.64.93.93 0 0 0 .26.65.87.87 0 0 0 .62.26.85.85 0 0 0 .62-.27l8.15-8.43a3.2 3.2 0 0 1 4.65 0 3.47 3.47 0 0 1 0 4.81l-7.85 8.12c-.63.65-1.63.65-2.26 0a1.67 1.67 0 0 1 0-2.33l6.25-6.46a.9.9 0 0 0 .27-.65.93.93 0 0 0-.25-.64.87.87 0 0 0-.63-.27.85.85 0 0 0-.62.28l-6.25 6.47a3.55 3.55 0 0 0 0 4.88 3.27 3.27 0 0 0 4.72 0l7.85-8.12a5.34 5.34 0 0 0 0-7.36A4.93 4.93 0 0 0 11.97 0Z',
  viewBox: [0, 0, 17, 18],
};

export const email: SvgImgProp = {
  path: 'M3.25 0A3.26 3.26 0 0 0 0 3.25v9.5A3.26 3.26 0 0 0 3.25 16h13.5A3.26 3.26 0 0 0 20 12.75v-9.5A3.26 3.26 0 0 0 16.75 0H3.25Zm0 1.5h13.5c.98 0 1.75.77 1.75 1.75v.55L10 8.4 1.5 3.8v-.55c0-.98.77-1.75 1.75-1.75Zm-1.75 4 8.14 4.41a.75.75 0 0 0 .72 0l8.14-4.4v7.24c0 .97-.77 1.75-1.75 1.75H3.25c-.98 0-1.75-.78-1.75-1.75V5.51Z',
  viewBox: [0, 0, 20, 16],
};

export const emailFilled: SvgImgProp = {
  path: 'M3 0A3 3 0 0 0 .01 2.75L10 8.15l9.99-5.4A3 3 0 0 0 17 0H3ZM0 4.45V13a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V4.45l-9.64 5.21a.74.74 0 0 1-.72 0L0 4.45Z',
  viewBox: [0, 0, 20, 16],
};

export const phone: SvgImgProp = {
  path: 'M5.52 0a2.8 2.8 0 0 0-1 .11L3.34.46A3.77 3.77 0 0 0 .76 3.1c-.74 2.81.1 6.14 2.31 9.88 2.2 3.73 4.7 6.11 7.54 6.89 1.28.34 2.65.01 3.62-.89l.9-.83c1-.94 1.16-2.5.34-3.6l-1.3-1.77a2.8 2.8 0 0 0-3.05-1l-2.04.6c-.09.02-.29-.1-.47-.25A7.04 7.04 0 0 1 7.4 10.5a7.27 7.27 0 0 1-.8-1.74c-.1-.33-.07-.37-.06-.48 0-.06.03-.11.08-.15l1.51-1.4c.87-.79 1.14-2.06.65-3.12l-.92-2A2.78 2.78 0 0 0 5.52 0Zm-.1 1.5c.45.04.87.31 1.07.73l.91 2a1.2 1.2 0 0 1-.29 1.4L5.6 7.02c-.32.28-.51.67-.56 1.09-.01.1-.04.52.09 1.03.13.5.4 1.19.96 2.13.52.87 1 1.54 1.54 2 .17.15.9.84 1.87.55l2.05-.61a1.3 1.3 0 0 1 1.41.46l1.3 1.77c.37.5.3 1.18-.15 1.6l-.9.84c-.58.54-1.43.75-2.2.54-2.3-.63-4.56-2.66-6.65-6.2-2.09-3.55-2.74-6.49-2.15-8.74.2-.74.8-1.35 1.56-1.58l1.18-.35c.15-.04.3-.06.46-.05Z',
  viewBox: [0, 0, 16, 20],
};

export const phoneFilled: SvgImgProp = {
  path: 'm6.46 1.31.86 2.03c.37.88.16 1.92-.52 2.57L5.2 7.43a.76.76 0 0 0-.24.67 8.76 8.76 0 0 0 2.97 5.11c.2.17.49.22.74.13l1.87-.62c.86-.29 1.8.04 2.33.82l1.23 1.8c.62.91.5 2.16-.26 2.92l-.81.83a3.02 3.02 0 0 1-3.06.77c-2.53-.79-4.87-3.14-7-7.05C.84 8.89.1 5.57.71 2.84A3.26 3.26 0 0 1 2.86.44L3.94.09c1-.32 2.09.2 2.52 1.22Z',
  viewBox: [0, 0, 16, 20],
};

export const world: SvgImgProp = {
  path: 'M8 0a8.01 8.01 0 0 0 0 16A8.01 8.01 0 0 0 8 0Zm-.67 1.37v1.3H6V1.64c.43-.14.87-.23 1.33-.27Zm1.34 0c1.15.11 2.21.52 3.12 1.14l-.46.16v.66h-.66L10 4v1.33l1.33-.66 2 .66-.66 1.34L11.33 6H10L8.67 7.33v1.34L10 10h1.33l.67 1.33-1.4 2.81a6.66 6.66 0 0 1-3.32.49l-1.3-1.27.02-2.7-2-2H2V7.34l3.33-2V4h1.34l.66.67L8.67 4V1.37Z',
  viewBox: [0, 0, 16, 16],
};

export const location: SvgImgProp = {
  path: 'M8.5 0a8.51 8.51 0 0 0-6.46 14.02s3.62 4.13 4.9 5.36c.87.83 2.25.83 3.11 0 1.47-1.4 4.9-5.35 4.9-5.35l.01-.01A8.51 8.51 0 0 0 8.5 0Zm0 1.5a6.99 6.99 0 0 1 5.32 11.54c0 .01-3.53 4.04-4.8 5.26a.73.73 0 0 1-1.04 0c-1.07-1.02-4.79-5.24-4.8-5.26A6.99 6.99 0 0 1 8.5 1.5Zm0 4c-.94 0-1.74.38-2.25.96A3.07 3.07 0 0 0 5.5 8.5c0 .73.24 1.47.75 2.05.51.57 1.31.95 2.25.95s1.74-.38 2.25-.95c.51-.58.75-1.32.75-2.05 0-.73-.24-1.47-.75-2.04A2.98 2.98 0 0 0 8.5 5.5Zm0 1.5c.56 0 .89.19 1.13.45.23.27.37.66.37 1.05 0 .4-.14.78-.37 1.05-.24.27-.57.45-1.13.45-.56 0-.89-.18-1.13-.45A1.6 1.6 0 0 1 7 8.5c0-.4.14-.78.37-1.05C7.61 7.2 7.94 7 8.5 7Z',
  viewBox: [0, 0, 17, 20],
};

export const locationFilled: SvgImgProp = {
  path: 'M8.5 0a8.51 8.51 0 0 0-6.45 14.02c.15.18 3.71 4.22 4.9 5.36a2.24 2.24 0 0 0 3.1 0c1.38-1.32 4.76-5.2 4.91-5.36A8.5 8.5 0 0 0 8.5 0Zm0 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z',
  viewBox: [0, 0, 17, 20],
};

export const lock: SvgImgProp = {
  path: 'M8 0C5.8 0 4 1.8 4 4v2H2.25A2.26 2.26 0 0 0 0 8.25v9.5A2.26 2.26 0 0 0 2.25 20h11.5A2.26 2.26 0 0 0 16 17.75v-9.5A2.26 2.26 0 0 0 13.75 6H12V4c0-2.2-1.8-4-4-4Zm0 1.5c1.39 0 2.5 1.11 2.5 2.5v2h-5V4c0-1.39 1.11-2.5 2.5-2.5Zm-5.75 6h11.5c.42 0 .75.33.75.75v9.5c0 .42-.33.75-.75.75H2.25a.74.74 0 0 1-.75-.75v-9.5c0-.42.33-.75.75-.75Zm5.75 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z',
  viewBox: [0, 0, 16, 20],
};

export const lockFilled: SvgImgProp = {
  path: 'M8 0C5.8 0 4 1.8 4 4v2H2.25A2.25 2.25 0 0 0 0 8.25v9.5A2.25 2.25 0 0 0 2.25 20h11.5c1.24 0 2.25-1 2.25-2.25v-9.5C16 7.01 15 6 13.75 6H12V4c0-2.2-1.8-4-4-4Zm0 1.5c1.39 0 2.5 1.11 2.5 2.5v2h-5V4c0-1.39 1.11-2.5 2.5-2.5Zm0 10a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z',
  viewBox: [0, 0, 16, 20],
};

export const grid: SvgImgProp = {
  markup:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 2.75a1 1 0 0 0-1 1V9H9V2.75H3.75ZM9 11H2.75v5.25a1 1 0 0 0 1 1H9V11Zm2 0h6.25v5.25a1 1 0 0 1-1 1H11V11Zm6.25-2H11V2.75h5.25a1 1 0 0 1 1 1V9ZM.75 3.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v12.5a3 3 0 0 1-3 3H3.75a3 3 0 0 1-3-3V3.75Z" fill="currentColor" />',
  viewBox: [0, 0, 20, 20],
};

export const list: SvgImgProp = {
  path: 'M2.5 1.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm0 5a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM1.25 12.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM4.75.25a1 1 0 0 0 0 2h14.5a1 1 0 1 0 0-2H4.75Zm-1 6a1 1 0 0 1 1-1h14.5a1 1 0 1 1 0 2H4.75a1 1 0 0 1-1-1Zm1 4a1 1 0 1 0 0 2h14.5a1 1 0 1 0 0-2H4.75Z',
  viewBox: [0, 0, 21, 13],
};

export const id: SvgImgProp = {
  path: 'M2.25 0A2.26 2.26 0 0 0 0 2.25v11.5A2.26 2.26 0 0 0 2.25 16h15.5A2.26 2.26 0 0 0 20 13.75V2.25A2.26 2.26 0 0 0 17.75 0H2.25Zm0 1.5h15.5c.42 0 .75.33.75.75v11.5c0 .42-.33.75-.75.75H2.25a.74.74 0 0 1-.75-.75V2.25c0-.42.33-.75.75-.75ZM6 4.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm5.25 1a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5h-4.5Zm-7 3a.75.75 0 0 0-.75.75c0 .75 0 2.25 2.45 2.25h.1C8.5 11.5 8.5 10 8.5 9.25a.75.75 0 0 0-.75-.75h-3.5Zm7 .5a.75.75 0 1 0 0 1.5h4.5a.75.75 0 1 0 0-1.5h-4.5Z',
  viewBox: [0, 0, 20, 16],
};

export const birthdate: SvgImgProp = {
  path: 'M8.98 0a.75.75 0 0 0-.64.39s-.38.7-.79 1.52c-.41.83-.85 1.72-1 2.43-.17.76.16 1.48.64 1.93.06.06.13.08.2.13-.23.32-.39.68-.39 1.1V9H3.2A3.2 3.2 0 0 0 0 12.2c0 .87.4 1.68 1.05 2.2l.72 5.23A2.76 2.76 0 0 0 4.49 22h9.02a2.76 2.76 0 0 0 2.72-2.37l.72-5.23A2.85 2.85 0 0 0 18 12.2 3.2 3.2 0 0 0 14.8 9H11V7.5c0-.42-.16-.78-.38-1.1.06-.05.13-.07.2-.13.47-.45.8-1.17.64-1.93-.16-.71-.6-1.6-1.01-2.43-.41-.82-.8-1.52-.8-1.52A.75.75 0 0 0 8.99 0ZM9 2.38l.1.2c.4.8.82 1.78.89 2.08.04.19-.02.34-.21.52-.2.19-.52.32-.78.32s-.59-.13-.78-.32c-.2-.18-.25-.33-.2-.52a15 15 0 0 1 .87-2.08l.11-.2ZM9 7c.29 0 .5.21.5.5V9h-1V7.5c0-.29.21-.5.5-.5Zm-5.8 3.5H14.8c.94 0 1.69.75 1.69 1.7 0 .66-.49 1.24-1.1 1.3h-.21c-.7 0-.95-.23-1.12-.46-.17-.22-.2-.41-.2-.41a.75.75 0 0 0-1.49 0s-.03.19-.2.41c-.17.23-.42.46-1.12.46-.7 0-.94-.23-1.11-.46-.18-.22-.21-.41-.21-.41a.75.75 0 0 0-1.48 0s-.03.19-.2.41c-.18.23-.42.46-1.12.46-.7 0-.95-.23-1.12-.46-.17-.22-.2-.41-.2-.41a.75.75 0 0 0-1.49 0s-.03.19-.2.41c-.17.23-.42.46-1.12.46h-.2c-.62-.06-1.11-.64-1.11-1.3 0-.95.75-1.7 1.7-1.7Zm1.67 3.65c.44.45 1.1.85 2.07.85.96 0 1.62-.4 2.06-.85.44.45 1.1.85 2.06.85s1.63-.4 2.06-.85c.44.45 1.1.85 2.07.85h.17l-.61 4.42c-.1.63-.61 1.08-1.24 1.08H4.49c-.63 0-1.15-.46-1.24-1.08L2.65 15l.16.01c.96 0 1.63-.4 2.06-.85Z',
  viewBox: [0, 0, 18, 22],
};

export const bookmark: SvgImgProp = {
  path: 'M3.25.5A3.26 3.26 0 0 0 0 3.75v15a.75.75 0 0 0 1.19.6L7 15.18l5.81 4.19a.75.75 0 0 0 1.19-.61v-15A3.26 3.26 0 0 0 10.75.5h-7.5Zm0 1.5h7.5c.97 0 1.75.77 1.75 1.75v13.54l-5.06-3.65a.75.75 0 0 0-.88 0L1.5 17.3V3.75C1.5 2.77 2.27 2 3.25 2Z',
  viewBox: [0, 0, 14, 20],
};

export const bookmarkFilled: SvgImgProp = {
  path: 'M13.25 19.5a.75.75 0 0 1-.44-.14L7 15.17l-5.81 4.19A.75.75 0 0 1 0 18.75v-15C0 1.95 1.46.5 3.25.5h7.5C12.55.5 14 1.96 14 3.75v15a.75.75 0 0 1-.75.75Z',
  viewBox: [0, 0, 14, 20],
};
