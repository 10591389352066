export const BRL_BANKS = [
  { name: 'Access Soluções de Pagto SA', routingCode: '332' },
  { name: 'Advanced CC Ltda', routingCode: '117' },
  { name: 'Ágora CTVM SA', routingCode: '565' },
  { name: 'Asaas IP SA', routingCode: '461' },
  { name: 'Ativa SA Investimentos CCTVM', routingCode: '188' },
  { name: 'Azumi Distribuidora de Títulos', routingCode: '463' },
  { name: 'B&T Corretora de Câmbio Ltda.', routingCode: '080' },
  { name: 'Banco Bradescard', routingCode: '063' },
  { name: 'Banco BTG Pactual SA', routingCode: '208' },
  { name: 'Central Bank of Brazil', routingCode: '002' },
  { name: 'Banco Cifra', routingCode: '233' },
  { name: 'MI Development Bank', routingCode: '023' },
  { name: 'Banco Digio', routingCode: '335' },
  { name: 'Banco do Brasil SA', routingCode: '001' },
  { name: 'Banco Finaxis SA', routingCode: '094' },
  { name: 'Banco Genial', routingCode: '125' },
  { name: 'Banco Inbursa', routingCode: '012' },
  { name: 'Banco Inter', routingCode: '077' },
  { name: 'Banco Investcred Unibanco SA', routingCode: '249' },
  { name: 'Banco Itaú Consignado SA', routingCode: '029' },
  { name: 'Banco John Deere SA', routingCode: '217' },
  { name: 'Banco Master S/A', routingCode: '243' },
  { name: 'Original Bank', routingCode: '212' },
  { name: 'Banco Pan SA', routingCode: '623' },
  { name: 'Banco Semear', routingCode: '743' },
  { name: 'Banco Senff SA', routingCode: '276' },
  { name: 'Banco Sicoob SA', routingCode: '756' },
  { name: 'Banco Sistema', routingCode: '754' },
  { name: 'Banco Sumitomo Mitsui Brasileiro', routingCode: '464' },
  { name: 'Banco Topázio SA', routingCode: '082' },
  { name: 'Banco Voiter', routingCode: '653' },
  { name: 'Bancoseguro SA', routingCode: '081' },
  { name: 'Bari Companhia Hipotecária', routingCode: '268' },
  { name: 'Banco ABC Brasil SA', routingCode: '246' },
  { name: 'Banco ABN AMRO SA', routingCode: '075' },
  { name: 'Banco Agibank SA', routingCode: '121' },
  { name: 'Banco Alfa SA', routingCode: '025' },
  { name: 'Banco Andbank SA', routingCode: '065' },
  { name: 'Banco Arbi SA', routingCode: '213' },
  { name: 'Banco B3 SA', routingCode: '096' },
  { name: 'Banco Bandepe SA', routingCode: '024' },
  { name: 'Banco Banestes SA', routingCode: '021' },
  { name: 'Banco BBI SA', routingCode: '036' },
  { name: 'Banco BMG SA', routingCode: '318' },
  { name: 'Banco BNP Paribas Brasil SA', routingCode: '752' },
  { name: 'Banco Bocom BBM SA', routingCode: '107' },
  { name: 'Banco Bradesco Berj SA', routingCode: '122' },
  { name: 'Banco Bradesco Financ. SA', routingCode: '394' },
  { name: 'Banco Bradesco SA', routingCode: '237' },
  { name: 'Banco BS2 SA', routingCode: '218' },
  { name: 'Banco BV SA', routingCode: '413' },
  { name: 'Banco C6 Consig', routingCode: '626' },
  { name: 'Banco C6 SA', routingCode: '336' },
  { name: 'Banco Caixa Geral Brasil SA', routingCode: '473' },
  { name: 'Banco Cargill SA', routingCode: '040' },
  { name: 'Banco CCB Brasil SA', routingCode: '320' },
  { name: 'Banco Cédula SA', routingCode: '266' },
  { name: 'Banco Cetelem SA', routingCode: '739' },
  { name: 'Banco Citibank SA', routingCode: '745' },
  { name: 'Banco Clássico SA', routingCode: '241' },
  { name: 'Banco Cooperativo Sicredi SA', routingCode: '748' },
  { name: 'Banco Credit Agricole Brasil SA', routingCode: '222' },
  { name: 'Banco Credit Suisse SA', routingCode: '505' },
  { name: 'Banco Crefisa SA', routingCode: '069' },
  { name: 'Banco da Amazônia SA', routingCode: '003' },
  { name: 'Banco da China Brasil SA', routingCode: '083' },
  { name: 'Banco Daycoval SA', routingCode: '707' },
  { name: 'Banco Digimais SA', routingCode: '654' },
  { name: 'Banco do Estado de Sergipe SA', routingCode: '047' },
  { name: 'Banco do Estado do Pará SA', routingCode: '037' },
  { name: 'Banco do Estado do Rio Grande do Sul SA', routingCode: '041' },
  { name: 'Banco do Nordeste do Brasil SA', routingCode: '004' },
  { name: 'Banco Fator SA', routingCode: '265' },
  { name: 'Banco Fibra SA', routingCode: '224' },
  { name: 'Banco Guanabara SA', routingCode: '612' },
  { name: 'Banco HSBC SA', routingCode: '269' },
  { name: 'Banco Industrial do Brasil SA', routingCode: '604' },
  { name: 'Banco ItaúBank SA', routingCode: '479' },
  { name: 'Banco JP Morgan SA', routingCode: '376' },
  { name: 'Banco KDB Brasil SA', routingCode: '076' },
  { name: 'Banco KEB Hana do Brasil SA', routingCode: '757' },
  { name: 'Banco La Nacion Argentina', routingCode: '300' },
  { name: 'Banco La Provincia B Aires BCE', routingCode: '495' },
  { name: 'Banco Letsbank SA', routingCode: '630' },
  { name: 'Banco Luso Brasileiro SA', routingCode: '600' },
  { name: 'Banco Mercantil do Brasil SA', routingCode: '389' },
  { name: 'Banco Mizuho SA', routingCode: '370' },
  { name: 'Banco Modal SA', routingCode: '746' },
  { name: 'Banco Morgan Stanley SA', routingCode: '066' },
  { name: 'Banco MUFG Brasil SA', routingCode: '456' },
  { name: 'Banco Ourinvest SA', routingCode: '712' },
  { name: 'Banco Paulista SA', routingCode: '611' },
  { name: 'Banco Pine SA', routingCode: '643' },
  { name: 'Banco Rabobank Intl Brasil SA', routingCode: '747' },
  { name: 'Banco Rendimento SA', routingCode: '633' },
  { name: 'Banco Ribeirão Preto SA', routingCode: '741' },
  { name: 'Banco Rodobens SA', routingCode: '120' },
  { name: 'Banco Safra SA', routingCode: '422' },
  { name: 'Banco Santander Brasil SA', routingCode: '033' },
  { name: 'Banco Société Générale Brasil', routingCode: '366' },
  { name: 'Banco Sofisa SA', routingCode: '637' },
  { name: 'Banco Triângulo SA', routingCode: '634' },
  { name: 'Banco Tricury SA', routingCode: '018' },
  { name: 'Banco Votorantim SA', routingCode: '655' },
  { name: 'Banco VR SA', routingCode: '610' },
  { name: 'Banco Western Union', routingCode: '119' },
  { name: 'Banco Woori Bank do Brasil SA', routingCode: '124' },
  { name: 'Banco XP SA', routingCode: '348' },
  { name: 'Banco J. Safra SA', routingCode: '074' },
  { name: 'BCV Banco de Crédito e Varejo SA', routingCode: '250' },
  { name: 'BGC Liquidez DTVM Ltda', routingCode: '134' },
  { name: 'BMP SCMEPP Ltda', routingCode: '274' },
  { name: 'BNDES', routingCode: '007' },
  { name: 'BNY Mellon Banco SA', routingCode: '017' },
  { name: 'BofA Merrill Lynch BM SA', routingCode: '755' },
  { name: 'BR Partners BI', routingCode: '126' },
  { name: 'Braza Bank SA Banco de Câmbio', routingCode: '128' },
  { name: 'BRB Banco de Brasília SA', routingCode: '070' },
  { name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários', routingCode: '173' },
  { name: 'Broker Brasil Corretora de Câmbio', routingCode: '142' },
  { name: 'C. Suisse Hedging Griffo CV S/A', routingCode: '011' },
  { name: 'Caixa Econômica Federal', routingCode: '104' },
  { name: 'Carol DTVM Ltda.', routingCode: '288' },
  { name: 'Cartos SCD SA', routingCode: '324' },
  { name: 'Caruana SCFI', routingCode: '130' },
  { name: 'Casa Crédito SA SCM', routingCode: '159' },
  { name: 'CCM Desp Trans SC e RS', routingCode: '016' },
  { name: 'CCR de Abelardo Luz', routingCode: '322' },
  { name: 'CCR de São Miguel do Oeste', routingCode: '273' },
  { name: 'Celcoin Instituição de Pagamento', routingCode: '509' },
  { name: 'Citibank NA', routingCode: '477' },
  { name: 'CM Capital Markets CCTVM Ltda', routingCode: '180' },
  { name: 'Codepe CVC SA', routingCode: '127' },
  { name: 'Confederação Nacional das Cooperativas Centrais Unicred', routingCode: '136' },
  { name: 'Confidence CC SA', routingCode: '060' },
  { name: 'Central Credit Cooperative', routingCode: '085' },
  { name: 'Cora SCD SA', routingCode: '403' },
  { name: 'Credialiança CCR', routingCode: '098' },
  { name: 'Credicoamo Crédito Rural Cooperativa', routingCode: '010' },
  { name: 'Credisan CC', routingCode: '089' },
  { name: 'Credisis Central de Cooperativas', routingCode: '097' },
  { name: 'Credsystem Sociedade de Crédito', routingCode: '428' },
  { name: 'Cresol Confederação', routingCode: '133' },
  { name: 'Delcred Sociedade de Crédito', routingCode: '435' },
  { name: 'Deutsche Bank SA Banco Alemão', routingCode: '487' },
  { name: 'Dock Payment Institution', routingCode: '301' },
  { name: 'Ebanx IP Ltda.', routingCode: '383' },
  { name: 'Ebury Banco de Câmbio SA', routingCode: '144' },
  { name: 'EFI SA Instituição de Pagamento', routingCode: '364' },
  { name: 'Facta SA CFI', routingCode: '149' },
  { name: 'Fair CC SA', routingCode: '196' },
  { name: 'Fiducia SCMEPP Ltda', routingCode: '382' },
  { name: 'Finvest Distribuidora de Títulos e Valores', routingCode: '512' },
  { name: 'Fitbank Instituição de Pagamento', routingCode: '450' },
  { name: 'Frente Corretora de Câmbio SA', routingCode: '285' },
  { name: 'Gazincred SA Sociedade de Crédito Financiamento e Investimento', routingCode: '478' },
  { name: 'Genial Investimentos CVM SA', routingCode: '278' },
  { name: 'Get Money CC Ltda', routingCode: '138' },
  { name: 'Global SCM Ltda', routingCode: '384' },
  { name: 'Goldman Sachs do Brasil BM SA', routingCode: '064' },
  { name: 'Guide Investimentos SA Corretora de Valores', routingCode: '177' },
  { name: 'Guitta CC Ltda', routingCode: '146' },
  { name: 'Haitong Banco de Investimento do Brasil SA', routingCode: '078' },
  { name: 'Hemera DTVM Ltda', routingCode: '448' },
  { name: 'Hipercard BM SA', routingCode: '062' },
  { name: 'HR Digital Sociedade de Crédito', routingCode: '523' },
  { name: 'HS Financeira', routingCode: '189' },
  { name: 'Hub IP SA', routingCode: '396' },
  { name: 'IB CCTVM SA', routingCode: '271' },
  { name: 'ICAP do Brasil CTVM Ltda.', routingCode: '157' },
  { name: 'ICBC do Brasil BM SA', routingCode: '132' },
  { name: 'ID Corretora de Títulos e Valores Mobiliários', routingCode: '439' },
  { name: 'Intesa Sanpaolo Brasil SA BM', routingCode: '139' },
  { name: 'Issuer IP Ltda.', routingCode: '597' },
  { name: 'Itaú Unibanco SA', routingCode: '341' },
  { name: 'Iugu Payment Institution', routingCode: '401' },
  { name: 'JPMorgan Chase Bank', routingCode: '488' },
  { name: 'Kirton Bank', routingCode: '399' },
  { name: 'Lastro RDV DTVM Ltda', routingCode: '293' },
  { name: 'Lecca CFI SA', routingCode: '105' },
  { name: 'Levycam CCV Ltda', routingCode: '145' },
  { name: 'Mercado Pago IP Ltda.', routingCode: '323' },
  { name: 'Mirae Asset Brasil CCTVM Ltda', routingCode: '447' },
  { name: 'Neon Corretora de Títulos e Valores', routingCode: '113' },
  { name: 'Neon Pagamentos SA Instituição de Pagamento', routingCode: '536' },
  { name: 'Nova Futura CTVM Ltda', routingCode: '191' },
  { name: 'Novo Banco Continental SA', routingCode: '753' },
  { name: 'Nu Invest Corretora de Valores', routingCode: '140' },
  { name: 'Nu Payments IP', routingCode: '260' },
  { name: 'Oliveira Trust DTVM SA', routingCode: '111' },
  { name: 'Omni Banco SA', routingCode: '613' },
  { name: 'Órama DTVM SA', routingCode: '325' },
  { name: 'Oslo Capital DTVM SA', routingCode: '331' },
  { name: 'PagSeguro Internet IP SA', routingCode: '290' },
  { name: 'Paraná Banco SA', routingCode: '254' },
  { name: 'Parati CFI SA', routingCode: '326' },
  { name: 'Pefisa SACFI', routingCode: '174' },
  { name: 'PicPay', routingCode: '380' },
  { name: 'PicPay Bank Banco Múltiplo SA', routingCode: '079' },
  { name: 'Pinbank Brasil Instituição de Pagamento', routingCode: '529' },
  { name: 'Planner CV SA', routingCode: '100' },
  { name: 'Planner Sociedade de Crédito', routingCode: '410' },
  { name: 'Polocred SCMEPP Ltda', routingCode: '093' },
  { name: 'QI SCD SA', routingCode: '329' },
  { name: 'Qista SA Crédito Financiamento e Investimento', routingCode: '516' },
  { name: 'RB Investimentos DTVM Ltda', routingCode: '283' },
  { name: 'Reag DTVM SA', routingCode: '528' },
  { name: 'Renascença DTVM Ltda', routingCode: '101' },
  { name: 'RTS - Financial Transfers and Solutions', routingCode: '590' },
  { name: 'RJI Corretora de Títulos e Valores', routingCode: '506' },
  { name: 'Sefer Investimentos DTVM Ltda', routingCode: '407' },
  { name: 'Senso CCVM SA', routingCode: '545' },
  { name: 'Serviçoop', routingCode: '190' },
  { name: 'Simpaul', routingCode: '365' },
  { name: 'Singulare CTVM SA', routingCode: '363' },
  { name: 'Sisprime do Brasil Cooperativa de Crédito', routingCode: '084' },
  { name: 'Social Bank Banco Múltiplo SA', routingCode: '412' },
  { name: 'Socred SA SCMEPP', routingCode: '183' },
  { name: 'SRM Bank', routingCode: '533' },
  { name: 'State Street BR SA Banco Comercial', routingCode: '014' },
  { name: 'Stone Instituição de Pagamento', routingCode: '197' },
  { name: 'Superdigital IP SA', routingCode: '340' },
  { name: 'Scotiabank Brasil', routingCode: '751' },
  { name: 'Terra Investimentos DTVM', routingCode: '307' },
  { name: 'Toro CTVM SA', routingCode: '352' },
  { name: 'Travelex Banco de Câmbio SA', routingCode: '095' },
  { name: 'Treviso CC SA', routingCode: '143' },
  { name: 'Trinus Capital DTVM', routingCode: '360' },
  { name: 'Trinus Sociedade de Crédito', routingCode: '444' },
  { name: 'Trustee DTVM Ltda', routingCode: '438' },
  { name: 'Tullett Prebon Brasil CVC Ltda', routingCode: '131' },
  { name: 'UBS Brasil BI SA', routingCode: '129' },
  { name: 'UBS Brasil CCTVM SA', routingCode: '015' },
  { name: 'Unavanti Sociedade de Crédito', routingCode: '460' },
  { name: 'Unida DTVM Ltda', routingCode: '194' },
  { name: 'Uniprime Coopcentral Ltda', routingCode: '099' },
  { name: 'UY3 SCD S/A', routingCode: '457' },
  { name: 'Vips CC Ltda', routingCode: '298' },
  { name: 'Vortx DTVM Ltda', routingCode: '310' },
  { name: 'Warren CVMC Ltda', routingCode: '371' },
  { name: 'Will Financeira SACFI', routingCode: '280' },
  { name: 'XP Investimentos CCTVM S/A', routingCode: '102' },
];

export const COL_BANKS = [
  { name: 'Ban100 S.A', routingCode: '1558' },
  { name: 'Banco Agrario', routingCode: '1040' },
  { name: 'Banco Av Villas', routingCode: '1052' },
  { name: 'Banco BTG Pactual', routingCode: '1805' },
  { name: 'Banco Caja Social BCSC SA', routingCode: '1032' },
  { name: 'Banco Contactar', routingCode: '1819' },
  { name: 'Banco Cooperativo Coopcentral', routingCode: '1066' },
  { name: 'Banco Davivienda S.A', routingCode: '1051' },
  { name: 'Banco Falabella S.A.', routingCode: '1062' },
  { name: 'Banco Finandina S.A.', routingCode: '1063' },
  { name: 'Banco GNB Sudameris', routingCode: '1012' },
  { name: 'Banco J.P. Morgan Colombia S.A.', routingCode: '1071' },
  { name: 'Banco Mundo Mujer', routingCode: '1047' },
  { name: 'Banco Pichincha', routingCode: '1060' },
  { name: 'Banco Popular', routingCode: '1002' },
  { name: 'Banco Santander de Negocios Colombia S.A', routingCode: '1065' },
  { name: 'Banco Serfinanza S.A', routingCode: '1069' },
  { name: 'Banco Union S.A', routingCode: '1303' },
  { name: 'Banco W', routingCode: '1053' },
  { name: 'Banco de Bogota', routingCode: '1001' },
  { name: 'Banco de Occidente', routingCode: '1023' },
  { name: 'Banco de las Microfinanzas - Bancamia S.A.', routingCode: '1059' },
  { name: 'Bancoldex S.A.', routingCode: '1031' },
  { name: 'Bancolombia', routingCode: '1007' },
  { name: 'Bancoomeva', routingCode: '1061' },
  { name: 'BBVA Colombia', routingCode: '1013' },
  { name: 'BNP Paribas Colombia', routingCode: '1042' },
  { name: 'Bold CF', routingCode: '1808' },
  { name: 'Citibank', routingCode: '1009' },
  { name: 'Coink', routingCode: '1812' },
  { name: 'Coltefinanciera S.A', routingCode: '1370' },
  { name: 'Confiar Cooperativa Financiera', routingCode: '1292' },
  { name: 'Coofinep Cooperativa Financiera', routingCode: '1291' },
  { name: 'Cooperativa Financiera de Antioquia', routingCode: '1283' },
  { name: 'Cootrafa Cooperativa Financiera', routingCode: '1289' },
  { name: 'Daviplata', routingCode: '1551' },
  { name: 'Ding Tecnipagos S.A', routingCode: '1802' },
  { name: 'Financiera Juriscoop S.A. Compañia de Financiamiento', routingCode: '1121' },
  { name: 'Global66', routingCode: '1814' },
  { name: 'ITAU', routingCode: '1006' },
  { name: 'Iris', routingCode: '1637' },
  { name: 'JFK Cooperativa Financiera', routingCode: '1286' },
  { name: 'Lulo Bank S.A.', routingCode: '1070' },
  { name: 'Mibanco S.A.', routingCode: '1067' },
  { name: 'Movii', routingCode: '1801' },
  { name: 'NU Colombia', routingCode: '1809' },
  { name: 'Nequi', routingCode: '1507' },
  { name: 'Powwi', routingCode: '1803' },
  { name: 'Rappipay', routingCode: '1811' },
  { name: 'Santander Consumer', routingCode: '1813' },
  { name: 'Scotiabank Colpatria S.A', routingCode: '1019' },
  { name: 'Uala', routingCode: '1804' },
];

const generateBankOptions = bankList =>
  bankList.map(bank => ({
    label: `${bank.name} (${bank.routingCode})`,
    value: bank.name,
  }));

export const BRL_BANKS_OPTIONS = generateBankOptions(BRL_BANKS);
export const COL_BANKS_OPTIONS = generateBankOptions(COL_BANKS);
