import { Link, NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import React, { FC } from 'react';
import cx from 'classnames';

import { checkmark } from 'components/core/Svg/images/icons';
import Svg from 'components/core/Svg/Svg';

import { MenuItemProps } from './MenuItem.types';
import styles from './MenuItem.module.scss';

const MenuItem: FC<MenuItemProps> = ({
  children,
  className,
  dataTestId,
  href,
  isActive,
  isCta,
  isDisabled,
  isNavlink = false,
  label,
  onClick,
  rel,
  showCheckmarkForActive = false,
  svgImg,
  svgImgForActivePath,
  svgSize = 1.8,
  target,
  to,
  useWholePathForMatching = false,
  variant = 'dropdown',
}) => {
  const filteredProps = {};
  let isMatchedPath = false;
  let determinedSvgImage = svgImg;

  let Component;
  if (isNavlink) {
    Component = NavLink;
    const resolvedPath = useResolvedPath(to || '');
    Object.assign(filteredProps, { to });
    isMatchedPath = !!useMatch({ end: useWholePathForMatching, path: resolvedPath.pathname });
  } else if (to) {
    Component = Link;
    Object.assign(filteredProps, { to });
  } else if (href) {
    Component = 'a';
    Object.assign(filteredProps, { href, rel, target });
  } else {
    Component = 'button';
  }

  if ((isMatchedPath || isActive) && svgImgForActivePath) {
    determinedSvgImage = svgImgForActivePath;
  }

  return (
    <Component
      className={cx(
        styles.root,
        styles[`variant--${variant}`],
        isCta && styles.isCta,
        (isMatchedPath || isActive) && styles.isActive,
        isDisabled && styles.isDisabled,
        determinedSvgImage && styles.hasIcon,
        className,
      )}
      data-testid={dataTestId}
      onClick={isDisabled ? undefined : onClick}
      {...filteredProps}
    >
      <div className={styles.contentWrapper}>
        {determinedSvgImage && (
          <span className={styles.iconContainer}>
            <Svg img={determinedSvgImage} size={svgSize} />
          </span>
        )}
        {label && <span className={styles.label}>{label}</span>}
        {children}
        {showCheckmarkForActive && (isMatchedPath || isActive) && (
          <Svg className={styles.checkmarkIcon} img={checkmark} size={[1.6, 1.2]} />
        )}
      </div>
    </Component>
  );
};

export default MenuItem;
