import {
  Navigate,
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';

import { ORGANIZATIONS_ROUTES, ORGANIZATION_ROUTES } from 'router/constants';
import { showError } from 'services/notificationService';
import { useGetOrganizationQuery, useGetOrganizationsQuery } from 'store/api/rootApi';
import LoaderView from 'components/views/LoaderView/LoaderView';
import OrganizationContext from 'context/OrganizationContext';
import localStorageService from 'services/localStorageService';

import { LAST_ORGANIZATION_ID_LOCAL_STORAGE_KEY } from './OrganizationContext.constants';
import { OrganizationContextType } from './OrganizationContext.types';

export const OrganizationContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { organizationId: organizationIdFromUrlParam } = useParams() as { organizationId: string };
  const {
    data: organizations,
    isLoading: isLoadingUserOrganizations,
    isFetching: isFetchingUserOrganizations,
  } = useGetOrganizationsQuery();
  const {
    data: organization,
    isLoading: isLoadingOrganization,
    isFetching: isFetchingOrganization,
  } = useGetOrganizationQuery(
    {
      organizationId: organizationIdFromUrlParam,
    },
    { skip: !organizationIdFromUrlParam },
  );

  const lastWorkingOrganizationId = useMemo(() => {
    const storedId = localStorageService.get(LAST_ORGANIZATION_ID_LOCAL_STORAGE_KEY);
    if (organizations && !organizations?.find(org => org.id === storedId)) {
      localStorageService.remove(LAST_ORGANIZATION_ID_LOCAL_STORAGE_KEY);
      return null;
    }
    return storedId;
  }, [organizations]);

  const currentOrganizationId = useMemo(() => {
    return organizationIdFromUrlParam || lastWorkingOrganizationId || null;
  }, [organizationIdFromUrlParam, lastWorkingOrganizationId]);

  useEffect(() => {
    if (currentOrganizationId && !isFetchingOrganization && organization) {
      localStorageService.set(LAST_ORGANIZATION_ID_LOCAL_STORAGE_KEY, organizationIdFromUrlParam);
    }
  }, [currentOrganizationId, isFetchingOrganization, organization, organizationIdFromUrlParam]);

  const canUsePlatform = useMemo(() => organization?.platform.canUsePlatform, [organization]);

  const isInvalidOrganizationId = useMemo(
    () => organizationIdFromUrlParam && !isFetchingOrganization && !organization,
    [organizationIdFromUrlParam, isFetchingOrganization, organization],
  );

  useEffect(() => {
    if (!organizations) {
      return;
    }
    if (lastWorkingOrganizationId && !organizationIdFromUrlParam) {
      navigate(
        generatePath(ORGANIZATIONS_ROUTES.ORGANIZATION.absolute, {
          organizationId: lastWorkingOrganizationId,
        }),
      );
    }
    if (!currentOrganizationId || isInvalidOrganizationId) {
      if (isInvalidOrganizationId) {
        showError('Invalid organization ID.');
      }

      if (organizations?.length === 0) {
        navigate(ORGANIZATIONS_ROUTES.CREATE_ORGANIZATION.absolute);
      } else {
        const redirectOrganizationId = lastWorkingOrganizationId || organizations?.[0]?.id;
        navigate(
          generatePath(ORGANIZATIONS_ROUTES.ORGANIZATION.absolute, {
            organizationId: redirectOrganizationId,
          }),
        );
      }
    }
  }, [
    currentOrganizationId,
    isInvalidOrganizationId,
    lastWorkingOrganizationId,
    navigate,
    organizationIdFromUrlParam,
    organizations,
  ]);

  const contextValue = useMemo(
    (): OrganizationContextType => ({
      currentOrganizationData: organization,
      currentOrganizationId,
      isFetchingCurrentOrganization: isFetchingOrganization,
      isFetchingUserOrganizations,
      isLoadingCurrentOrganization: isLoadingOrganization,
      isLoadingUserOrganizations,
      userOrganizations: organizations || [],
    }),
    [
      organization,
      currentOrganizationId,
      isFetchingOrganization,
      isLoadingOrganization,
      organizations,
      isFetchingUserOrganizations,
      isLoadingUserOrganizations,
    ],
  );

  if (!organizations || !organization || isLoadingOrganization) {
    return <LoaderView />;
  }

  const pendingVerificationPath = generatePath(ORGANIZATION_ROUTES.PENDING_VERIFICATION.absolute, {
    organizationId: organizationIdFromUrlParam,
  });
  if (!canUsePlatform && !matchPath(pendingVerificationPath, pathname)) {
    return <Navigate replace to={pendingVerificationPath} />;
  }

  return (
    <OrganizationContext.Provider value={contextValue}>{children}</OrganizationContext.Provider>
  );
};
