import React, { ReactNode } from 'react';

import {
  ApiTransactionSummaryAttributes,
  ApiTransactionSummaryAttributesBank,
  TransactionType,
} from 'store/api/api.types';
import {
  ParsedTransactionAttributes,
  TransactionEntityLabels,
  TransactionEntityLabelsAttributes,
} from 'helpers/transaction.helpers.types';
import { formatAmount, getUriId, getUriIdAndChainId, isFiat } from 'utils/format';
import HexId from 'components/core/HexId/HexId';

export const CONDUIT_ACCOUNT_NAME = 'My Conduit Account';

export const getBankNameWithAccountNumberPart = (
  bank?: Pick<ApiTransactionSummaryAttributesBank, 'bankName' | 'accountNumber'>,
): string => (bank ? `${bank.bankName} (${bank.accountNumber.slice(-4)})` : '');

export const getTransactionEntityLabels = ({
  transactionType,
  recipientName,
  recipientBankLabel,
  senderName,
  senderBankLabel,
  isFiatToFiat = false,
}: TransactionEntityLabelsAttributes): TransactionEntityLabels => {
  let formattedSenderBankLabel = senderBankLabel ? ` - ${senderBankLabel}` : '';
  let senderLabel: ReactNode = senderName;
  let senderSublabel;
  let formattedRecipientBankLabel = recipientBankLabel ? ` - ${recipientBankLabel}` : '';
  let recipientLabel: ReactNode = recipientName;
  let recipientSublabel;

  switch (transactionType) {
    case TransactionType.Deposit:
      recipientLabel = CONDUIT_ACCOUNT_NAME;
      senderLabel = <HexId id={`From ${senderName}`} minLeftChars={10} />;
      formattedSenderBankLabel = '';
      break;
    case TransactionType.Withdrawal:
      senderLabel = CONDUIT_ACCOUNT_NAME;
      recipientLabel = <HexId id={recipientName || ''} />;
      formattedRecipientBankLabel = '';
      break;
    case TransactionType.Onramp:
      recipientLabel = isFiatToFiat
        ? `${recipientName} - ${recipientBankLabel}`
        : CONDUIT_ACCOUNT_NAME;
      recipientSublabel = isFiatToFiat ? '' : `(True Recipient: ${recipientName})`;
      formattedRecipientBankLabel = '';
      break;
    case TransactionType.Offramp:
      senderLabel = CONDUIT_ACCOUNT_NAME;
      senderSublabel = `(True Sender: ${senderName})`;
      formattedSenderBankLabel = '';
      break;
    default:
      break;
  }

  return {
    recipientBankLabel: formattedRecipientBankLabel,
    recipientLabel,
    recipientSublabel,
    senderBankLabel: formattedSenderBankLabel,
    senderLabel,
    senderSublabel,
  };
};

export const getParsedTransactionAttributes = (
  attributes: ApiTransactionSummaryAttributes,
): ParsedTransactionAttributes => {
  const { createdAt, destination, source, status, transactionType } = attributes;

  // Transaction time
  const date = new Date(createdAt);
  const creationTime = {
    day: date.getDate(),
    month: date.toLocaleString('en-US', { month: 'short' }),
    year: date.getFullYear(),
  };

  const targetAsset = destination.assetType && getUriIdAndChainId(destination.assetType);

  const targetAmount = formatAmount({
    amount: destination.amount,
    assetType: destination.assetType,
  });

  const sourceAsset = source.assetType && getUriIdAndChainId(source.assetType);

  const sourceAmount =
    source.amount && formatAmount({ amount: source.amount, assetType: source.assetType });

  const sender =
    transactionType === TransactionType.Deposit
      ? getUriId(source.id)
      : source.bank?.nameOnBankAccount;
  const recipient =
    transactionType === TransactionType.Withdrawal
      ? getUriId(destination.id)
      : destination.bank?.nameOnBankAccount;

  const isFiatToFiat = isFiat(sourceAsset) && isFiat(targetAsset);

  const labels = getTransactionEntityLabels({
    isFiatToFiat,
    recipientBankLabel: getBankNameWithAccountNumberPart(destination.bank),
    recipientName: recipient,
    senderBankLabel: getBankNameWithAccountNumberPart(source.bank),
    senderName: sender,
    transactionType,
  });

  return {
    creationTime,
    sourceAmount,
    sourceAsset,
    status,
    targetAmount,
    targetAsset,
    transactionType,
    ...labels,
  };
};
