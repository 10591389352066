import React, { ReactElement, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { useCurrentUser } from 'context/CurrentUserContext';
import { useOrganization } from 'context/OrganizationContext';
import Button from 'components/core/Button/Button';
import ContentSection from 'components/core/ContentSection/ContentSection';
import IdentitiesTable from 'components/dedicated/organization/senders-recipients/IdentitiesTable/IdentitiesTable';
import ModalIdentityCreate from 'components/dedicated/organization/senders-recipients/ModalIdentityCreate/ModalIdentityCreate';
import PageHeader from 'components/core/PageHeader/PageHeader';
import modalService from 'services/modalService';

const CounterpartiesView = (): ReactElement => {
  const { currentOrganizationId } = useOrganization();
  const { isUserAllowedTo } = useCurrentUser();
  const canCreateIdentity = useMemo(
    () => isUserAllowedTo(currentOrganizationId, ActionName.CreateIdentity),
    [currentOrganizationId, isUserAllowedTo],
  );
  return (
    <>
      <PageHeader showBorder title='Counterparties'>
        {canCreateIdentity && (
          <Button
            label='Add New Counterparty'
            onClick={() =>
              modalService.openModal(
                {
                  title: 'Sender/Recipient Details',
                },
                <ModalIdentityCreate organizationId={currentOrganizationId} />,
              )
            }
            type='submit'
            variant='primary'
          />
        )}
      </PageHeader>
      <ContentSection flexGrow={1}>
        <IdentitiesTable organizationId={currentOrganizationId} />
      </ContentSection>
    </>
  );
};

export default CounterpartiesView;
