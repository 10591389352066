import { ASSET_TYPES } from 'constants/assetTypes';
import { CountryCode } from 'constants/countries';
import { CurrencySelectFieldProps } from 'components/core/Form/CurrencySelectField/CurrencySelectField.types';

export const getAvailableAssetTypesByBankCountry = (
  country: CountryCode,
): CurrencySelectFieldProps['assetTypes'] => {
  switch (country) {
    case 'BRA':
      return [ASSET_TYPES.FIAT_BRL, ASSET_TYPES.FIAT_USD, ASSET_TYPES.FIAT_EUR];
    case 'COL':
      return [ASSET_TYPES.FIAT_COP, ASSET_TYPES.FIAT_USD, ASSET_TYPES.FIAT_EUR];
    case 'MEX':
      return [ASSET_TYPES.FIAT_MXN, ASSET_TYPES.FIAT_USD, ASSET_TYPES.FIAT_EUR];
    default:
      return [ASSET_TYPES.FIAT_USD, ASSET_TYPES.FIAT_EUR];
  }
};
