import { format } from 'date-fns';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { DATEPICKER_BUTTON_LABELS } from 'components/core/DatePickerButton/DatePickerButton.constants';
import {
  DATEPICKER_CLICK_OUTSIDE_DROPDOWN_CLASS_NAME,
  FILTER_LABELS,
} from 'components/core/DatePicker/DatePicker.constants';
import { DatePicker } from 'components/core/DatePicker/DatePicker';
import {
  DatePickerButtonProps,
  DatePickerExportedFunctions,
  DatePickerOnChangeProps,
  PREBUILT_FILTERS,
} from 'components/core/DatePicker/DatePicker.types';
import { applyFilterFor } from 'components/core/DatePicker/components/DatepickerPrebuiltFilters.utils';
import { calendar, calendarFilled, chevronDown } from 'components/core/Svg/images/icons';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Svg from 'components/core/Svg/Svg';

import styles from './DatePickerButton.module.scss';

export const DatePickerButton = forwardRef<DatePickerExportedFunctions, DatePickerButtonProps>(
  ({ onChange, initialState = {}, defaultFilter = PREBUILT_FILTERS.LAST_90_DAYS }, ref) => {
    const [isActive, setIsActive] = useState(false);
    const [buttonLabel, setButtonLabel] = useState(FILTER_LABELS.LAST_90_DAYS);
    const [isFiltersApplied, setIsFiltersApplied] = useState(false);

    const [appliedFilter, setAppliedFilter] = useState<PREBUILT_FILTERS | null>(null);
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

    const boxRef = useRef<HTMLDivElement>(null);

    const onDateChange = ({
      startDate,
      endDate,
      activeFilter,
      isSingleDate,
    }: DatePickerOnChangeProps) => {
      if (
        (!startDate || !endDate) &&
        ![PREBUILT_FILTERS.ALL_TIME, PREBUILT_FILTERS.CUSTOM].includes(
          activeFilter as PREBUILT_FILTERS,
        )
      ) {
        setButtonLabel(FILTER_LABELS[defaultFilter]);
        setIsFiltersApplied(false);
        setIsActive(false);
        setAppliedFilter(null);
        return;
      }

      const isDefaultFilter = activeFilter === defaultFilter;

      setIsFiltersApplied(!isDefaultFilter);

      setSelectedStartDate(startDate as Date);
      setSelectedEndDate(endDate as Date);
      setAppliedFilter(activeFilter as PREBUILT_FILTERS);
      if (activeFilter && activeFilter !== PREBUILT_FILTERS.CUSTOM) {
        const label = DATEPICKER_BUTTON_LABELS[activeFilter];
        setButtonLabel(label);

        onChange({
          end: endDate,
          filter: activeFilter,
          isDefaultFilter,
          isSingleDate,
          label,
          start: startDate,
        });
      }

      if (activeFilter && activeFilter === PREBUILT_FILTERS.CUSTOM) {
        if (isSingleDate) {
          const startText = format(startDate as Date, 'MMM d, yyyy');
          // sets the end date to the same day to disable React Date Picker's default range functionality
          setSelectedEndDate(startDate as Date);
          setButtonLabel(startText);
          onChange({
            end: endDate,
            filter: activeFilter,
            isDefaultFilter,
            isSingleDate,
            label: startText,
            start: startDate,
          });
        } else {
          const startText = format(startDate as Date, 'MMM d, yyyy');
          const endText = format(endDate as Date, 'MMM d, yyyy');
          const label = `${startText} - ${endText}`;
          setButtonLabel(label);

          onChange({
            end: endDate,
            filter: activeFilter,
            isDefaultFilter,
            isSingleDate,
            label,
            start: startDate,
          });
        }
      }

      setIsActive(false);
    };

    const handleClickOutside = event => {
      if (
        boxRef.current &&
        !boxRef.current.contains(event.target) &&
        !event.target.closest(`.${DATEPICKER_CLICK_OUTSIDE_DROPDOWN_CLASS_NAME}`)
      ) {
        setIsActive(false);
      }
    };

    const initializeFilters = ({
      shouldResetToDefault,
    }: { shouldResetToDefault?: boolean } = {}) => {
      const shouldUseDefaultFilters = !(Object.keys(initialState).length > 0);

      if (shouldUseDefaultFilters || shouldResetToDefault) {
        const { newStartDate, newEndDate } = applyFilterFor(defaultFilter!, new Date());
        setIsFiltersApplied(false);
        setAppliedFilter(defaultFilter);
        setButtonLabel(FILTER_LABELS[defaultFilter]);
        setSelectedStartDate(newStartDate);
        setSelectedEndDate(newEndDate);
        onChange({
          end: newEndDate,
          filter: defaultFilter,
          isDefaultFilter: true,
          label: buttonLabel,
          start: newStartDate,
        });

        return;
      }

      const { start, end, label: initialLabel, filter, isDefaultFilter = false } = initialState;

      setIsFiltersApplied(true);
      setAppliedFilter(filter);
      setButtonLabel(initialLabel);
      setSelectedStartDate(start);
      setSelectedEndDate(end);

      onChange({
        end,
        filter,
        isDefaultFilter,
        label: initialLabel,
        start,
      });
    };

    useEffect(() => {
      initializeFilters();
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClearFilters = () => {
      initializeFilters({ shouldResetToDefault: true });
      setIsActive(false);
    };

    useImperativeHandle(ref, () => ({
      clearFilters: () => initializeFilters({ shouldResetToDefault: true }),
    }));

    return (
      <Box className={styles.datePickerButtonContainer}>
        <Button
          className={styles.datePickerButton}
          LeadingIcon={
            <Svg
              color={!initialState.isDefaultFilter && isFiltersApplied ? 'violetBase' : 'blackWarm'}
              img={!initialState.isDefaultFilter && isFiltersApplied ? calendarFilled : calendar}
              size={1.9}
            />
          }
          onClick={() => setIsActive(!isActive)}
          TrailingIcon={<Svg img={chevronDown} size={1} />}
          variant='tertiary'
        >
          {buttonLabel}
        </Button>
        {isActive && (
          <Box
            ref={boxRef}
            className={styles.datePickerButtonCalendarContainer}
            display='flex'
            marginTop={3}
          >
            <DatePicker
              initialFilter={appliedFilter}
              onChange={params => onDateChange(params)}
              onClearFilters={() => onClearFilters()}
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
            />
          </Box>
        )}
      </Box>
    );
  },
);
