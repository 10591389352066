import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import SidebarContext from 'context/SidebarContext';
import localStorageService from 'services/localStorageService';

import { SIDEBAR_COLLAPSED_STATE_LOCAL_STORAGE_KEY } from './SidebarContext.constants';
import { SidebarContextType } from './SidebarContext.types';

export const SidebarContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorageService.get(SIDEBAR_COLLAPSED_STATE_LOCAL_STORAGE_KEY) || false,
  );

  const onToggleCollapse = useCallback(() => {
    localStorageService.set(SIDEBAR_COLLAPSED_STATE_LOCAL_STORAGE_KEY, !isCollapsed);
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const contextValue = useMemo(
    (): SidebarContextType => ({
      isCollapsed,
      toggleCollapsedState: onToggleCollapse,
    }),
    [isCollapsed, onToggleCollapse],
  );

  return <SidebarContext.Provider value={contextValue}>{children}</SidebarContext.Provider>;
};
