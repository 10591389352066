import React, { FC, useMemo } from 'react';

import { ELLIPSIS, PAGE_SIZE_OPTIONS } from 'components/core/PaginationBar/PaginationBar.constants';
import { chevronLeft, chevronRight } from 'components/core/Svg/images/icons';
import { generatePageNumbers } from 'components/core/PaginationBar/PaginationBar.utils';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import SelectField from 'components/core/Form/SelectField/SelectField';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';

import { PageItem, PaginationBarProps } from './PaginationBar.types';
import styles from './PaginationBar.module.scss';

const PaginationBar: FC<PaginationBarProps> = ({
  isDisabled,
  pageSize,
  pageNumber,
  lastPageNumber,
  onNextPage,
  onPrevPage,
  onSelectPage,
  onLimitSelect,
  resultsCount,
  ...props
}) => {
  const hasPagination = useMemo(() => onNextPage || onPrevPage, [onNextPage, onPrevPage]);

  const intPageNumber = useMemo(
    () => (Number.isInteger(pageNumber) ? pageNumber : parseInt((pageNumber as string) || '1', 10)),
    [pageNumber],
  ) as number;
  const intLastPageNumber = useMemo(
    () =>
      Number.isInteger(lastPageNumber)
        ? lastPageNumber
        : parseInt((lastPageNumber as string) || '0', 10),
    [lastPageNumber],
  ) as number;

  const pageItems: PageItem[] = useMemo(
    () => (onSelectPage ? generatePageNumbers(intPageNumber, intLastPageNumber, 2, 9) : []),
    [intPageNumber, intLastPageNumber, onSelectPage],
  );

  return (
    <Box display='flex' {...props}>
      {resultsCount && (
        <Text className={styles.resultsCount} display='flex' variant='subCopySmall'>
          Displaying {resultsCount} results
        </Text>
      )}
      {hasPagination && (
        <div className={styles.pagination}>
          {intLastPageNumber > 1 && (
            <Button
              className={styles.paginationButton}
              isDisabled={!onPrevPage || isDisabled}
              label='Back'
              LeadingIcon={<Svg img={chevronLeft} size={0.8} />}
              onClick={onPrevPage}
              size='small'
              variant='pagination'
            />
          )}
          {pageItems.map((pageItem, index) => {
            if (pageItem === ELLIPSIS) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${index}-ellipsis`} className={styles.paginationButton}>
                  {pageItem}
                </div>
              );
            }
            return (
              <Button
                // eslint-disable-next-line react/no-array-index-key
                key={`${index}-page`}
                className={styles.paginationButton}
                isActive={intPageNumber === pageItem}
                isDisabled={isDisabled}
                label={pageItem}
                onClick={() => {
                  if (onSelectPage) {
                    onSelectPage(pageItem as number);
                  }
                }}
                size='small'
                variant='pagination'
              />
            );
          })}
          {intLastPageNumber > 1 && (
            <Button
              className={styles.paginationButton}
              isDisabled={!onNextPage || isDisabled}
              label='Next'
              onClick={onNextPage}
              size='small'
              TrailingIcon={<Svg img={chevronRight} size={0.8} />}
              variant='pagination'
            />
          )}
        </div>
      )}
      {pageSize && (
        <div className={styles.resultsPerPage}>
          <div>Results per page</div>
          <SelectField
            className={styles.selectField}
            isDisabled={!onLimitSelect || isDisabled}
            isSearchable={false}
            onSelect={onLimitSelect}
            options={PAGE_SIZE_OPTIONS}
            size='small'
            value={pageSize}
            variant='v2'
          />
        </div>
      )}
    </Box>
  );
};

export default PaginationBar;
