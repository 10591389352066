import React, { FC } from 'react';

import { info } from 'components/core/Svg/images/icons';
import Box from 'components/core/Box/Box';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';
import Tooltip from 'components/core/Tooltip/Tooltip';

import { TextWithTooltipProps } from './TextWithTooltip.types';

const TextWithTooltip: FC<TextWithTooltipProps> = ({ text, tooltipText, ...props }) => {
  return (
    <Tooltip display='flex' label={tooltipText} {...props}>
      <Text variant='subCopySmall'>{text}</Text>
      <Box display='flex' marginLeft={2}>
        <Svg img={info} size={1.6} />
      </Box>
    </Tooltip>
  );
};

export default TextWithTooltip;
