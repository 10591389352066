export default function sortBasedOnArray<T>(arr: T[], priorityList: T[]): T[] {
  return arr.sort((a, b) => {
    const indexA = priorityList.indexOf(a);
    const indexB = priorityList.indexOf(b);

    // If both a and b are in the priority list, order them by their index in the list
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one of them is in the priority list, put the one in the priority list first
    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }

    // If neither is in the priority list, maintain their relative order
    return 0;
  });
}
