import { useResolvedPath } from 'react-router-dom';
import React, { FC, useEffect, useRef } from 'react';

import { ApiTransactionSummary, PaginationMode } from 'store/api/api.types';
import { SearchField } from 'components/dedicated/SearchField/SearchField';
import {
  SearchFieldExportedFunctions,
  SuggestionItem,
} from 'components/dedicated/SearchField/SearchField.types';
import { arrowRight } from 'components/core/Svg/images/icons';
import { getParsedTransactionAttributes } from 'helpers/transaction.helpers';
import { useLazyGetTransactionSummariesQuery } from 'store/api/platformApi';
import Box from 'components/core/Box/Box';
import Icon from 'components/core/Icon/Icon';
import Svg from 'components/core/Svg/Svg';

export const TransactionSearchField: FC<{
  onSearchChange: (value: SuggestionItem) => void;
  organizationId: string;
  transactionStatusFilterValue?: string;
}> = ({ organizationId, transactionStatusFilterValue, onSearchChange }) => {
  const [
    triggerSearch,
    { isLoading: isSuggestionSearchLoading, isFetching: isSuggestionSearchFetching },
  ] = useLazyGetTransactionSummariesQuery();
  const searchComponentRef = useRef<SearchFieldExportedFunctions>(null);
  const resolvedPath = useResolvedPath('');

  const onRouteChange = () => {
    // clears the search after each tab change
    searchComponentRef.current?.clearSearch();
  };

  useEffect(() => onRouteChange(), [resolvedPath]);

  const searchSuggestions = async (
    searchTerm: string,
  ): Promise<SuggestionItem<ApiTransactionSummary>[]> => {
    const suggestionItems = await triggerSearch({
      organizationId,
      params: {
        filter: {
          id: searchTerm,
          ...(transactionStatusFilterValue ? { status: transactionStatusFilterValue } : {}),
        },
        page: {
          mode: PaginationMode.Offset,
          number: '1',
          size: 5,
        },
      },
    });

    const mappedSuggestions = suggestionItems.data?.data.map(transactionItem => {
      const { sourceAsset, targetAsset } = getParsedTransactionAttributes(
        transactionItem.attributes,
      );

      return {
        attributes: transactionItem.attributes,
        icon: <Icon iconName='receipt' size={[1.6, 1.9]} />,
        id: transactionItem.id,
        subtitle: (
          <Box display='flex' gap={2}>
            <span>{sourceAsset}</span>
            <Svg img={arrowRight} size={1.0} />
            <span>{targetAsset}</span>
          </Box>
        ),
        title: transactionItem.id.replace('id:trxn:', ''),
      };
    });

    return mappedSuggestions as SuggestionItem[];
  };

  return (
    <SearchField
      ref={searchComponentRef}
      isLoading={isSuggestionSearchLoading || isSuggestionSearchFetching}
      onChange={value => onSearchChange(value as SuggestionItem)}
      placeholder='Search by Transaction ID'
      searchSuggestions={searchSuggestions}
    />
  );
};
