import React, { FC } from 'react';

import Box from 'components/core/Box/Box';
import ContentSection from 'components/core/ContentSection/ContentSection';
import Text from 'components/core/Text/Text';

import PageHeaderProps from './PageHeader.types';

const PageHeader: FC<PageHeaderProps> = ({
  children,
  title,
  variant = 'sectionHeaderExtraLarge',
  ...props
}) => {
  return (
    <ContentSection
      alignItems='center'
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      minHeight='9.6rem'
      Tag='header'
      {...props}
    >
      <Text variant={variant}>{title}</Text>
      {children && <Box marginLeft='auto'>{children}</Box>}
    </ContentSection>
  );
};

export default PageHeader;
