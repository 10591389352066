import { Id, toast } from 'react-toastify';
import React from 'react';

import {
  FilteredOptions,
  LoaderToastProps,
  ReactToastProxy,
} from 'services/notificationService.types';
import { alert, circledCheckmark, help, info, warning } from 'components/core/Svg/images/icons';
import LoaderBar from 'components/core/LoaderBar/LoaderBar';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';

const showDefault: ReactToastProxy = (content, options) =>
  toast(content, {
    closeOnClick: true,
    icon: <Svg img={info} />,
    type: 'default',
    ...options,
  });

const showError: ReactToastProxy = (content, options) =>
  toast(content, {
    closeOnClick: true,
    icon: <Svg img={alert} />,
    type: 'error',
    ...options,
  });

const showInfo: ReactToastProxy = (content, options) =>
  toast(content, {
    closeOnClick: true,
    icon: <Svg img={help} />,
    type: 'info',
    ...options,
  });

const showSuccess: ReactToastProxy = (content, options) =>
  toast(content, {
    closeOnClick: true,
    icon: <Svg img={circledCheckmark} />,
    type: 'success',
    ...options,
  });

const showWarning: ReactToastProxy = (content, options) =>
  toast(content, {
    closeOnClick: true,
    icon: <Svg img={warning} />,
    type: 'warning',
    ...options,
  });

const showLoader = ({ title, message }: LoaderToastProps, options?: FilteredOptions): Id =>
  toast(
    <>
      <Text marginBottom={1} variant='sectionHeaderStandard'>
        {title}
      </Text>
      {message && (
        <Text marginBottom={1} variant='subCopySmall'>
          {message}
        </Text>
      )}
      <LoaderBar />
    </>,
    {
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
      type: 'default',
      ...options,
    },
  );

const { promise } = toast;

const dismiss = (id?: Id): void => toast.dismiss(id);

export { dismiss, promise, showDefault, showError, showInfo, showLoader, showSuccess, showWarning };
