import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import {
  CheckboxDropdownExportedFunctions,
  CheckboxDropdownProps,
} from 'components/core/CheckboxDropdown/CheckboxDropdown.types';
import { DropdownExportedFunctions } from 'components/core/Dropdown/Dropdown.types';
import { chevronDown } from 'components/core/Svg/images/icons';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import CheckboxField from 'components/core/Form/CheckboxField/CheckboxField';
import Divider from 'components/core/Divider/Divider';
import Dropdown from 'components/core/Dropdown/Dropdown';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';

import styles from './CheckboxDropdown.module.scss';

export const CheckboxDropdown = forwardRef<
  CheckboxDropdownExportedFunctions,
  CheckboxDropdownProps
>(({ onChange, label, sections, value: initialValue = [] }, ref) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(initialValue);
  const dropdownRef = useRef<DropdownExportedFunctions>(null);

  const onCheckboxChange = (field: string, isChecked: boolean) => {
    if (!isChecked) {
      setCheckedValues([...checkedValues.filter(value => value !== field)]);
      return;
    }

    setCheckedValues([...checkedValues, field]);
  };

  const applySelection = () => {
    onChange(checkedValues);
    dropdownRef.current?.toggleMenu();
  };

  const clearSelection = () => {
    setCheckedValues([]);
    onChange([]);
  };

  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      setCheckedValues([]);
    },
  }));
  return (
    <Dropdown
      ref={dropdownRef}
      dropdownContentClassname={styles.filterDropdownContentContainer}
      keepOpen
      label={
        <Box display='flex' gap={3} justifyContent='space-between'>
          {initialValue.length > 0 && <span className={styles.counter}>{initialValue.length}</span>}
          <Text className={styles.checkboxDropdownButtonLabel}>{label}</Text>
        </Box>
      }
      menuPosition='left'
      TrailingIcon={<Svg img={chevronDown} size={1.0} />}
      variant='tertiary'
    >
      {sections.map((section, index) => (
        <Box key={section.name}>
          <Text color='charcoalBase' marginTop={index === 0 ? 0 : 6} variant='subCopySmall'>
            {section.name}
          </Text>

          <Box marginLeft={1}>
            {section.options.map(({ value, label: optionLabel }) => (
              <CheckboxField
                key={`${section.name}-${optionLabel}`}
                className={styles.checkboxField}
                isChecked={checkedValues.includes(value)}
                label={optionLabel}
                onChange={() => onCheckboxChange(value, !checkedValues.includes(value))}
              />
            ))}
          </Box>
        </Box>
      ))}
      <Divider />
      <Box display='flex' gap={2} justifyContent='flex-end' paddingTop={2}>
        {(initialValue.length > 0 || checkedValues.length > 0) && (
          <Button onClick={() => clearSelection()} size='small' variant='textCta'>
            Clear
          </Button>
        )}
        <Button onClick={() => applySelection()} size='small'>
          Apply
        </Button>
      </Box>
    </Dropdown>
  );
});
