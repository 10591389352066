/* eslint-disable no-restricted-syntax */

/**
 * WHEN ADDING NEW ICONS, please ensure that the icon SVG code is taken
 * from the 24px version of the icon. This is to ensure that the icon
 * scales properly when using Icon component with defined iconSize
 * parameter.
 */

import { SvgImgProp } from 'components/core/Svg/Svg.types';

import * as assetChainIcons from './assetChains';
import * as assetTypeIcons from './assetTypes';
import * as icons from './icons';

export { assetChainIcons, assetTypeIcons, icons };

export type IconName = keyof typeof icons;
export type AssetTypeIconName = keyof typeof assetTypeIcons;
export type AssetChainIconName = keyof typeof assetChainIcons;
export type ImageName = IconName | AssetTypeIconName | AssetChainIconName;

const images: Record<ImageName, SvgImgProp> = { ...assetChainIcons, ...assetTypeIcons, ...icons };

export default images;
