const createWrapperAndAppendToElement = (wrapperId: string, appendTo?: string): HTMLDivElement => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  const appendToElement = appendTo ? document.getElementById(appendTo) : null;
  if (appendToElement) {
    appendToElement.appendChild(wrapperElement);
  } else {
    document.body.appendChild(wrapperElement);
  }
  return wrapperElement;
};

export default createWrapperAndAppendToElement;
