import React, { ReactElement } from 'react';

import Icon from 'components/core/Icon/Icon';
import Text from 'components/core/Text/Text';

const RejectedTransaction = (props: { showTitle?: boolean }): ReactElement => {
  return (
    <Text align='center'>
      {props.showTitle && (
        <>
          <Icon color='redBase' iconName='clear' size={4} />
          <Text marginTop={3} variant='sectionHeaderLarge'>
            Unable to Complete Transaction
          </Text>
        </>
      )}
      <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
        The transaction could not be completed due to compliance reasons. Please{' '}
        <a href='https://www.conduit.financial/contact' rel='noreferrer' target='_blank'>
          contact
        </a>{' '}
        our support team for further assistance.
      </Text>
    </Text>
  );
};

export default RejectedTransaction;
