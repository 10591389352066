import { ApiTransactionSummary, TransactionStatus, TransactionType } from 'store/api/api.types';
import { TRANSACTION_COMPLETED_STATUSES } from 'constants/transactions';

export const shouldShowCancelTransationButton = (
  transactionSummary: ApiTransactionSummary,
  section: 'transactionDetails' | 'transactionActivity',
): boolean => {
  const { attributes } = transactionSummary;
  const { transactionType, status } = attributes;

  if (section === 'transactionActivity') {
    return (
      [TransactionType.Onramp].includes(transactionType) &&
      [TransactionStatus.AwaitingFunds, TransactionStatus.AwaitingComplianceReview].includes(status)
    );
  }

  return (
    [TransactionType.Onramp].includes(transactionType) &&
    ![...TRANSACTION_COMPLETED_STATUSES, TransactionStatus.Created].includes(status)
  );
};
