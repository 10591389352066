import { Link } from 'react-router-dom';
import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import { ROOT_ROUTES } from 'router/constants';
import { conduitLogo, conduitSign } from 'components/core/Svg/images/illustrations';
import { selectAuth } from 'store/auth/auth.slice';
import { signOutThick } from 'components/core/Svg/images/icons';
import { useAppSelector } from 'store/store';
import { useSidebar } from 'context/SidebarContext';
import CurrentUserMenuItem from 'components/dedicated/CurrentUserMenuItem/CurrentUserMenuItem';
import Divider from 'components/core/Divider/Divider';
import Dropdown from 'components/core/Dropdown/Dropdown';
import MenuItem from 'components/core/MenuItem/MenuItem';
import Svg from 'components/core/Svg/Svg';
import UserAvatar from 'components/core/UserAvatar/UserAvatar';
import auth0Client from 'services/auth0Client';
import generateUid from 'utils/generateUid';

import TopbarProps from './Topbar.types';
import styles from './Topbar.module.scss';

const Topbar: FC<TopbarProps> = ({ showLogo = false, showUserMenu = true, isFixed = false }) => {
  const { user } = useAppSelector(selectAuth);
  const { isCollapsed: isSidebarCollapsed } = useSidebar();
  const topbarId = useMemo(() => generateUid(), []);
  return (
    <div
      className={cx(
        styles.root,
        isFixed && styles.isFixed,
        isSidebarCollapsed && styles.isSidebarCollapsed,
      )}
      id={topbarId}
    >
      <div className={styles.topBar}>
        {showLogo && (
          <Link className={styles.headerLogo} to={ROOT_ROUTES.APP.absolute}>
            <Svg className={styles.logoFull} img={conduitLogo} size={[11, 2.5]} />
            <Svg className={styles.logoSign} img={conduitSign} size={[3, 3]} />
          </Link>
        )}
        <nav className={styles.navItems}>
          {showUserMenu && (
            <Dropdown
              appendTo={topbarId}
              className={styles.userProfileDropdown}
              isFixed
              label={user?.email}
              LeadingIcon={<UserAvatar email={user?.email} size='small' />}
              menuWidth='auto'
              size='largeIcon'
              variant='textCta'
            >
              <CurrentUserMenuItem padding={4} />
              <Divider noMargin />
              <MenuItem
                isCta
                label='Sign Out'
                onClick={() => {
                  auth0Client.logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  });
                }}
                svgImg={signOutThick}
              />
            </Dropdown>
          )}
        </nav>
      </div>
    </div>
  );
};

export default Topbar;
