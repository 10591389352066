import React, { FC } from 'react';
import cx from 'classnames';

import { DividerProps } from 'components/core/Divider/Divider.types';

import styles from './Divider.module.scss';

const Divider: FC<DividerProps> = ({ noMargin }) => (
  <hr className={cx(styles.root, noMargin && styles.noMargin)} />
);

export default Divider;
