import { Outlet } from 'react-router-dom';
import React, { ReactElement } from 'react';

import { OrganizationContextProvider } from 'context/OrganizationContextProvider';
import { useOrganization } from 'context/OrganizationContext';
import Sidebar from 'components/dedicated/Sidebar/Sidebar';
import Spinner from 'components/core/Spinner/Spinner';
import Topbar from 'components/dedicated/Topbar/Topbar';

import styles from './OrganizationLayout.module.scss';

const OrganizationLayout = (): ReactElement => {
  const { isFetchingCurrentOrganization } = useOrganization();
  return (
    <div className={styles.root}>
      <Sidebar />
      <div className={styles.content}>
        <Topbar isFixed />
        {isFetchingCurrentOrganization ? <Spinner flexGrow={1} size='large' /> : <Outlet />}
      </div>
    </div>
  );
};

export default (): ReactElement => (
  <OrganizationContextProvider>
    <OrganizationLayout />
  </OrganizationContextProvider>
);
