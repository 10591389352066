import { CSSTransition } from 'react-transition-group';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { DEFAULT_TRANSITION_TIME } from 'constants/constants';
import { arrowUp } from 'components/core/Svg/images/icons';
import { useSidebar } from 'context/SidebarContext';
import ReactPortal from 'components/core/ReactPortal/ReactPortal';
import Svg from 'components/core/Svg/Svg';

import { BackToTopButtonProps } from './BackToTopButton.types';
import styles from './BackToTopButton.module.scss';

const BackToTopButton: FC<BackToTopButtonProps> = ({
  className,
  dataTestId,
  offsetFromTop = 20,
  isVisible,
  marginFromViewportBottom = 40,
  marginFromAbsoluteBottom = 52,
}) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isVisibleState, setIsVisibleState] = useState(isVisible !== undefined ? isVisible : false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [absoluteTop, setAbsoluteTop] = useState<number | null>(null);
  const { isCollapsed: isSidebarCollapsed } = useSidebar();

  const toggleVisibility = useCallback(() => {
    setIsVisibleState(window.scrollY > offsetFromTop);
  }, [offsetFromTop]);

  const checkPosition = useCallback(() => {
    if (!nodeRef.current) {
      return;
    }

    const { scrollY } = window;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const distanceFromBottom = documentHeight - (scrollY + windowHeight);

    if (distanceFromBottom <= marginFromAbsoluteBottom) {
      setIsAtBottom(true);

      // Get the component's height
      const componentHeight = nodeRef.current.offsetHeight;

      // Calculate the absolute top position
      const topPosition =
        documentHeight - marginFromAbsoluteBottom - componentHeight - marginFromViewportBottom;

      setAbsoluteTop(topPosition);
    } else {
      setIsAtBottom(false);
      setAbsoluteTop(null);
    }
  }, [marginFromAbsoluteBottom, marginFromViewportBottom]);

  const handleScrollToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  useEffect(() => {
    if (isVisible !== undefined) {
      return;
    }
    window.addEventListener('scroll', toggleVisibility);
    window.addEventListener('scroll', checkPosition);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
      window.removeEventListener('scroll', checkPosition);
    };
  }, [isVisible, toggleVisibility, checkPosition]);

  const style = isAtBottom
    ? { position: 'absolute' as const, top: `${absoluteTop}px` }
    : { bottom: `${marginFromViewportBottom}px`, position: 'fixed' as const };

  return (
    <ReactPortal>
      <CSSTransition
        classNames={{
          enter: styles['root--enter'],
          enterActive: styles['root--enterActive'],
          exit: styles['root--exit'],
          exitActive: styles['root--exitActive'],
        }}
        in={isVisible !== undefined ? isVisible : isVisibleState}
        nodeRef={nodeRef}
        timeout={DEFAULT_TRANSITION_TIME}
        unmountOnExit
      >
        <div
          ref={nodeRef}
          className={cx(styles.root, isSidebarCollapsed && styles.isSidebarCollapsed, className)}
          data-testid={dataTestId}
          onClick={handleScrollToTop}
          style={style}
        >
          <Svg className={styles.icon} img={arrowUp} size={1.6} />
          Back To Top
        </div>
      </CSSTransition>
    </ReactPortal>
  );
};

export default BackToTopButton;
