import { Navigate, generatePath } from 'react-router-dom';
import React, { ReactElement } from 'react';

import { HELLO_EMAIL } from 'constants/constants';
import { ORGANIZATIONS_ROUTES } from 'router/constants';
import { circledCheckmarkFilled } from 'components/core/Svg/images/icons';
import { useOrganization } from 'context/OrganizationContext';
import Spinner from 'components/core/Spinner/Spinner';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';

import styles from './PendingVerificationView.module.scss';

const PendingVerificationView = (): ReactElement => {
  const { currentOrganizationId, isFetchingCurrentOrganization, currentOrganizationData } =
    useOrganization();

  if (currentOrganizationData?.platform.canUsePlatform) {
    const url = generatePath(ORGANIZATIONS_ROUTES.ORGANIZATION.absolute, {
      organizationId: currentOrganizationId,
    });
    return <Navigate replace to={url} />;
  }

  if (!currentOrganizationData || isFetchingCurrentOrganization) {
    return (
      <div className={styles.root}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Svg className={styles.icon} color='currentColor' img={circledCheckmarkFilled} size={3.6} />
      <Text className={styles.title} marginBottom={4} marginTop={4} variant='sectionHeaderLarge'>
        Welcome to Conduit
      </Text>
      <Text variant='bodyCopySmall'>
        Thank you for registering your organization,{' '}
        <Text Tag='span' variant='legacyBodySmallEmphasized'>
          {currentOrganizationData?.name}
        </Text>
        . Our team has been notified and will contact you shortly with the next steps to access our
        payments platform.
      </Text>
      <Text marginTop={4} variant='bodyCopySmall'>
        If you haven&apos;t heard from us within 24-48 hours, please contact us at{' '}
        <a href={`mailto:${HELLO_EMAIL}`}>{HELLO_EMAIL}</a>
      </Text>
    </div>
  );
};

export default PendingVerificationView;
