import React, { FC } from 'react';
import cx from 'classnames';
import round from 'lodash/round';

import { SvgProps } from 'components/core/Svg/Svg.types';
import Box from 'components/core/Box/Box';
import Svg from 'components/core/Svg/Svg';
import images from 'components/core/Svg/images';

import { IconProps } from './Icon.types';
import styles from './Icon.module.scss';

/**
 * Icon component generally works using two size settings:
 * - `iconSize` is uses selection of standardized sizes for the icon,
 * including its expected whitespace. It will always render as a square,
 * unless also defining a noWhitespace prop value.
 * - `size` is the user-defined size of the icon, without any whitespace.
 * When using iconSize, the size prop will be ignored.
 */
export const Icon: FC<IconProps> = ({
  className,
  iconName,
  alt,
  color,
  iconSize,
  noWhitespace,
  onClick,
  size,
  sizeUnit,
  title,
  ...props
}) => {
  const imgConfig = images[iconName];
  let determinedSize = size;

  if (iconSize) {
    /**
     * We assume the base icon size is taken from a 24x24 canvas.
     * Therefore we scale the icon up or down only if the iconSize
     * is different from 24.
     */
    const DEFAULT_CANVAS_SIZE = 24;
    const [iconBaseWidth, iconBaseHeight] = imgConfig.viewBox.slice(2);

    if (iconSize === DEFAULT_CANVAS_SIZE) {
      determinedSize = [iconBaseWidth / 10, iconBaseHeight / 10];
    } else {
      // We round to the nearest even number since container dimensions are always even.
      determinedSize = [
        (2 * round((iconBaseWidth * iconSize) / DEFAULT_CANVAS_SIZE / 2)) / 10,
        (2 * round((iconBaseHeight * iconSize) / DEFAULT_CANVAS_SIZE / 2)) / 10,
      ];
    }

    if (
      (determinedSize[0] as number) > iconSize / 10 ||
      (determinedSize[1] as number) > iconSize / 10
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        `Icon "${iconName}" is too large for iconSize ${iconSize} - make sure the original size is taken from a 24x24 canvas. Falling back to auto sizing SVG.`,
      );
      determinedSize = ['auto', 'auto'];
    }
  }

  const svgProps: SvgProps = {
    alt,
    color,
    img: imgConfig,
    onClick,
    size: determinedSize,
    sizeUnit,
    title,
  };

  if (!svgProps.img) {
    // eslint-disable-next-line no-console
    console.error(`Attempted to load an icon that does not exist ${iconName}`);
    return null;
  }
  return (
    <Box
      className={cx(
        styles.root,
        styles[`iconSize--${iconSize}`],
        noWhitespace && styles[`noWhitespace--${noWhitespace === true ? 'both' : noWhitespace}`],
        className,
      )}
      {...props}
    >
      <Svg {...svgProps} />
    </Box>
  );
};
export default Icon;
