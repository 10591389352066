import { Link, useParams } from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';
import cx from 'classnames';

import { Organization } from 'store/api/api.types';
import { ROOT_ROUTES } from 'router/constants';
import { closePanel, openPanel } from 'components/core/Svg/images/icons';
import { conduitLogo, conduitSign } from 'components/core/Svg/images/illustrations';
import { getOrganizationMenuItems } from 'router/menuItems';
import { useCurrentUser } from 'context/CurrentUserContext';
import { useGetOrganizationQuery } from 'store/api/rootApi';
import { useSidebar } from 'context/SidebarContext';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import MenuItem from 'components/core/MenuItem/MenuItem';
import OrganizationSwitcherDropdown from 'components/dedicated/organization/OrganizationSwitcherDropdown/OrganizationSwitcherDropdown';
import Svg from 'components/core/Svg/Svg';
import Tooltip from 'components/core/Tooltip/Tooltip';
import generateUid from 'utils/generateUid';

import styles from './Sidebar.module.scss';

const Sidebar = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: Organization['id'] };
  const { data: organization } = useGetOrganizationQuery({ organizationId });
  const { isUserAllowedTo } = useCurrentUser();

  const { isCollapsed, toggleCollapsedState } = useSidebar();

  const menuItems = useMemo(
    () => (organization ? getOrganizationMenuItems(organization, isUserAllowedTo) : []),
    [organization, isUserAllowedTo],
  );

  const sidebarId = useMemo(() => generateUid(), []);

  return (
    <div className={cx(styles.root, isCollapsed && styles.isCollapsed)} id={sidebarId}>
      <div className={cx(styles.sidebarWrapper, isCollapsed && styles.isCollapsed)}>
        <Box marginBottom={6}>
          <Link className={styles.logoWrapper} to={ROOT_ROUTES.APP.absolute}>
            <Svg className={styles.logoFull} img={conduitLogo} size={[10.6, 2.4]} />
            <Svg className={styles.logoSign} img={conduitSign} size={[2.4, 2.4]} />
          </Link>
        </Box>
        <nav className={styles.navigationItems}>
          <OrganizationSwitcherDropdown
            appendTo={sidebarId}
            className={styles.organizationDropdown}
            isFixed
          />
          {menuItems.map((item, index) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={styles.menuItem}
              isDisabled={item.isDisabled}
              isNavlink
              label={item.label}
              svgImg={item.icon}
              svgImgForActivePath={item.iconActive}
              to={item.path.absolute}
              variant='navPrimary'
            />
          ))}
        </nav>
        <Box textAlign='right'>
          <span className={styles.sidebarToggleButtonWrapper}>
            <Tooltip label={isCollapsed ? 'Pin menu' : 'Collapse menu'} position='left'>
              <Button
                className={styles.sidebarToggleButton}
                LeadingIcon={<Svg img={isCollapsed ? openPanel : closePanel} size={1.9} />}
                onClick={toggleCollapsedState}
                variant='icon'
              />
            </Tooltip>
          </span>
        </Box>
      </div>
    </div>
  );
};

export default Sidebar;
