import React, { FC } from 'react';

import { APP_ROUTES, ORGANIZATIONS_ROUTES } from 'router/constants';
import { add, business, chevronDown } from 'components/core/Svg/images/icons';
import { useOrganization } from 'context/OrganizationContext';
import Divider from 'components/core/Divider/Divider';
import Dropdown from 'components/core/Dropdown/Dropdown';
import DropdownProps from 'components/core/Dropdown/Dropdown.types';
import MenuItem from 'components/core/MenuItem/MenuItem';
import Svg from 'components/core/Svg/Svg';

const OrganizationSwitcherDropdown: FC<DropdownProps> = props => {
  const { currentOrganizationId, userOrganizations, isFetchingUserOrganizations } =
    useOrganization();

  let dropdownLabel = 'Select organization';
  if (userOrganizations?.length > 0) {
    userOrganizations.forEach(({ id, name }) => {
      if (currentOrganizationId === id) {
        dropdownLabel = name;
      }
    });
  }

  return (
    <Dropdown
      isCollapsible
      isLoading={isFetchingUserOrganizations}
      label={dropdownLabel}
      LeadingIcon={<Svg img={business} size={[1.6, 2]} />}
      menuPosition='left'
      menuWidth='widest'
      TrailingIcon={<Svg img={chevronDown} size={[1.2, 1.2]} />}
      variant='tertiary'
      {...props}
    >
      {userOrganizations &&
        userOrganizations.map(({ id, name }) => (
          <MenuItem
            key={id}
            isNavlink
            label={name}
            showCheckmarkForActive
            svgImg={business}
            to={`${APP_ROUTES.ORGANIZATIONS.absolute}/${id}`}
          />
        ))}
      {userOrganizations && userOrganizations.length > 0 && <Divider noMargin />}
      <MenuItem
        isCta
        label='Create Organization'
        svgImg={add}
        svgSize={1.6}
        to={ORGANIZATIONS_ROUTES.CREATE_ORGANIZATION.absolute}
      />
    </Dropdown>
  );
};

export default OrganizationSwitcherDropdown;
