import { useParams } from 'react-router-dom';
import React, { FC, useMemo } from 'react';

import { BoxProps } from 'components/core/Box/Box.types';
import { CurrentUser } from 'store/api/api.types';
import { ORGANIZATION_ROLES_ORDER } from 'components/dedicated/CurrentUserMenuItem/CurrentUserMenuItem.constants';
import { uppercaseFirstChar } from 'utils/uppercaseFirstChar';
import { useCurrentUser } from 'context/CurrentUserContext';
import { useGetOrganizationRolesQuery } from 'store/api/rootApi';
import Box from 'components/core/Box/Box';
import Text from 'components/core/Text/Text';
import UserAvatar from 'components/core/UserAvatar/UserAvatar';
import sortBasedOnArray from 'utils/sortBasedOnArray';

const CurrentUserMenuItem: FC<BoxProps> = props => {
  const { organizationId } = useParams() as { organizationId: string };
  const { user } = useCurrentUser();
  const { data: organizationRoles } = useGetOrganizationRolesQuery(
    {
      organizationId,
    },
    { skip: !organizationId },
  );
  const currentUserRole = useMemo(() => {
    if (!user || !organizationRoles) {
      return '';
    }
    const {
      authorizations: { attachedRoles },
    } = user as CurrentUser;
    const currentOrganizationUserRoleIds = attachedRoles.filter(role =>
      organizationRoles.some(orgRole => orgRole.id === role),
    );
    const currentOrganizationUserRoles = currentOrganizationUserRoleIds.map(roleId => {
      const role = organizationRoles.find(orgRole => orgRole.id === roleId);
      return role?.name;
    });
    const sortedCurrentOrganizationUserRoles = sortBasedOnArray(
      currentOrganizationUserRoles,
      ORGANIZATION_ROLES_ORDER,
    );
    return uppercaseFirstChar(sortedCurrentOrganizationUserRoles[0] || '');
  }, [user, organizationRoles]);
  return (
    <Box {...props}>
      <Box display='flex'>
        <UserAvatar email={user?.email} />
        <Box marginLeft={2.5} overflow='hidden'>
          <Text overflow='ellipsis' variant='labelStandard'>
            {user?.email}
          </Text>
          <Text variant='subCopySmall'>{currentUserRole}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CurrentUserMenuItem;
