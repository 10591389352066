import { useLocation } from 'react-router-dom';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import { ButtonVariant } from 'components/core/Button/Button.types';
import { chevronDown } from 'components/core/Svg/images/icons';
import { getScrollableParent } from 'utils/getScrollableParent';
import Button from 'components/core/Button/Button';
import ReactPortal from 'components/core/ReactPortal/ReactPortal';
import Svg from 'components/core/Svg/Svg';
import generateUid from 'utils/generateUid';
import isNumeric from 'utils/isNumeric';

import DropdownProps, { DropdownExportedFunctions } from './Dropdown.types';
import styles from './Dropdown.module.scss';

const Dropdown = forwardRef<DropdownExportedFunctions, DropdownProps>(
  (
    {
      appendTo,
      children,
      className,
      dataTestId,
      isFixed = false,
      menuPosition = 'right',
      menuWidth = 'normal',
      LeadingIcon,
      keepOpen = false,
      TrailingIcon,
      variant = 'dropdownOpaque',
      dropdownContentClassname,
      ...buttonProps
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownMenuMaxHeight, setDropdownMenuMaxHeight] = useState<string>();
    const [dropdownMenuPosition, setDropdownMenuPosition] = useState({
      left: 'auto',
      top: 'auto',
    });
    const location = useLocation();
    const dropdownButtonRef = useRef<HTMLButtonElement>(null);
    const dropdownMenuRef = useRef<HTMLDivElement>(null);
    const isOpenState = useRef(isOpen);

    const toggleMenu = isOpenCurrent => {
      isOpenState.current = isOpenCurrent;
      setIsOpen(isOpenCurrent);
    };

    useImperativeHandle(ref, () => ({
      toggleMenu: () => toggleMenu(!isOpen),
    }));

    const updateDropdownMenuPosition = useCallback(() => {
      if (isOpen && dropdownButtonRef.current && dropdownMenuRef.current) {
        const dropdownButtonRect = dropdownButtonRef.current.getBoundingClientRect();
        const dropdownMenuRect = dropdownMenuRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const rightScreenEdgeOffset = 16;

        const absoluteButtonTop = dropdownButtonRect.top + (isFixed ? 0 : window.scrollY);
        const absoluteButtonLeft = dropdownButtonRect.left + window.scrollX;
        const absoluteButtonRight = dropdownButtonRect.right + window.scrollX;

        let newLeftPosition;
        if (menuPosition === 'left') {
          newLeftPosition = absoluteButtonLeft;
        } else {
          newLeftPosition = absoluteButtonRight - dropdownMenuRect.width;
        }

        // Check if dropdown overflows the window's width.
        // If it does, pin it to the right side of the window.
        if (
          absoluteButtonRight + rightScreenEdgeOffset > windowWidth ||
          newLeftPosition + dropdownMenuRect.width + rightScreenEdgeOffset > windowWidth
        ) {
          newLeftPosition = windowWidth - dropdownMenuRect.width - rightScreenEdgeOffset;
        } else if (newLeftPosition < rightScreenEdgeOffset) {
          newLeftPosition = rightScreenEdgeOffset;
        }

        const newPosition = {
          left: `${newLeftPosition}px`,
          top: `${absoluteButtonTop + dropdownButtonRect.height}px`,
        };

        setDropdownMenuPosition(newPosition);
      }
    }, [isFixed, isOpen, menuPosition]);

    const updateDropdownMenuMaxHeight = useCallback(() => {
      if (isFixed) {
        setDropdownMenuMaxHeight(
          `${window.innerHeight - parseFloat(dropdownMenuPosition.top) - 30}px`,
        );
      }
    }, [dropdownMenuPosition.top, isFixed]);

    useEffect(() => {
      updateDropdownMenuPosition();

      window.addEventListener('resize', updateDropdownMenuMaxHeight);

      const scrollableParent = getScrollableParent(dropdownButtonRef.current);
      if (!isFixed) {
        window.addEventListener('scroll', updateDropdownMenuPosition);
        window.addEventListener('resize', updateDropdownMenuPosition);
      }
      if (scrollableParent) {
        scrollableParent.addEventListener('scroll', updateDropdownMenuPosition);
      }

      return () => {
        window.removeEventListener('resize', updateDropdownMenuMaxHeight);
        if (!isFixed) {
          window.removeEventListener('scroll', updateDropdownMenuPosition);
          window.removeEventListener('resize', updateDropdownMenuPosition);
        }
        if (scrollableParent) {
          scrollableParent.removeEventListener('scroll', updateDropdownMenuPosition);
        }
      };
    }, [isOpen, isFixed, updateDropdownMenuPosition, updateDropdownMenuMaxHeight]);

    const onClickOutside = ({ target }) => {
      const dropdownBUtton = dropdownButtonRef.current;
      if (isOpenState.current && !dropdownBUtton?.contains(target)) {
        toggleMenu(!isOpenState.current);
      }
    };

    const dropdownPortalId = useMemo(() => `dropdownPortal-${generateUid()}`, []);

    useEffect(() => {
      toggleMenu(false);
    }, [location]);

    useEffect(() => {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
      // eslint-disable-next-line
    }, []);

    let trailingIcon = TrailingIcon;
    if (['dropdownOpaque', 'dropdownTransparent'].includes(variant)) {
      trailingIcon = <Svg img={chevronDown} size={[1, 0.6]} />;
    }

    return (
      <div
        className={cx(styles.root, styles[`variant--${variant}`], className)}
        data-testid={dataTestId}
      >
        <Button
          ref={dropdownButtonRef}
          isActive={isOpen}
          LeadingIcon={LeadingIcon}
          onClick={() => toggleMenu(!isOpenState.current)}
          TrailingIcon={trailingIcon}
          variant={variant as keyof typeof ButtonVariant}
          {...buttonProps}
        />
        <ReactPortal appendTo={appendTo} wrapperId={dropdownPortalId}>
          <div
            ref={dropdownMenuRef}
            className={cx(
              styles.dropdownContent,
              dropdownContentClassname,
              styles[`menuPosition--${menuPosition}`],
              !isNumeric(menuWidth) && styles[`menuWidth--${menuWidth}`],
              isOpen && styles.isOpen,
              isFixed && styles.isFixed,
            )}
            onClick={e => {
              e.stopPropagation();
              if (!keepOpen) {
                toggleMenu(!isOpenState.current);
              }
            }}
            style={{
              ...dropdownMenuPosition,
              ...(isNumeric(menuWidth) ? { width: `${menuWidth}rem` } : {}),
              ...(dropdownMenuMaxHeight ? { maxHeight: dropdownMenuMaxHeight } : {}),
            }}
          >
            {children}
          </div>
        </ReactPortal>
      </div>
    );
  },
);

export default Dropdown;
