import { SvgImgProp } from 'components/core/Svg/Svg.types';

export const assetChainETH: SvgImgProp = {
  markup:
    '<path d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z" fill="white" stroke="#F2F5FA"/><g clip-path="url(#clip0_3204_6162)"><path d="M7.99899 2.28571L7.92406 2.54615V10.1029L7.99899 10.1794L11.4265 8.10597L7.99899 2.28571Z" fill="#343434"/><path d="M7.99899 2.28571L4.57142 8.10597L7.99899 10.1794V6.51155V2.28571Z" fill="#8C8C8C"/><path d="M7.99899 10.8435L7.95676 10.8962V13.588L7.99899 13.7142L11.4286 8.77115L7.99899 10.8435Z" fill="#3C3C3B"/><path d="M7.99899 13.7142V10.8435L4.57142 8.77115L7.99899 13.7142Z" fill="#8C8C8C"/><path d="M7.99899 10.1794L11.4265 8.10598L7.99899 6.51157V10.1794Z" fill="#141414"/><path d="M4.57142 8.10598L7.99899 10.1794V6.51157L4.57142 8.10598Z" fill="#393939"/></g><defs><clipPath id="clip0_3204_6162"><rect width="6.85714" height="11.4286" fill="white" transform="translate(4.57142 2.28571)"/></clipPath></defs>',
  viewBox: [0, 0, 16, 16],
};

export const assetChainTRX: SvgImgProp = {
  markup:
    '<path d="M15.5 8C15.5 12.1414 12.1437 15.5 8 15.5C3.85926 15.5 0.5 12.1413 0.5 8C0.5 3.85861 3.85632 0.5 8 0.5C12.1437 0.5 15.5 3.85861 15.5 8Z" fill="#FF060A" stroke="#F2F5FA"/><path d="M12.3746 6.77848C11.9377 6.39007 11.3308 5.79835 10.8392 5.37959L10.8088 5.36138C10.7603 5.32497 10.7057 5.29462 10.648 5.27338C9.45849 5.06097 3.9236 4.06869 3.81739 4.08083C3.78705 4.08386 3.7567 4.096 3.73242 4.11117L3.70511 4.13242C3.67174 4.16579 3.64443 4.20524 3.62925 4.25076L3.62318 4.26897V4.36911V4.38428C4.24525 6.04717 6.70622 11.491 7.19174 12.7716C7.22208 12.8596 7.2767 13.0234 7.37987 13.0326H7.40415C7.45877 13.0326 7.69546 12.7321 7.69546 12.7321C7.69546 12.7321 11.9225 7.82235 12.3504 7.30042C12.405 7.23669 12.4535 7.1669 12.496 7.09407C12.5081 7.03642 12.5021 6.97876 12.4808 6.92414C12.4596 6.86952 12.4201 6.81793 12.3746 6.77848ZM8.77574 7.352L10.5782 5.91973L11.6373 6.85435L8.77574 7.352ZM8.07477 7.25793L4.97049 4.81821L9.9956 5.70731L8.07477 7.25793ZM8.35394 7.89517L11.531 7.40359L7.89877 11.6003L8.35394 7.89517ZM4.5487 5.064L7.81684 7.71917L7.34346 11.6033L4.5487 5.064Z" fill="white"/>',
  viewBox: [0, 0, 16, 16],
};
