import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import cx from 'classnames';

import { DATEPICKER_CLICK_OUTSIDE_DROPDOWN_CLASS_NAME } from 'components/core/DatePicker/DatePicker.constants';
import {
  DatePickerHeaderProps,
  DatePickerHeadlessExportedFunctions,
  PREBUILT_FILTERS,
} from 'components/core/DatePicker/DatePicker.types';
import { arrowLeft, arrowRight, chevronDown } from 'components/core/Svg/images/icons';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Dropdown from 'components/core/Dropdown/Dropdown';
import MenuItem from 'components/core/MenuItem/MenuItem';
import Svg from 'components/core/Svg/Svg';

import styles from '../DatePicker.module.scss';

/**
 *
 * This component represents a "half" of the date picker header,
 * react date picker renders one of this for each calendar that it is displaying it.
 *
 * We forward the refs for the change month and change year method here because these methods
 * only come through the renderCustomHeader prop passed in the date picker component.
 * And this is how we can navigate through the calendar without changing the selected dates
 *
 * We need to able to navigate through the calendar because the default behavior of React Date Picker is to redirect automatically to the range's selected calendars,
 * and this is not what we wanted to do in our designs.
 *
 * for example: if we set the date period of the last 90 days, React Date Picker would render the oldest month, while we want to keep the latest month shown.
 *
 */
export const DatePickerHeadlessHeader = forwardRef<
  DatePickerHeadlessExportedFunctions,
  DatePickerHeaderProps
>((props, ref) => {
  const {
    changeMonth,
    today,
    changeYear,
    increaseMonth,
    customHeaderCount,
    decreaseMonth,
    monthDate,
    activeFilter,
    endDate: currentEndDate,
    startDate: currentStartDate,
  } = props;

  const [currentMonthIndex, setCurrentMonthIndex] = useState(today.getMonth());

  const currentYear = today.getFullYear();
  const startingYear = 2021;
  const yearArray = Array.from(
    { length: currentYear - startingYear + 1 },
    (_, i) => startingYear + i,
  );
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const changeMonthView = ({ appliedFilter, endDate, startDate }: any) => {
    const defaultViewFilters = [
      PREBUILT_FILTERS.LAST_90_DAYS,
      PREBUILT_FILTERS.LAST_MONTH,
      PREBUILT_FILTERS.LAST_WEEK,
      PREBUILT_FILTERS.THIS_MONTH,
      PREBUILT_FILTERS.THIS_YEAR,
      PREBUILT_FILTERS.ALL_TIME,
      PREBUILT_FILTERS.LAST_YEAR,
    ];
    const isStartDateInTwoLastMonths = startDate?.getMonth() >= today.getMonth() - 2;
    const shouldFilterUseDefaultView = defaultViewFilters.includes(appliedFilter);

    /*
      Our Default Presentation of the calendar consists of showing the present month on the right and the past month on the left.

      all of the filters with the exception of the CUSTOM prebuilt filter applies this logic, and for the custom filter,
      since we might not have a start or end date, we have a fallback logic to render accordingly

      */

    if (shouldFilterUseDefaultView || isStartDateInTwoLastMonths) {
      const previousMonthDate = new Date(today);
      previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
      changeMonth(previousMonthDate.getMonth());
      changeYear(previousMonthDate.getFullYear());
      return;
    }

    if (appliedFilter === PREBUILT_FILTERS.CUSTOM) {
      if (endDate) {
        changeMonth(endDate.getMonth() - 1);
      } else if (startDate) {
        changeMonth(startDate.getMonth());
      } else {
        changeMonth(today.getMonth() - 1);
      }
    }
  };
  useImperativeHandle(ref, () => ({
    changeMonthView,
    changeYearView: changeYear,
  }));

  useEffect(() => {
    const isFirstHalfOfCalendar = customHeaderCount === 0;
    // applies the required navigation to display the correct months in the first render of the calendar
    if (isFirstHalfOfCalendar) {
      changeMonthView({
        appliedFilter: activeFilter,
        endDate: currentEndDate,
        startDate: currentStartDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMonthInputChange = index => {
    const isRight = customHeaderCount === 1;
    const monthIndex = isRight ? index - 1 : index;

    changeMonth(monthIndex);
    setCurrentMonthIndex(index);
  };

  const handleYearInputChange = year => {
    const isRight = customHeaderCount === 1;

    const isDisplayingJanuaryMonth = currentMonthIndex === 0 && monthDate.getMonth() === 0;

    /**
     * monthDate -> represents the date being displayed on the far left of the calendar, this is manipulated but the arrows in both sides of the header
     * currentMonthIndex -> represents which month is currently selected in the dropdown
     *
     * if we display the month of January on the right side, if we change the year, the default behavior is to move the january month to the left side of the calendar, causing confusion.
     * This logic makes sure that the January month stays on the right side, and renders the month of December on the left
     */

    if (isRight && isDisplayingJanuaryMonth) {
      changeYear(year - 1);
      return;
    }

    changeYear(year);
  };

  const isIsBottomEndOfCalendar = useMemo(() => {
    return monthDate.getMonth() === 11 && monthDate.getFullYear() === 2020;
  }, [monthDate]);
  return (
    <Box display='flex' gap={3} justifyContent='space-between'>
      {customHeaderCount === 0 && (
        <Button
          className={styles.headerButton}
          isDisabled={isIsBottomEndOfCalendar}
          LeadingIcon={<Svg img={arrowLeft} size={1.8} />}
          onClick={() => decreaseMonth()}
          size='normal'
          variant='icon'
        />
      )}

      <Box display='flex' gap={1} margin='0 auto'>
        <Dropdown
          dropdownContentClassname={styles.dropdownContentContainerMonth}
          isDisabled={isIsBottomEndOfCalendar}
          label={monthDate.toLocaleString('default', { month: 'long' })}
          menuPosition='center'
          size='small'
          TrailingIcon={<Svg img={chevronDown} size={1} />}
          variant='tertiary'
        >
          {months.map((month, index) => (
            <MenuItem
              key={`${month}-${customHeaderCount === 0 ? 'start' : 'end'}`}
              className={cx(
                styles.dateDropdownItem,
                monthDate.toLocaleString('default', { month: 'long' }) === month &&
                  styles.dateDropdownItemActive,
                DATEPICKER_CLICK_OUTSIDE_DROPDOWN_CLASS_NAME,
              )}
              label={month}
              onClick={() => handleMonthInputChange(index)}
            />
          ))}
        </Dropdown>
        <Dropdown
          dropdownContentClassname={styles.dropdownContentContainerYear}
          isDisabled={monthDate.getFullYear() === 2020 && monthDate.getMonth() === 11}
          label={monthDate.getFullYear()}
          menuPosition='center'
          size='small'
          TrailingIcon={<Svg img={chevronDown} size={1} />}
          variant='tertiary'
        >
          {yearArray.map(year => (
            <MenuItem
              key={`${year}-${customHeaderCount === 0 ? 'start' : 'end'}`}
              className={cx(
                styles.dateDropdownItem,
                year === monthDate.getFullYear() && styles.dateDropdownItemActive,
                DATEPICKER_CLICK_OUTSIDE_DROPDOWN_CLASS_NAME,
              )}
              label={String(year)}
              onClick={() => handleYearInputChange(year)}
            />
          ))}
        </Dropdown>
      </Box>

      {customHeaderCount === 1 && (
        <Button
          className={styles.headerButton}
          isDisabled={
            today.getFullYear() === monthDate.getFullYear() &&
            today.getMonth() === monthDate.getMonth()
          }
          LeadingIcon={<Svg img={arrowRight} size={1.8} />}
          onClick={() => increaseMonth()}
          size='small'
          variant='icon'
        />
      )}
    </Box>
  );
});
