import React, { forwardRef, useMemo } from 'react';

import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import CurrencyItem from 'components/core/Form/CurrencyItem/CurrencyItem';
import SelectField from 'components/core/Form/SelectField/SelectField';

import { CurrencySelectFieldProps } from './CurrencySelectField.types';

const CurrencySelectField = forwardRef<HTMLInputElement, CurrencySelectFieldProps>(
  ({ assetTypes = [], value, ...props }, ref) => {
    const options: SelectFieldOption[] = useMemo(
      () =>
        assetTypes
          .map(assetType => ({
            component: <CurrencyItem assetType={assetType} />,
            label: assetType.ticker,
            sublabel: assetType.name,
            value: assetType.value || assetType.ticker,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      [assetTypes],
    );

    const selectedOption = useMemo(
      () => options.find(option => option.value === value),
      [options, value],
    );

    return <SelectField ref={ref} options={options} value={selectedOption} {...props} />;
  },
);

export default CurrencySelectField;
