import { createContext } from 'react';

import { SidebarContextType } from './SidebarContext.types';
import { getContextFromProvider } from './context.utils';

const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

export const useSidebar = getContextFromProvider<SidebarContextType>(SidebarContext);

export default SidebarContext;
