import { enUS } from 'date-fns/locale/en-US';
import { formatInTimeZone } from 'date-fns-tz';
import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import { TimeZone } from 'constants/constants';
import { checkmark, close } from 'components/core/Svg/images/icons';
import Box from 'components/core/Box/Box';
import Dot from 'components/core/Dot/Dot';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';

import { TransactionActivityStepProps } from './TransactionActivityStep.types';
import styles from './TransactionActivityStep.module.scss';

const TransactionActivityStep: FC<TransactionActivityStepProps> = ({
  date,
  children,
  className,
  indicatorType,
  isFinalStep,
  title,
  ...props
}) => {
  const indicator = useMemo(() => {
    switch (indicatorType) {
      case 'completed':
        return <Svg img={checkmark} size={[1.5, 1.1]} />;
      case 'failed':
      case 'stopped':
        return <Svg img={close} size={1} />;
      case 'empty':
        return null;
      default:
        return <Dot color={indicatorType} isAnimated />;
    }
  }, [indicatorType]);

  const progressLineVariant = useMemo(() => {
    if (isFinalStep || indicatorType === 'stopped') {
      return 'none';
    }
    if (indicatorType === 'completed') {
      return 'solid';
    }
    return 'dashed';
  }, [indicatorType, isFinalStep]);

  const formattedDate = useMemo(() => {
    if (!date) {
      return null;
    }
    try {
      return formatInTimeZone(date, TimeZone.ET, 'MMMM d, yyyy h:mm:ss a zzz', { locale: enUS });
    } catch {
      return null;
    }
  }, [date]);

  return (
    <Box className={cx(styles.root, className)} {...props}>
      <div className={styles.indicatorColumn}>
        <div className={cx(styles.indicator, styles[`indicator--${indicatorType}`])}>
          {indicator}
        </div>
        {!isFinalStep && (
          <div
            className={cx(styles.progressLine, styles[`progressLine--${progressLineVariant}`])}
          />
        )}
      </div>
      <div className={styles.contentColumn}>
        <Text className={styles[`statusName--${indicatorType}`]} variant='labelStandard'>
          {title}
        </Text>
        {children && <Box marginTop={2}>{children}</Box>}
        {formattedDate && (
          <Text marginTop={2} variant='subCopySmall'>
            {formattedDate}
          </Text>
        )}
      </div>
    </Box>
  );
};

export default TransactionActivityStep;
