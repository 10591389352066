import React, { FC, useMemo } from 'react';

import { ASSET_TYPES } from 'constants/assetTypes';
import { AssetType } from 'constants/assetTypes.types';
import { getUriChain, getUriId } from 'utils/format';
import Button from 'components/core/Button/Button';
import CopyToClipboardButton from 'components/core/CopyToClipboardButton/CopyToClipboardButton';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';
import USDDepositInstructions from 'components/dedicated/organization/send-money/ModalDeposit/USDDepositInstructions/USDDepositInstructions';
import modalService from 'services/modalService';

import styles from './ModalDeposit.module.scss';

const ModalDeposit: FC<{ assetType?: AssetType }> = ({ assetType }) => {
  const chain = useMemo(() => getUriChain(assetType), [assetType]);

  if (assetType?.id === ASSET_TYPES.FIAT_USD.id) {
    return <USDDepositInstructions assetType={assetType} />;
  }

  return (
    <>
      <ModalContent>
        <Text marginBottom={3}>
          Send your {getUriId(assetType?.id)} on {chain?.name} to the following address.
        </Text>
        <Text marginBottom={4}>
          <b>
            {getUriId(assetType?.id)} {chain?.name} Address
          </b>
          <div className={styles.row}>
            <Text overflow='breakWord' variant='legacyWalletAddress'>
              {getUriId(assetType?.depositAddress)}
            </Text>
            <CopyToClipboardButton valueToCopy={getUriId(assetType?.depositAddress)} />
          </div>
        </Text>
        <Noticebox variant='warning'>
          <Text>
            This address can only receive {getUriId(assetType?.id)} from {chain?.name} network.
            Don&apos;t send {getUriId(assetType?.id)} from other networks, other{' '}
            {chain?.fungibleToken}s or NFTs, or it may result in a loss of funds.
          </Text>
        </Noticebox>
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          dataTestId='close-button'
          label='Close'
          onClick={() => modalService.closeCurrentModal()}
          variant='tertiary'
        />
      </ModalContent>
    </>
  );
};

export default ModalDeposit;
