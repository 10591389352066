import React, { FC, useMemo } from 'react';

import { AssetType, HolidayInfo } from 'constants/assetTypes.types';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';

const HolidayMessage: FC<{ sourceAssetType?: AssetType; targetAssetType?: AssetType }> = ({
  sourceAssetType,
  targetAssetType,
}) => {
  const holidayMessages = useMemo<HolidayInfo[]>(() => {
    const today = new Date();

    let messages: HolidayInfo[] = [];

    if (sourceAssetType?.holidays) {
      const holidayMatches = sourceAssetType.holidays.filter(
        holiday => today > holiday.dateStart && today < holiday.dateEnd,
      );

      messages = [...holidayMatches];
    }

    if (targetAssetType?.holidays) {
      const holidayMatches = targetAssetType.holidays.filter(
        holiday => today > holiday.dateStart && today < holiday.dateEnd,
      );

      const isSameCurrencyTransaction = targetAssetType.id === sourceAssetType?.id;

      if (isSameCurrencyTransaction) {
        const deduplicatedMessages = holidayMatches.filter(
          message => !messages.find(m => m.message === message.message),
        );

        messages = [...messages, ...deduplicatedMessages];
      } else {
        messages = [...messages, ...holidayMatches];
      }
    }

    return messages;
  }, [sourceAssetType, targetAssetType]);

  if (holidayMessages.length > 0) {
    return holidayMessages.map((holidayMessage, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Noticebox key={`holidayMessage-${index}`} marginTop={4} variant='warning'>
        <Text>{holidayMessage.message}</Text>
      </Noticebox>
    ));
  }

  return null;
};

export default HolidayMessage;
