import React, { ReactElement } from 'react';

import { useOrganization } from 'context/OrganizationContext';
import ContentSection from 'components/core/ContentSection/ContentSection';
import PageHeader from 'components/core/PageHeader/PageHeader';

const SettingsView = (): ReactElement => {
  const { currentOrganizationData } = useOrganization();

  return (
    <>
      <PageHeader title={currentOrganizationData?.name} />
      <ContentSection>Organization settings page - work in progress.</ContentSection>
    </>
  );
};

export default SettingsView;
