import { AssetType } from 'constants/assetTypes.types';
import { BankAccount, Quote, QuoteRequest } from 'store/api/api.types';

export interface QuoteFormProps {
  assetTypes: AssetType[];
  bankAccounts?: BankAccount[];
  isQuoteInProgress: boolean;
  onChange: (sourceLabel: string, recipientLabel: string, quoteRequest?: QuoteRequest) => void;
  onContinue: () => void;
  quote?: Quote;
  quoteStatusLabel?: string;
  recipient?: string;
}

export enum QuoteViewStep {
  FORM = 'FORM',
  PREVIEW = 'PREVIEW',
}
