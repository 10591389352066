import React, { FC } from 'react';
import cx from 'classnames';

import { ContentSectionProps } from 'components/core/ContentSection/ContentSection.types';
import Box from 'components/core/Box/Box';

import styles from './ContentSection.module.scss';

const ContentSection: FC<ContentSectionProps> = ({
  backgroundColor = 'white',
  children,
  className,
  showBorder = false,
  flexGrow,
  ...props
}) => (
  <Box
    alignItems='flex-start'
    backgroundColor={backgroundColor}
    className={cx(showBorder && styles.showBorder, className)}
    display='flex'
    flexDirection='column'
    flexGrow={flexGrow}
    justifyContent='center'
    Tag='section'
  >
    <Box
      alignItems='stretch'
      className={styles.content}
      display='flex'
      flexDirection='column'
      flexGrow={flexGrow}
      {...props}
    >
      {children}
    </Box>
  </Box>
);

export default ContentSection;
