import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';

import { UserAvatarProps } from './UserAvatar.types';
import styles from './UserAvatar.module.scss';

const UserAvatar: FC<UserAvatarProps> = ({ className, size = 'regular', email, ...props }) => {
  const userAvatarLetter = email ? email.slice(0, 1).toUpperCase() : '';
  return (
    <Box className={cx(styles.root, styles[`size--${size}`], className)} {...props}>
      {userAvatarLetter}
    </Box>
  );
};

export default UserAvatar;
