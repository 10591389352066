import { toNumber } from 'lodash';
import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import { AssetTypeHeaderBoxProps } from 'components/dedicated/organization/send-money/AssetTypesHeader/AssetTypeHeaderBox/AssetTypeHeaderBox.types';
import { ModalDeposit, ModalWithdraw } from 'components/dedicated/organization/send-money';
import { NO_ACCOUNT_GENERIC_MESSAGE } from 'constants/assetTypes';
import { NO_PERMISSION_MESSAGE } from 'constants/constants';
import { formatAmount, getUriIdAndChainId, isFiat } from 'utils/format';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Icon from 'components/core/Icon/Icon';
import Text from 'components/core/Text/Text';
import Tooltip from 'components/core/Tooltip/Tooltip';
import modalService from 'services/modalService';

import styles from './AssetTypeHeaderBox.module.scss';

const AssetTypeHeaderBox: FC<AssetTypeHeaderBoxProps> = ({
  assetType,
  canWithdraw,
  organizationId,
  useShowcaseMode,
  ...props
}) => {
  const amount = useMemo(() => {
    if (assetType.localeFormat) {
      return toNumber(assetType.balance)?.toLocaleString(
        assetType.localeFormat?.locales,
        assetType.localeFormat?.options,
      );
    }
    return formatAmount(assetType, isFiat(assetType) ? 2 : 3);
  }, [assetType]);

  return (
    <Tooltip
      className={styles.tooltip}
      isHidden={!!assetType.depositAddress || !!assetType.depositInstructions}
      label={assetType.noAccountMessage || NO_ACCOUNT_GENERIC_MESSAGE}
    >
      <Box
        key={assetType.id}
        className={cx(styles.assetType, useShowcaseMode && styles.isDisabled)}
        dataTestId='asset-type-header-box'
        {...props}
      >
        <Box className={styles.details}>
          <Text variant='bodyCopySmall'>{getUriIdAndChainId(assetType)}</Text>
          <Text variant='sectionHeaderLarge'>
            <Box className={styles.tokenAndChain}>
              <Icon iconName={assetType.iconName} size={2.2} />
              {assetType.networkIconName && (
                <Icon
                  className={styles.networkIcon}
                  iconName={assetType.networkIconName}
                  size={1.4}
                />
              )}
            </Box>
            {amount}
          </Text>
        </Box>
        <Box className={styles.actions}>
          <Button
            dataTestId='add-button'
            label='Add'
            onClick={() =>
              modalService.openModal(
                {
                  title: `Add ${getUriIdAndChainId(assetType)}`,
                },
                <ModalDeposit assetType={assetType} />,
              )
            }
            size='small'
            variant='primary'
          />
          <Tooltip className={styles.tooltip} isHidden={canWithdraw} label={NO_PERMISSION_MESSAGE}>
            <Button
              dataTestId='withdraw-button'
              isDisabled={!canWithdraw}
              label='Withdraw'
              onClick={() =>
                modalService.openModal(
                  {
                    title: `Withdraw ${getUriIdAndChainId(assetType)}`,
                  },
                  <ModalWithdraw assetType={assetType} organizationId={organizationId} />,
                )
              }
              size='small'
              variant='secondary'
            />
          </Tooltip>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default AssetTypeHeaderBox;
