import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import React, { FC, useMemo, useState } from 'react';
import cx from 'classnames';

import { upload } from 'components/core/Svg/images/icons';
import Box from 'components/core/Box/Box';
import FormErrorMessage from 'components/core/Form/FormErrorMessage/FormErrorMessage';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';
import formatBytes from 'utils/formatBytes';

import { FileDropzoneProps } from './FileDropzone.types';
import { acceptedFileTypes, defaultOnDropRejectedErrors } from './FileDropzone.constants';
import { getOnDropRejectedErrors } from './FileDropzone.utils';
import UploadedFilesList from './UploadedFilesList/UploadedFilesList';
import styles from './FileDropzone.module.scss';

const FileDropzone: FC<FileDropzoneProps> = ({
  className,
  dataTestId,
  description,
  error,
  fileRequirements,
  isDisabled,
  maxNumberOfFiles = 1,
  files,
  onAddFile,
  onRemoveFile,
  onDropRejectedErrors = defaultOnDropRejectedErrors,
  title,
  ...dropzoneProps
}) => {
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const isDropzoneDisabled = isDisabled || files.length === maxNumberOfFiles;
  const numberOfFilesUnderLimit = maxNumberOfFiles - files.length;
  const isMultiple = numberOfFilesUnderLimit > 1;

  const fileRequirementsDescription = useMemo(() => {
    const { mimeTypes, maxSize } = fileRequirements || {};
    const descriptionParts: string[] = [];
    if (mimeTypes) {
      descriptionParts.push(mimeTypes.join(', '));
    }
    if (maxSize) {
      descriptionParts.push(`< ${formatBytes(maxSize)}`);
    }
    return descriptionParts.length > 0 ? `(${descriptionParts.join(' ')})` : '';
  }, [fileRequirements]);

  const dropzoneAcceptProp: DropzoneOptions['accept'] = fileRequirements?.mimeTypes
    ? fileRequirements.mimeTypes.reduce(
        (acc, mimeType) => ({
          ...acc,
          ...acceptedFileTypes[mimeType],
        }),
        {},
      )
    : undefined;

  const handleOnRemoveFile = (index: number) => {
    setFormErrors([]);
    onRemoveFile(index);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: dropzoneAcceptProp,
    disabled: isDropzoneDisabled,
    maxFiles: maxNumberOfFiles,
    maxSize: fileRequirements?.maxSize,
    multiple: isMultiple,
    onDrop: () => setFormErrors([]),
    onDropAccepted: newFiles => {
      onAddFile(isMultiple ? newFiles.slice(0, numberOfFilesUnderLimit) : newFiles);
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      setFormErrors(
        getOnDropRejectedErrors(fileRejections, onDropRejectedErrors, fileRequirements),
      );
    },
    ...dropzoneProps,
  });

  return (
    <Box className={className} marginBottom={6} marginTop={6}>
      {files.length < maxNumberOfFiles && (
        <div
          className={cx(
            error && styles.hasError,
            isDropzoneDisabled && styles.isDisabled,
            styles.dropzone,
          )}
          {...getRootProps()}
        >
          <Box marginBottom={1}>
            <Svg img={upload} size={2.4} />
          </Box>
          {title && <Text variant='bodyCopyStandard'>{title}</Text>}
          <Text className={styles.fileRequirements} marginTop={2} variant='bodyCopySmall'>
            Drag and Drop {fileRequirementsDescription} or{' '}
            <Text color='violetDark' Tag='span' variant='bodyCopySmall'>
              Browse
            </Text>
          </Text>
          {description && (
            <Text marginTop={4} variant='bodyCopySmall'>
              {description}
            </Text>
          )}
          <input data-testid={dataTestId} {...getInputProps()} />
        </div>
      )}
      {[error, ...formErrors].map(formError => (
        <FormErrorMessage
          key={formError || 'no-error'}
          className={styles.errorMessage}
          dataTestId={dataTestId ? `${dataTestId}-error` : undefined}
          message={formError}
        />
      ))}
      {files.length >= 0 && <UploadedFilesList files={files} onRemoveFile={handleOnRemoveFile} />}
    </Box>
  );
};

export default FileDropzone;
