import React, { ReactElement, useEffect } from 'react';

import { ROOT_ROUTES } from 'router/constants';
import { settingsFilled } from 'components/core/Svg/images/icons';
import { useGetPortalStatusQuery } from 'store/api/rootApi';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';
import router from 'router/router';

import styles from './UnderMaintenanceView.module.scss';

const UnderMaintenanceView = (): ReactElement => {
  const { data: portalStatus, isFetching: isFetchingPortalStatus } = useGetPortalStatusQuery();

  useEffect(() => {
    const isUnderMaintenance = isFetchingPortalStatus || portalStatus?.isUnderMaintenance;
    if (!isUnderMaintenance) {
      router.navigate(ROOT_ROUTES.APP.absolute);
    }
  }, [portalStatus, isFetchingPortalStatus]);

  return (
    <div className={styles.root}>
      <div className={styles.icon}>
        <Svg img={settingsFilled} size={2.2} />
      </div>
      <Text align='center' className={styles.header} variant='sectionHeaderLarge'>
        We are currently down for maintenance.
      </Text>
      <Text align='center' marginTop={4} variant='bodyCopySmall'>
        The platform is currently down for maintenance and will be back shortly.
        <br />
        For urgent matters, please contact support.
      </Text>
    </div>
  );
};

export default UnderMaintenanceView;
