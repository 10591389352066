import React, { FC } from 'react';

import { copy } from 'components/core/Svg/images/icons';
import Box from 'components/core/Box/Box';
import Svg from 'components/core/Svg/Svg';
import Tooltip from 'components/core/Tooltip/Tooltip';
import useTimeoutState from 'hooks/useTimeoutState';

import CopyToClipboardProps from './CopyToClipboardButton.types';
import styles from './CopyToClipboardButton.module.scss';

const CopyToClipboardButton: FC<CopyToClipboardProps> = ({
  marginLeft = 4,
  size = 1.5,
  valueToCopy,
  ...props
}) => {
  const [showCopiedMessage, setShowMessage] = useTimeoutState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(valueToCopy);
    setShowMessage(true, { timeout: 1000 });
  };

  return (
    <Box marginLeft={marginLeft} {...props}>
      <Tooltip label={showCopiedMessage ? 'Copied!' : 'Copy'}>
        <Svg
          className={styles.icon}
          dataTestId='copy-to-clipboard-button'
          img={copy}
          onClick={handleClick}
          size={size}
        />
      </Tooltip>
    </Box>
  );
};
export default CopyToClipboardButton;
