import { useParams } from 'react-router-dom';
import React, { ReactElement } from 'react';

import { FrameBoxBody, FrameBoxFooter } from 'components/core/FrameBox';
import { ORGANIZATION_ROUTES } from 'router/constants';
import Button from 'components/core/Button/Button';
import TransactionDetail from 'components/dedicated/organization/TransactionDetail/TransactionDetail';

import styles from './TransactionView.module.scss';

const TransactionView = (): ReactElement => {
  const { organizationId, transactionId } = useParams() as {
    organizationId: string;
    transactionId: string;
  };

  return (
    <>
      <FrameBoxBody>
        <TransactionDetail
          organizationId={organizationId}
          showTitle
          transactionId={transactionId}
        />
      </FrameBoxBody>
      <FrameBoxFooter className={styles.footer}>
        <div className={styles.buttons}>
          <Button
            dataTestId='see-transactions-button'
            label='See Transactions'
            to={`../../${ORGANIZATION_ROUTES.TRANSACTIONS.relative}`}
            type='button'
            variant='tertiary'
          />
          <Button label='Start Another' to='../' type='submit' variant='primary' />
        </div>
      </FrameBoxFooter>
    </>
  );
};

export default TransactionView;
