import { FC, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import createWrapperAndAppendToElement from 'utils/createWrapperAndAppendToElement';

import { ReactPortalProps } from './ReactPortal.types';

const ReactPortal: FC<ReactPortalProps> = ({ children, wrapperId = 'react-portal', appendTo }) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let isWrapperAutoGenerated = false;
    if (!element) {
      element = createWrapperAndAppendToElement(wrapperId, appendTo);
      isWrapperAutoGenerated = true;
    }
    setWrapperElement(element);

    return () => {
      if (isWrapperAutoGenerated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId, appendTo]);

  if (wrapperElement === null) {
    return null;
  }

  return createPortal(children, wrapperElement);
};

export default ReactPortal;
