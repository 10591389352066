import React, { FC, useCallback } from 'react';
import cx from 'classnames';

import { TransactionType } from 'store/api/api.types';
import { getParsedTransactionAttributes } from 'helpers/transaction.helpers';
import TransactionDescription from 'components/dedicated/TransactionDescription/TransactionDescription';
import TransactionTypeIcon from 'components/dedicated/TransactionTypeIcon/TransactionTypeIcon';

import { TransactionItemProps } from './TransactionItem.types';
import styles from './TransactionItem.module.scss';

import TransactionStatusBadge from '../TransactionStatusBadge/TransactionStatusBadge';

const TransactionItem: FC<TransactionItemProps> = ({
  className,
  onClick,
  dataTestId,
  transactionSummary,
}) => {
  const { attributes } = transactionSummary;
  const { cancellationStatus } = attributes;
  const {
    creationTime,
    senderLabel,
    sourceAmount,
    sourceAsset,
    recipientLabel,
    status,
    targetAsset,
    targetAmount,
    transactionType,
  } = getParsedTransactionAttributes(attributes);

  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(transactionSummary);
    }
  }, [onClick, transactionSummary]);

  return (
    <div className={cx(styles.root, className)} data-testid={dataTestId} onClick={handleOnClick}>
      <div className={styles.cellDateAndType}>
        <div className={styles.date}>
          <span className={styles.month}>{creationTime.month}</span>
          <span className={styles.day}>{creationTime.day}</span>
        </div>
        <TransactionTypeIcon className={styles.iconWrapper} transactionType={transactionType} />
      </div>
      <div className={styles.cellDetails}>
        <div className={styles.subcell}>
          <div className={styles.labelAndSublabelCell}>
            <TransactionDescription
              sourceAsset={sourceAsset}
              targetAsset={targetAsset}
              transactionType={transactionType}
            />
            <div className={styles.senderAndAmount}>
              <span className={styles.sender}>{senderLabel}</span>
              {sourceAmount &&
                ![TransactionType.Deposit, TransactionType.Withdrawal].includes(
                  transactionType,
                ) && (
                  <span className={styles.amount}>
                    &nbsp;|&nbsp;{sourceAmount} {sourceAsset}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className={styles.subcell}>
          <div className={styles.targetAmountAndAsset}>
            <span className={styles.amount}>{targetAmount}</span> {targetAsset}
          </div>
          <div className={styles.recipient}>
            To&nbsp;
            {recipientLabel}
          </div>
        </div>
      </div>
      <div className={styles.cellStatusBadge}>
        <TransactionStatusBadge cancellationStatus={cancellationStatus} status={status} />
      </div>
    </div>
  );
};

export default TransactionItem;
